import { useIntersectionObserver } from '@folklore/hooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from '../../styles/animations/remax/budget-breakdown-roxanne.module.css';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function BudgetBreakdownRoxanne({ className }) {
    const {
        ref: containerRef,
        entry: { isIntersecting = false },
    } = useIntersectionObserver({ rootMargin: '-20%' });

    return (
        <div
            ref={containerRef}
            className={classNames([
                styles.container,
                { [className]: className !== null, [styles.active]: isIntersecting },
            ])}
        >
            <svg viewBox="0 0 609.3 718.2">
                <defs>
                    <pattern
                        id="Nouveau_motif"
                        data-name="Nouveau motif"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternTransform="translate(-654.9 -191.2)"
                        patternUnits="userSpaceOnUse"
                        viewBox="0 0 20 20"
                    >
                        <g>
                            <rect width="20" height="20" fill="none" />
                            <g>
                                <line
                                    x1="10"
                                    x2="10"
                                    y2="100"
                                    fill="none"
                                    opacity=".8"
                                    stroke="#dee7f4"
                                    strokeMiterlimit="10"
                                    strokeWidth=".2"
                                />
                                <line
                                    x1="10"
                                    y1="-20"
                                    x2="10"
                                    y2="80"
                                    fill="none"
                                    opacity=".8"
                                    stroke="#dee7f4"
                                    strokeMiterlimit="10"
                                    strokeWidth=".2"
                                />
                                <line
                                    y1="10"
                                    x2="100"
                                    y2="10"
                                    fill="none"
                                    opacity=".8"
                                    stroke="#dee7f4"
                                    strokeMiterlimit="10"
                                    strokeWidth=".2"
                                />
                                <line
                                    x1="-20"
                                    y1="10"
                                    x2="80"
                                    y2="10"
                                    fill="none"
                                    opacity=".8"
                                    stroke="#dee7f4"
                                    strokeMiterlimit="10"
                                    strokeWidth=".2"
                                />
                            </g>
                            <g>
                                <line
                                    x1="-40"
                                    y1="10"
                                    x2="60"
                                    y2="10"
                                    fill="none"
                                    opacity=".8"
                                    stroke="#dee7f4"
                                    strokeMiterlimit="10"
                                    strokeWidth=".2"
                                />
                                <line
                                    x1="10"
                                    y1="-40"
                                    x2="10"
                                    y2="60"
                                    fill="none"
                                    opacity=".8"
                                    stroke="#dee7f4"
                                    strokeMiterlimit="10"
                                    strokeWidth=".2"
                                />
                            </g>
                            <line
                                x1="-60"
                                y1="10"
                                x2="40"
                                y2="10"
                                fill="none"
                                opacity=".8"
                                stroke="#dee7f4"
                                strokeMiterlimit="10"
                                strokeWidth=".2"
                            />
                            <line
                                x1="-80"
                                y1="10"
                                x2="20"
                                y2="10"
                                fill="none"
                                opacity=".8"
                                stroke="#dee7f4"
                                strokeMiterlimit="10"
                                strokeWidth=".2"
                            />
                            <line
                                x1="10"
                                y1="-60"
                                x2="10"
                                y2="40"
                                fill="none"
                                opacity=".8"
                                stroke="#dee7f4"
                                strokeMiterlimit="10"
                                strokeWidth=".2"
                            />
                            <line
                                x1="10"
                                y1="-80"
                                x2="10"
                                y2="20"
                                fill="none"
                                opacity=".8"
                                stroke="#dee7f4"
                                strokeMiterlimit="10"
                                strokeWidth=".2"
                            />
                        </g>
                    </pattern>
                </defs>
                <rect
                    x=".8"
                    y=".8"
                    width="607.6"
                    height="716.7"
                    rx="12"
                    ry="12"
                    fill="#dee7f4"
                    stroke="#0035a2"
                    strokeMiterlimit="10"
                    strokeWidth="1.5"
                />
                <g className={styles.pie}>
                    <path
                        d="M305.1,100.7c-51.8,0-98.7,21.2-132.4,55.4l132.4,130.6,184,27c1.3-8.8,2-17.8,2-27,0-102.7-83.2-185.9-185.9-185.9Z"
                        fill="#0046ab"
                    />
                    <path
                        d="M172.7,156.1c-33.1,33.6-53.6,79.7-53.6,130.6,0,75.2,44.7,140,108.9,169.3l77-169.3-132.4-130.6Z"
                        fill="#ff0018"
                    />
                    <path
                        d="M305.1,286.6l29.1,183.7c15.5-2.4,30.3-6.8,44.2-12.7l-73.3-170.9Z"
                        fill="#3884c8"
                    />
                    <path
                        d="M305.1,286.6l73.3,170.9c51.2-22,90.6-66.4,105.8-120.8l-179.1-50.1Z"
                        fill="#0070bd"
                    />
                    <path
                        d="M489.1,313.7l-184-27,179.1,50.1c2.1-7.5,3.8-15.2,4.9-23.1Z"
                        fill="#005ab5"
                    />
                </g>
                <g>
                    <rect x="488" y="100.7" width="99.3" height="25.1" fill="none" />
                    <path
                        d="M561.3,110.5c-1.2,1.5-2.8,2.2-4.7,2.2s-3.3-.6-4.5-1.7-1.8-2.6-1.8-4.4.6-3.3,1.7-4.5c1.2-1.1,2.6-1.7,4.4-1.7s3.5.7,4.6,2.2l-1.3,1.4c-.8-1.2-1.9-1.8-3.3-1.8s-2.2.4-2.9,1.2c-.8.8-1.2,1.9-1.2,3.1s.4,2.2,1.2,3.1c.8.8,1.8,1.2,3,1.2s2.5-.6,3.4-1.8l1.3,1.4Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M563.2,112.6v-11.9h8.4v1.8h-6.4v3.1h6.1v1.9h-6.1v3.3h6.4v1.8h-8.4Z"
                        fill="#0035a2"
                    />
                    <path d="M574.3,112.6v-11.9h2v10.1h5.6v1.8h-7.7Z" fill="#0035a2" />
                    <path d="M584,112.6v-11.9h2v11.9h-2Z" fill="#0035a2" />
                </g>
                <rect
                    x="13.1"
                    y="635.9"
                    width="580.7"
                    height="61.8"
                    rx="30.9"
                    ry="30.9"
                    fill="#0035a2"
                />
                <g>
                    <rect x="33.1" y="657" width="278.1" height="25.1" fill="none" />
                    <path
                        d="M41.2,669.7l4.1-12.7h3.8l-6,17.5h-3.8l-6-17.5h3.8l4.2,12.7Z"
                        fill="#fff"
                    />
                    <path
                        d="M58.4,657l6.8,17.5h-3.8l-1.4-3.4h-6.8l-1.4,3.4h-3.8l6.8-17.5h3.5ZM56.6,662l-2.2,5.7h4.3l-2.2-5.7Z"
                        fill="#fff"
                    />
                    <path d="M67.2,674.5v-17.5h3.7v14.1h8v3.4h-11.7Z" fill="#fff" />
                    <path
                        d="M81.3,674.5v-17.5h12.9v3.4h-9.2v3.5h8.8v3.4h-8.8v3.8h9.2v3.4h-12.9Z"
                        fill="#fff"
                    />
                    <path
                        d="M108.3,657h3.7v9.7c0,5.2-2.7,8-7.3,8s-7.3-2.8-7.3-8v-9.7h3.7v9.9c0,2.7,1.2,4.5,3.6,4.5s3.6-1.5,3.6-4.5v-9.9Z"
                        fill="#fff"
                    />
                    <path
                        d="M125.2,674.5l-3.4-5.6h-3v5.6h-3.7v-17.5h7.2c1.9,0,3.5.5,4.8,1.5,1.3,1,1.9,2.4,1.9,4.2s-1.3,4.5-3.4,5.5l3.9,6.3h-4.2ZM118.8,660.4v5.1h2.9c2.4,0,3.6-.9,3.6-2.6s-1.1-2.5-3.6-2.5h-2.9Z"
                        fill="#fff"
                    />
                    <path
                        d="M149.8,674.5l-7.1-11.8v11.8h-3.7v-17.5h4.5l7.1,11.9v-11.9h3.7v17.5h-4.4Z"
                        fill="#fff"
                    />
                    <path
                        d="M157.9,674.5v-17.5h12.9v3.4h-9.2v3.5h8.8v3.4h-8.8v3.8h9.2v3.4h-12.9Z"
                        fill="#fff"
                    />
                    <path d="M173.1,657h13.7v3.4h-5v14.1h-3.7v-14.1h-5v-3.4Z" fill="#fff" />
                    <path d="M188.3,657h13.7v3.4h-5v14.1h-3.7v-14.1h-5v-3.4Z" fill="#fff" />
                    <path
                        d="M204.4,674.5v-17.5h12.9v3.4h-9.2v3.5h8.8v3.4h-8.8v3.8h9.2v3.4h-12.9Z"
                        fill="#fff"
                    />
                </g>
                <g>
                    <rect x="295.6" y="657" width="278.3" height="25.1" fill="none" />
                    <path
                        d="M481.7,671.1v3.4h-12.5v-.6c0-2.1.4-3.9,1.3-5.3.9-1.4,2.3-2.6,4.4-3.7l1-.5c1.7-.9,2.2-1.6,2.2-2.5s-.9-1.8-2.4-1.8-3.1.9-4,2.8l-2.4-2.4c.5-1.1,1.4-2,2.5-2.7,1.2-.7,2.4-1.1,3.8-1.1s3.2.5,4.4,1.4c1.2.9,1.9,2.2,1.9,3.8,0,2.6-1.4,4.1-4.2,5.5l-1.5.8c-1.6.8-2.4,1.5-2.6,2.8h8.3Z"
                        fill="#fffcfc"
                    />
                    <path
                        d="M484.2,663.1v-6.1h12.8v3.2c-3.5,3.6-5.4,8-5.9,13.3v1h-3.8v-1c.5-5.1,2.2-9.5,5.2-13.1h-4.7v2.6h-3.6Z"
                        fill="#fffcfc"
                    />
                    <path
                        d="M514.9,672.3c-1.2,1.7-2.9,2.5-5.1,2.5s-3.9-.8-5.1-2.5c-1.2-1.7-1.8-3.8-1.8-6.5s.6-4.9,1.8-6.5c1.2-1.7,2.9-2.5,5.1-2.5s3.9.8,5.1,2.5c1.2,1.7,1.8,3.8,1.8,6.5s-.6,4.9-1.8,6.5ZM513.1,665.7c0-1.8-.3-3.1-.8-4.1-.5-1-1.3-1.5-2.4-1.5s-1.8.5-2.4,1.5c-.5,1-.8,2.4-.8,4.1s.3,3.1.8,4.1c.6,1,1.4,1.5,2.4,1.5s1.8-.5,2.4-1.5c.6-1,.8-2.4.8-4.1Z"
                        fill="#fffcfc"
                    />
                    <path
                        d="M531.1,672.3c-1.2,1.7-2.9,2.5-5.1,2.5s-3.9-.8-5.1-2.5-1.8-3.8-1.8-6.5.6-4.9,1.8-6.5c1.2-1.7,2.9-2.5,5.1-2.5s3.9.8,5.1,2.5c1.2,1.7,1.8,3.8,1.8,6.5s-.6,4.9-1.8,6.5ZM529.3,665.7c0-1.8-.3-3.1-.8-4.1-.5-1-1.3-1.5-2.4-1.5s-1.8.5-2.4,1.5c-.6,1-.8,2.4-.8,4.1s.3,3.1.8,4.1c.6,1,1.4,1.5,2.4,1.5s1.8-.5,2.4-1.5c.6-1,.8-2.4.8-4.1Z"
                        fill="#fffcfc"
                    />
                    <path
                        d="M547.3,672.3c-1.2,1.7-2.9,2.5-5.1,2.5s-3.9-.8-5.1-2.5c-1.2-1.7-1.8-3.8-1.8-6.5s.6-4.9,1.8-6.5c1.2-1.7,2.9-2.5,5.1-2.5s3.9.8,5.1,2.5c1.2,1.7,1.8,3.8,1.8,6.5s-.6,4.9-1.8,6.5ZM545.5,665.7c0-1.8-.3-3.1-.8-4.1-.5-1-1.3-1.5-2.4-1.5s-1.8.5-2.4,1.5c-.5,1-.8,2.4-.8,4.1s.3,3.1.8,4.1c.6,1,1.4,1.5,2.4,1.5s1.8-.5,2.4-1.5c.6-1,.8-2.4.8-4.1Z"
                        fill="#fffcfc"
                    />
                    <path
                        d="M564.3,677.4v-2.7c-2.5-.3-4.5-1.6-5.8-3.2l2.5-2.6c.9,1.2,2,1.9,3.3,2.3v-3.9c-3.4-.6-5.4-2.4-5.4-5.2s.5-2.6,1.5-3.5c1-.9,2.3-1.5,3.9-1.7v-2.6h2.8v2.7c2,.4,3.7,1.2,5,2.6l-2.5,2.6c-.8-.8-1.6-1.3-2.5-1.7v3.5c3.7.7,5.6,2.5,5.6,5.4s-2.1,4.9-5.6,5.3v2.6h-2.8ZM564.3,663.5v-3.3c-1.1.2-1.7.8-1.7,1.6s.6,1.3,1.7,1.7ZM567.1,667.8v3.5c1.2-.2,1.9-.9,1.9-1.8s-.6-1.4-1.9-1.7Z"
                        fill="#fffcfc"
                    />
                </g>
                <g>
                    <rect x="24.5" y="549.3" width="205.7" height="23.7" fill="none" />
                    <path
                        d="M31.4,549.3l4.6,11.9h-2.1l-1-2.5h-5.1l-1,2.5h-2.1l4.7-11.9h2ZM30.3,552.1l-1.8,4.8h3.6l-1.8-4.8Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M38.8,557.1c0,1.5,1,2.6,2.3,2.6s1.5-.4,2.1-1.3l1.3,1.2c-.8,1.2-2,1.8-3.5,1.8s-2.1-.4-3-1.2-1.2-1.9-1.2-3.1.4-2.3,1.2-3.1,1.8-1.3,3-1.3,2.5.6,3.4,1.7l-1.3,1.3c-.6-.8-1.3-1.2-2-1.2-1.3,0-2.3,1.1-2.3,2.6Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M46.5,558.3v-3.7h-1.2v-1.7h1.2v-2.3h1.9v2.3h2v1.7h-2v3.4c0,1.2.3,1.5,1.4,1.5h.6s0,1.7,0,1.7c-.3,0-.6,0-1,0-2.2,0-2.9-.8-2.9-3Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M52.3,549.5c.5-.5,1.3-.5,1.7,0,.5.5.5,1.2,0,1.7-.5.5-1.3.5-1.7,0-.5-.5-.5-1.3,0-1.7ZM52.2,561.2v-8.3h1.9v8.3h-1.9Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M63.4,554c.8.8,1.3,1.9,1.3,3.1s-.4,2.3-1.3,3.1-1.8,1.2-3,1.2-2.2-.4-3.1-1.2-1.2-1.9-1.2-3.1.4-2.3,1.2-3.1,1.9-1.3,3.1-1.3,2.2.4,3,1.3ZM62,559c.4-.5.7-1.1.7-1.9s-.2-1.4-.7-1.9c-.4-.5-1-.7-1.6-.7-1.4,0-2.3,1.1-2.3,2.6s1,2.6,2.3,2.6,1.2-.2,1.6-.7Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M68.5,561.2h-1.9v-8.3h1.9v.7c.5-.6,1.2-.9,2.2-.9,2.1,0,3.3,1.4,3.3,3.7v4.8h-1.9v-4.8c0-1.2-.6-1.9-1.6-1.9s-1.8.8-1.8,2.2v4.6Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M78.3,557.8c-1.7-.4-2.5-1.3-2.5-2.6s1.3-2.5,3-2.5,2.2.5,3.1,1.4l-1.2,1.3c-.5-.7-1.2-1.1-2-1.1s-1.1.4-1.1.8.3.6.8.8l1.3.3c1.7.4,2.5,1.3,2.5,2.6s-1.2,2.6-3.3,2.6-2.6-.5-3.5-1.6l1.2-1.3c.6.8,1.4,1.2,2.4,1.2s1.3-.4,1.3-.9-.3-.6-1-.8l-1.1-.3Z"
                        fill="#0035a2"
                    />
                    <path d="M84.9,563.6h-1.9l1.7-4.3h2.1l-2,4.3Z" fill="#0035a2" />
                    <path
                        d="M94.9,557.1c0,1.5,1,2.6,2.3,2.6s1.5-.4,2.1-1.3l1.3,1.2c-.8,1.2-2,1.8-3.5,1.8s-2.1-.4-3-1.2c-.8-.8-1.2-1.9-1.2-3.1s.4-2.3,1.2-3.1c.8-.8,1.8-1.3,3-1.3s2.5.6,3.4,1.7l-1.3,1.3c-.6-.8-1.3-1.2-2-1.2-1.3,0-2.3,1.1-2.3,2.6Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M107.3,554.7c0,0-.2,0-.3,0-1.9,0-2.6,1-2.6,3.3v3.3h-1.9v-8.3h1.9v.9c.6-.7,1.4-1,2.5-1s.2,0,.4,0v1.8Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M112.1,564.5h-2l1.3-3.2-3.3-8.4h2.2l2.2,6,2.2-6h2.1l-4.7,11.6Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M120.2,560.6v3.9h-1.9v-11.6h1.9v.7c.6-.6,1.4-1,2.4-1s2.2.4,2.9,1.2,1.1,1.9,1.1,3.1-.4,2.3-1.1,3.1c-.8.8-1.7,1.2-2.9,1.2s-1.7-.3-2.3-.8ZM124,559c.4-.5.6-1.1.6-1.9s-.2-1.4-.6-1.9c-.4-.5-.9-.8-1.6-.8-1.4,0-2.2,1.1-2.2,2.6s.8,2.6,2.2,2.6,1.2-.3,1.6-.8Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M128.6,558.3v-3.7h-1.2v-1.7h1.2v-2.3h1.9v2.3h2v1.7h-2v3.4c0,1.2.3,1.5,1.4,1.5h.6s0,1.7,0,1.7c-.3,0-.6,0-1,0-2.2,0-2.9-.8-2.9-3Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M141.1,554c.8.8,1.3,1.9,1.3,3.1s-.4,2.3-1.3,3.1-1.8,1.2-3,1.2-2.2-.4-3.1-1.2-1.2-1.9-1.2-3.1.4-2.3,1.2-3.1,1.9-1.3,3.1-1.3,2.2.4,3,1.3ZM139.7,559c.4-.5.7-1.1.7-1.9s-.2-1.4-.7-1.9c-.4-.5-1-.7-1.6-.7-1.4,0-2.3,1.1-2.3,2.6s1,2.6,2.3,2.6,1.2-.2,1.6-.7Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M149.5,561.2v-5c0-1.1-.6-1.8-1.5-1.8s-1.7.8-1.7,2v4.7h-1.9v-8.3h1.9v.7c.5-.6,1.3-.9,2.2-.9s1.8.4,2.3,1.1c.6-.7,1.5-1.1,2.6-1.1,2.2,0,3.3,1.3,3.3,3.7v4.8h-1.9v-5c0-1.1-.6-1.8-1.5-1.8s-1.7.8-1.7,2v4.7h-1.9Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M165.7,554c.8.8,1.3,1.9,1.3,3.1s-.4,2.3-1.3,3.1-1.8,1.2-3,1.2-2.2-.4-3.1-1.2-1.2-1.9-1.2-3.1.4-2.3,1.2-3.1,1.9-1.3,3.1-1.3,2.2.4,3,1.3ZM164.3,559c.4-.5.7-1.1.7-1.9s-.2-1.4-.7-1.9c-.4-.5-1-.7-1.6-.7-1.4,0-2.3,1.1-2.3,2.6s1,2.6,2.3,2.6,1.2-.2,1.6-.7Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M170.8,561.2h-1.9v-8.3h1.9v.7c.5-.6,1.2-.9,2.2-.9,2.1,0,3.3,1.4,3.3,3.7v4.8h-1.9v-4.8c0-1.2-.6-1.9-1.6-1.9s-1.8.8-1.8,2.2v4.6Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M180.4,561.2h-1.9v-8.3h1.9v.7c.5-.6,1.2-.9,2.2-.9,2.1,0,3.3,1.4,3.3,3.7v4.8h-1.9v-4.8c0-1.2-.6-1.9-1.6-1.9s-1.8.8-1.8,2.2v4.6Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M188.4,560.8c-.5-.5-.8-1.1-.8-1.9,0-1.8,1.4-2.8,3.5-2.8s1.1.1,1.7.3v-.6c0-.8-.7-1.4-1.6-1.4s-1.5.4-2,1.2l-1.3-1.2c.6-1.1,1.9-1.7,3.3-1.7,2.2,0,3.6,1.1,3.6,3.1v5.4h-1.9v-.6c-.6.6-1.4.8-2.4.8s-1.5-.2-2-.7ZM190.8,559.7c.8,0,1.5-.2,2-.7v-1c-.6-.2-1.2-.3-1.7-.3-.9,0-1.5.4-1.5,1s.4,1,1.3,1Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M197,549.5c.5-.5,1.3-.5,1.7,0,.5.5.5,1.2,0,1.7-.5.5-1.3.5-1.7,0-.5-.5-.5-1.3,0-1.7ZM196.9,561.2v-8.3h1.9v8.3h-1.9Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M208.5,557.7h-5.7c0,1.2,1,2,2.2,2s1.6-.4,2.1-1.2l1.3,1.2c-.9,1.1-2,1.7-3.5,1.7s-2.3-.4-3-1.2-1.2-1.8-1.2-3.1.4-2.3,1.1-3.1c.7-.9,1.7-1.3,2.8-1.3,2.7,0,3.8,2.2,3.8,4.3v.7ZM202.9,556.1h3.6c0-.9-.6-1.6-1.7-1.6s-1.6.7-1.8,1.6Z"
                        fill="#0035a2"
                    />
                </g>
                <g>
                    <path
                        d="M38.3,591.1v3.4h-12.5v-.6c0-2.1.4-3.9,1.3-5.3.8-1.4,2.3-2.6,4.4-3.7l1-.5c1.7-.9,2.2-1.6,2.2-2.5s-.9-1.8-2.4-1.8-3.1.9-4,2.8l-2.4-2.4c.5-1.1,1.4-2,2.5-2.7,1.1-.7,2.4-1.1,3.8-1.1s3.2.4,4.4,1.4c1.2.9,1.9,2.2,1.9,3.8,0,2.6-1.4,4.1-4.2,5.5l-1.5.8c-1.6.8-2.4,1.6-2.6,2.8h8.3Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M53.1,592.3c-1.2,1.7-2.9,2.5-5.1,2.5s-3.9-.8-5.1-2.5c-1.2-1.7-1.8-3.8-1.8-6.5s.6-4.9,1.8-6.5c1.2-1.7,2.9-2.5,5.1-2.5s3.9.8,5.1,2.5c1.2,1.7,1.8,3.8,1.8,6.5s-.6,4.9-1.8,6.5ZM51.3,585.8c0-1.8-.3-3.1-.8-4.1-.5-1-1.3-1.5-2.4-1.5s-1.8.5-2.4,1.5c-.6,1-.8,2.4-.8,4.1s.3,3.1.8,4.1c.6,1,1.4,1.5,2.4,1.5s1.8-.5,2.4-1.5c.6-1,.8-2.4.8-4.1Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M69.3,592.3c-1.2,1.7-2.9,2.5-5.1,2.5s-3.9-.8-5.1-2.5c-1.2-1.7-1.8-3.8-1.8-6.5s.6-4.9,1.8-6.5c1.2-1.7,2.9-2.5,5.1-2.5s3.9.8,5.1,2.5c1.2,1.7,1.8,3.8,1.8,6.5s-.6,4.9-1.8,6.5ZM67.5,585.8c0-1.8-.3-3.1-.8-4.1-.5-1-1.3-1.5-2.4-1.5s-1.8.5-2.4,1.5c-.6,1-.8,2.4-.8,4.1s.3,3.1.8,4.1c.6,1,1.4,1.5,2.4,1.5s1.8-.5,2.4-1.5c.6-1,.8-2.4.8-4.1Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M85.5,592.3c-1.2,1.7-2.9,2.5-5.1,2.5s-3.9-.8-5.1-2.5c-1.2-1.7-1.8-3.8-1.8-6.5s.6-4.9,1.8-6.5c1.2-1.7,2.9-2.5,5.1-2.5s3.9.8,5.1,2.5c1.2,1.7,1.8,3.8,1.8,6.5s-.6,4.9-1.8,6.5ZM83.7,585.8c0-1.8-.3-3.1-.8-4.1-.5-1-1.3-1.5-2.4-1.5s-1.8.5-2.4,1.5c-.6,1-.8,2.4-.8,4.1s.3,3.1.8,4.1c.6,1,1.4,1.5,2.4,1.5s1.8-.5,2.4-1.5c.6-1,.8-2.4.8-4.1Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M98,597.4v-2.7c-2.5-.3-4.5-1.6-5.8-3.2l2.5-2.6c.9,1.2,2,1.9,3.3,2.3v-3.9c-3.4-.6-5.4-2.4-5.4-5.2s.5-2.6,1.5-3.5c1-.9,2.3-1.5,3.9-1.7v-2.6h2.8v2.7c2,.4,3.7,1.2,5,2.6l-2.5,2.6c-.8-.8-1.6-1.3-2.5-1.7v3.6c3.7.7,5.6,2.5,5.6,5.4s-2.1,4.9-5.6,5.3v2.6h-2.8ZM98,583.6v-3.3c-1.1.2-1.7.8-1.7,1.6s.6,1.3,1.7,1.7ZM100.8,587.8v3.5c1.2-.2,1.9-.9,1.9-1.8s-.6-1.4-1.9-1.7Z"
                        fill="#0035a2"
                    />
                </g>
                <g>
                    <rect x="381.3" y="504.4" width="205.7" height="23.7" fill="none" />
                    <path
                        d="M527.4,514.3c-1.2,1.5-2.8,2.2-4.7,2.2s-3.3-.6-4.5-1.7-1.8-2.6-1.8-4.4.6-3.3,1.7-4.5c1.2-1.1,2.6-1.7,4.4-1.7s3.5.7,4.6,2.2l-1.3,1.4c-.8-1.2-1.9-1.8-3.3-1.8s-2.2.4-2.9,1.2c-.8.8-1.2,1.9-1.2,3.1s.4,2.2,1.2,3.1c.8.8,1.8,1.2,3,1.2s2.5-.6,3.4-1.8l1.3,1.4Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M529.3,516.4v-11.9h8.4v1.8h-6.4v3.1h6.1v1.9h-6.1v3.3h6.4v1.8h-8.4Z"
                        fill="#0035a2"
                    />
                    <path d="M540.4,516.4v-11.9h2v10.1h5.6v1.8h-7.7Z" fill="#0035a2" />
                    <path d="M550.1,516.4v-11.9h2v11.9h-2Z" fill="#0035a2" />
                    <path
                        d="M560.4,504.4l4.6,11.9h-2.1l-1-2.5h-5.1l-1,2.5h-2.1l4.7-11.9h2ZM559.3,507.2l-1.8,4.8h3.6l-1.8-4.8Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M568.6,512.3v4.1h-2v-11.9h4.5c2.8,0,4.5,1.4,4.5,3.9s-1.8,4-4.5,4h-2.5ZM568.6,506.3v4.2h2.3c1.9,0,2.8-.7,2.8-2.1s-.9-2.1-2.8-2.1h-2.3Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M579.5,512.3v4.1h-2v-11.9h4.5c2.8,0,4.5,1.4,4.5,3.9s-1.8,4-4.5,4h-2.5ZM579.5,506.3v4.2h2.3c1.9,0,2.8-.7,2.8-2.1s-.9-2.1-2.8-2.1h-2.3Z"
                        fill="#0035a2"
                    />
                </g>
                <g>
                    <path
                        d="M509.4,541.2c-1.3,0-2.2.5-2.6,1.7h-3.4l.4-10.8h10.8v3.4h-7.3v3.2c.7-.5,1.6-.8,2.8-.8s3,.5,4.2,1.6c1.2,1,1.8,2.4,1.8,4.2s-.6,3.3-1.9,4.5c-1.3,1.1-2.9,1.7-5,1.7s-4.8-1.1-6-2.8l2.6-2.6c1,1.3,2.2,2,3.7,2s2.9-1,2.9-2.6-1.2-2.7-2.9-2.7Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M532.5,547.4c-1.2,1.7-2.9,2.5-5.1,2.5s-3.9-.8-5.1-2.5c-1.2-1.7-1.8-3.8-1.8-6.5s.6-4.9,1.8-6.5c1.2-1.7,2.9-2.5,5.1-2.5s3.9.8,5.1,2.5c1.2,1.7,1.8,3.8,1.8,6.5s-.6,4.9-1.8,6.5ZM530.6,540.9c0-1.8-.3-3.1-.8-4.1-.5-1-1.3-1.5-2.4-1.5s-1.8.5-2.4,1.5c-.5,1-.8,2.4-.8,4.1s.3,3.1.8,4.1c.6,1,1.4,1.5,2.4,1.5s1.8-.5,2.4-1.5c.6-1,.8-2.4.8-4.1Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M548.7,547.4c-1.2,1.7-2.9,2.5-5.1,2.5s-3.9-.8-5.1-2.5-1.8-3.8-1.8-6.5.6-4.9,1.8-6.5c1.2-1.7,2.9-2.5,5.1-2.5s3.9.8,5.1,2.5c1.2,1.7,1.8,3.8,1.8,6.5s-.6,4.9-1.8,6.5ZM546.8,540.9c0-1.8-.3-3.1-.8-4.1-.5-1-1.3-1.5-2.4-1.5s-1.8.5-2.4,1.5c-.6,1-.8,2.4-.8,4.1s.3,3.1.8,4.1c.6,1,1.4,1.5,2.4,1.5s1.8-.5,2.4-1.5c.6-1,.8-2.4.8-4.1Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M564.9,547.4c-1.2,1.7-2.9,2.5-5.1,2.5s-3.9-.8-5.1-2.5c-1.2-1.7-1.8-3.8-1.8-6.5s.6-4.9,1.8-6.5c1.2-1.7,2.9-2.5,5.1-2.5s3.9.8,5.1,2.5c1.2,1.7,1.8,3.8,1.8,6.5s-.6,4.9-1.8,6.5ZM563,540.9c0-1.8-.3-3.1-.8-4.1-.5-1-1.3-1.5-2.4-1.5s-1.8.5-2.4,1.5c-.5,1-.8,2.4-.8,4.1s.3,3.1.8,4.1c.6,1,1.4,1.5,2.4,1.5s1.8-.5,2.4-1.5c.6-1,.8-2.4.8-4.1Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M577.4,552.5v-2.7c-2.5-.3-4.5-1.6-5.8-3.2l2.5-2.6c.9,1.2,2,1.9,3.3,2.3v-3.9c-3.4-.6-5.4-2.4-5.4-5.2s.5-2.6,1.5-3.5,2.3-1.5,3.9-1.7v-2.6h2.8v2.7c2,.4,3.7,1.2,5,2.6l-2.5,2.6c-.8-.8-1.6-1.3-2.5-1.7v3.5c3.7.7,5.6,2.5,5.6,5.4s-2.1,4.9-5.6,5.3v2.6h-2.8ZM577.4,538.7v-3.3c-1.1.2-1.7.8-1.7,1.6s.6,1.3,1.7,1.7ZM580.1,542.9v3.5c1.2-.2,1.9-.9,1.9-1.8s-.7-1.4-1.9-1.7Z"
                        fill="#0035a2"
                    />
                </g>
                <g>
                    <rect x="1.7" y="22.3" width="607.6" height="25.1" fill="none" />
                    <path
                        d="M198.6,22.3l6.8,17.5h-3.8l-1.4-3.4h-6.8l-1.4,3.4h-3.8l6.8-17.5h3.5ZM196.9,27.3l-2.2,5.7h4.3l-2.2-5.7Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M222.2,36.8c-1.8,2.2-4.2,3.3-7.1,3.3s-4.9-.8-6.7-2.5c-1.7-1.6-2.6-3.8-2.6-6.5s.9-4.9,2.6-6.6c1.7-1.7,3.9-2.5,6.5-2.5s5.2,1.1,7,3.3l-2.5,2.5c-1-1.5-2.7-2.4-4.6-2.4s-2.6.5-3.6,1.5c-1,1-1.5,2.3-1.5,4s.5,3.1,1.5,4.1c1,1,2.3,1.5,3.8,1.5s3.5-.8,4.7-2.4l2.5,2.6Z"
                        fill="#0035a2"
                    />
                    <path d="M223.3,22.3h13.7v3.4h-5v14.1h-3.7v-14.1h-5v-3.4Z" fill="#0035a2" />
                    <path d="M239.6,39.8v-17.5h3.7v17.5h-3.7Z" fill="#0035a2" />
                    <path
                        d="M250.9,39.8h-3.7v-17.5h12.1v3.4h-8.3v3.8h7.8v3.4h-7.8v6.9Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M266.6,32.6c-3.4-.6-5.5-2.3-5.5-5.2s.6-2.9,1.9-3.8c1.3-1,2.8-1.5,4.6-1.5,2.7,0,5,.9,6.7,2.8l-2.5,2.6c-1.3-1.3-2.8-2-4.4-2s-2.6.7-2.6,1.7.8,1.5,2.5,1.8l1.7.3c3.9.6,5.9,2.5,5.9,5.5s-.6,2.9-1.9,3.9c-1.3,1-2.9,1.5-5,1.5-3.1,0-5.7-1.4-7.3-3.3l2.5-2.6c1.3,1.7,2.9,2.5,4.9,2.5s3-.8,3-1.9-.8-1.5-2.4-1.8l-2.1-.4Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M284.6,39.8v-17.5h12.9v3.4h-9.2v3.5h8.8v3.4h-8.8v3.8h9.2v3.4h-12.9Z"
                        fill="#0035a2"
                    />
                    <path d="M299.8,22.3h13.7v3.4h-5v14.1h-3.7v-14.1h-5v-3.4Z" fill="#0035a2" />
                    <path
                        d="M326.6,34.3v5.5h-3.7v-17.5h7.1c2.1,0,3.7.5,5,1.6,1.2,1,1.9,2.5,1.9,4.3s-.6,3.3-1.9,4.4c-1.3,1.1-2.9,1.7-4.9,1.7h-3.4ZM326.6,25.7v5.1h2.7c2.5,0,3.8-.9,3.8-2.6s-1.2-2.5-3.8-2.5h-2.7Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M346.1,22.3l6.8,17.5h-3.8l-1.4-3.4h-6.8l-1.4,3.4h-3.8l6.8-17.5h3.5ZM344.3,27.3l-2.2,5.7h4.3l-2.2-5.7Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M359.6,32.6c-3.4-.6-5.5-2.3-5.5-5.2s.6-2.9,1.9-3.8c1.3-1,2.8-1.5,4.6-1.5,2.7,0,5,.9,6.7,2.8l-2.5,2.6c-1.3-1.3-2.8-2-4.4-2s-2.6.7-2.6,1.7.8,1.5,2.5,1.8l1.7.3c3.9.6,5.9,2.5,5.9,5.5s-.7,2.9-1.9,3.9c-1.3,1-2.9,1.5-5,1.5-3.1,0-5.7-1.4-7.3-3.3l2.5-2.6c1.3,1.7,2.9,2.5,4.9,2.5s3-.8,3-1.9-.8-1.5-2.4-1.8l-2.1-.4Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M375.7,32.6c-3.4-.6-5.5-2.3-5.5-5.2s.6-2.9,1.9-3.8c1.3-1,2.8-1.5,4.6-1.5,2.7,0,5,.9,6.7,2.8l-2.5,2.6c-1.3-1.3-2.8-2-4.4-2s-2.6.7-2.6,1.7.8,1.5,2.5,1.8l1.7.3c3.9.6,5.9,2.5,5.9,5.5s-.7,2.9-1.9,3.9c-1.3,1-2.9,1.5-5,1.5-3.1,0-5.7-1.4-7.3-3.3l2.5-2.6c1.3,1.7,2.9,2.5,4.9,2.5s3-.8,3-1.9-.8-1.5-2.4-1.8l-2.1-.4Z"
                        fill="#0035a2"
                    />
                    <path d="M386.8,39.8v-17.5h3.7v17.5h-3.7Z" fill="#0035a2" />
                    <path
                        d="M398.1,39.8h-3.7v-17.5h12.1v3.4h-8.3v3.8h7.8v3.4h-7.8v6.9Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M413.8,32.6c-3.4-.6-5.5-2.3-5.5-5.2s.6-2.9,1.9-3.8c1.3-1,2.8-1.5,4.6-1.5,2.7,0,5,.9,6.7,2.8l-2.5,2.6c-1.3-1.3-2.8-2-4.4-2s-2.6.7-2.6,1.7.8,1.5,2.5,1.8l1.7.3c3.9.6,5.9,2.5,5.9,5.5s-.7,2.9-1.9,3.9c-1.3,1-2.9,1.5-5,1.5-3.1,0-5.7-1.4-7.3-3.3l2.5-2.6c1.3,1.7,2.9,2.5,4.9,2.5s3-.8,3-1.9-.8-1.5-2.4-1.8l-2.1-.4Z"
                        fill="#0035a2"
                    />
                </g>
                <line
                    x1="13.1"
                    y1="62.9"
                    x2="593.8"
                    y2="62.9"
                    fill="none"
                    stroke="#3e57b0"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <g className={styles.pie}>
                    <path
                        d="M305.1,286.6l-77,169.3c10.2,4.7,20.9,8.4,32,11.2l45-180.5Z"
                        fill="#7fa8da"
                    />
                    <path
                        d="M305.1,286.6l-45,180.5c14.4,3.6,29.5,5.5,45,5.5s19.6-.8,29.1-2.3l-29.1-183.7Z"
                        fill="#5c97d0"
                    />
                </g>
                <line
                    x1="365.4"
                    y1="154.6"
                    x2="589.5"
                    y2="154.6"
                    fill="none"
                    stroke="#0035a2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <g>
                    <path
                        d="M503.9,139.6v3.4h-12.5v-.6c0-2.1.4-3.9,1.3-5.3.8-1.4,2.3-2.6,4.4-3.7l1-.5c1.7-.9,2.2-1.6,2.2-2.5s-.9-1.8-2.4-1.8-3.1.9-4,2.8l-2.4-2.4c.5-1.1,1.4-2,2.5-2.7,1.2-.7,2.4-1.1,3.8-1.1s3.2.5,4.4,1.4c1.2.9,1.9,2.2,1.9,3.8,0,2.6-1.4,4.1-4.2,5.5l-1.5.8c-1.6.8-2.4,1.5-2.6,2.8h8.3Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M518.2,127.8c1.2,1.6,1.9,3.9,1.9,6.7s-.7,4.8-2,6.4c-1.3,1.6-3.1,2.4-5.4,2.4s-4.2-1-5.4-2.4l2.4-2.5c.8,1,1.8,1.5,3.1,1.5s2-.4,2.7-1.2c.8-.8,1.1-1.9,1.1-3.2-.9,1.1-2.2,1.6-3.8,1.6s-3.1-.5-4.3-1.6c-1.2-1.1-1.8-2.5-1.8-4.3s.6-3.2,1.8-4.3c1.2-1.1,2.8-1.6,4.5-1.6s3.9.8,5.1,2.5ZM515.6,131.3c0-1.5-1.1-2.6-2.6-2.6s-2.6,1.1-2.6,2.6,1.1,2.6,2.6,2.6,2.6-1.1,2.6-2.6Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M537.6,140.8c-1.2,1.7-2.9,2.5-5.1,2.5s-3.9-.8-5.1-2.5c-1.2-1.7-1.8-3.8-1.8-6.5s.6-4.9,1.8-6.5c1.2-1.7,2.9-2.5,5.1-2.5s3.9.8,5.1,2.5c1.2,1.7,1.8,3.8,1.8,6.5s-.6,4.9-1.8,6.5ZM535.7,134.3c0-1.8-.3-3.1-.8-4.1-.5-1-1.3-1.5-2.4-1.5s-1.8.5-2.4,1.5c-.5,1-.8,2.4-.8,4.1s.3,3.1.8,4.1c.6,1,1.4,1.5,2.4,1.5s1.8-.5,2.4-1.5c.6-1,.8-2.4.8-4.1Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M553.8,140.8c-1.2,1.7-2.9,2.5-5.1,2.5s-3.9-.8-5.1-2.5-1.8-3.8-1.8-6.5.6-4.9,1.8-6.5c1.2-1.7,2.9-2.5,5.1-2.5s3.9.8,5.1,2.5c1.2,1.7,1.8,3.8,1.8,6.5s-.6,4.9-1.8,6.5ZM551.9,134.3c0-1.8-.3-3.1-.8-4.1-.5-1-1.3-1.5-2.4-1.5s-1.8.5-2.4,1.5c-.6,1-.8,2.4-.8,4.1s.3,3.1.8,4.1c.6,1,1.4,1.5,2.4,1.5s1.8-.5,2.4-1.5c.6-1,.8-2.4.8-4.1Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M570,140.8c-1.2,1.7-2.9,2.5-5.1,2.5s-3.9-.8-5.1-2.5c-1.2-1.7-1.8-3.8-1.8-6.5s.6-4.9,1.8-6.5c1.2-1.7,2.9-2.5,5.1-2.5s3.9.8,5.1,2.5c1.2,1.7,1.8,3.8,1.8,6.5s-.6,4.9-1.8,6.5ZM568.1,134.3c0-1.8-.3-3.1-.8-4.1-.5-1-1.3-1.5-2.4-1.5s-1.8.5-2.4,1.5c-.5,1-.8,2.4-.8,4.1s.3,3.1.8,4.1c.6,1,1.4,1.5,2.4,1.5s1.8-.5,2.4-1.5c.6-1,.8-2.4.8-4.1Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M581.9,145.9v-2.7c-2.5-.3-4.5-1.6-5.8-3.2l2.5-2.6c.9,1.2,2,1.9,3.3,2.3v-3.9c-3.4-.6-5.4-2.4-5.4-5.2s.5-2.6,1.5-3.5,2.3-1.5,3.9-1.7v-2.6h2.8v2.7c2,.4,3.7,1.2,5,2.6l-2.5,2.6c-.8-.8-1.6-1.3-2.5-1.7v3.5c3.7.7,5.6,2.5,5.6,5.4s-2.1,4.9-5.6,5.3v2.6h-2.8ZM581.9,132.1v-3.3c-1.1.2-1.7.8-1.7,1.6s.6,1.3,1.7,1.7ZM584.7,136.3v3.5c1.2-.2,1.9-.9,1.9-1.8s-.7-1.4-1.9-1.7Z"
                        fill="#0035a2"
                    />
                </g>
                <g>
                    <rect x="490.2" y="243.4" width="99.3" height="48.3" fill="none" />
                    <path
                        d="M539,253.3c-1.2,1.5-2.8,2.2-4.7,2.2s-3.3-.6-4.5-1.7-1.8-2.6-1.8-4.4.6-3.3,1.7-4.5c1.2-1.1,2.6-1.7,4.4-1.7s3.5.7,4.6,2.2l-1.3,1.4c-.8-1.2-1.9-1.8-3.3-1.8s-2.2.4-2.9,1.2c-.8.8-1.2,1.9-1.2,3.1s.4,2.2,1.2,3.1c.8.8,1.8,1.2,3,1.2s2.5-.6,3.4-1.8l1.3,1.4Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M547.6,248c.8.8,1.3,1.9,1.3,3.1s-.4,2.3-1.3,3.1-1.8,1.2-3,1.2-2.2-.4-3.1-1.2-1.2-1.9-1.2-3.1.4-2.3,1.2-3.1,1.9-1.3,3.1-1.3,2.2.4,3,1.3ZM546.2,253c.4-.5.7-1.1.7-1.9s-.2-1.4-.7-1.9c-.4-.5-1-.7-1.6-.7-1.4,0-2.3,1.1-2.3,2.6s1,2.6,2.3,2.6,1.2-.2,1.6-.7Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M556,255.3v-5c0-1.1-.6-1.8-1.5-1.8s-1.7.8-1.7,2v4.7h-1.9v-8.3h1.9v.7c.5-.6,1.3-.9,2.2-.9s1.8.4,2.3,1.1c.6-.7,1.5-1.1,2.6-1.1,2.2,0,3.3,1.3,3.3,3.7v4.8h-1.9v-5c0-1.1-.6-1.8-1.5-1.8s-1.7.8-1.7,2v4.7h-1.9Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M567.4,254.7v3.9h-1.9v-11.6h1.9v.7c.6-.6,1.4-1,2.4-1s2.2.4,2.9,1.2,1.1,1.9,1.1,3.1-.4,2.3-1.1,3.1c-.8.8-1.7,1.2-2.9,1.2s-1.7-.3-2.3-.8ZM571.2,253c.4-.5.6-1.1.6-1.9s-.2-1.4-.6-1.9c-.4-.5-.9-.8-1.6-.8-1.4,0-2.2,1.1-2.2,2.6s.8,2.6,2.2,2.6,1.2-.3,1.6-.8Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M575.9,252.4v-3.7h-1.2v-1.7h1.2v-2.3h1.9v2.3h2v1.7h-2v3.4c0,1.2.3,1.5,1.4,1.5h.6s0,1.7,0,1.7c-.3,0-.6,0-1,0-2.2,0-2.9-.8-2.9-3Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M588.7,251.7h-5.7c0,1.2,1,2,2.2,2s1.6-.4,2.1-1.2l1.3,1.2c-.9,1.1-2,1.7-3.5,1.7s-2.3-.4-3-1.2-1.2-1.8-1.2-3.1.4-2.3,1.1-3.1c.7-.9,1.7-1.3,2.8-1.3,2.7,0,3.8,2.2,3.8,4.3v.7ZM583.1,250.2h3.6c0-.9-.6-1.6-1.7-1.6s-1.6.7-1.8,1.6Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M527.2,271.5c0,1.5,1,2.6,2.3,2.6s1.5-.4,2.1-1.3l1.3,1.2c-.8,1.2-2,1.8-3.5,1.8s-2.1-.4-3-1.2c-.8-.8-1.2-1.9-1.2-3.1s.4-2.3,1.2-3.1c.8-.8,1.8-1.3,3-1.3s2.5.6,3.4,1.7l-1.3,1.3c-.6-.8-1.3-1.2-2-1.2-1.3,0-2.3,1.1-2.3,2.6Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M536.6,275.7h-1.9v-11.9h1.9v4.2c.6-.5,1.3-.8,2.1-.8,2,0,3.3,1.4,3.3,3.7v4.8h-1.9v-4.8c0-1.2-.6-1.9-1.6-1.9s-1.8.8-1.8,2.2v4.6Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M551.6,272.1h-5.7c0,1.2,1,2,2.2,2s1.6-.4,2.1-1.2l1.3,1.2c-.9,1.1-2,1.7-3.5,1.7s-2.3-.4-3-1.2c-.8-.8-1.2-1.8-1.2-3.1s.4-2.3,1.1-3.1c.7-.9,1.7-1.3,2.8-1.3,2.7,0,3.8,2.2,3.8,4.3v.7ZM545.4,263.8h2.4l1.5,2.2h-1.9l-2-2.2ZM546,270.6h3.6c0-.9-.6-1.6-1.7-1.6s-1.6.7-1.8,1.6Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M559.8,268.1v-.7h1.9v11.6h-1.9v-3.9c-.6.5-1.4.8-2.3.8s-2.2-.4-2.9-1.2c-.8-.8-1.1-1.9-1.1-3.1s.4-2.3,1.1-3.1c.8-.8,1.7-1.2,2.9-1.2s1.8.3,2.4,1ZM555.9,269.7c-.4.5-.6,1.1-.6,1.9s.2,1.4.6,1.9c.4.5,1,.8,1.6.8,1.4,0,2.2-1.1,2.2-2.6s-.8-2.6-2.2-2.6-1.3.3-1.6.8Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M565.8,267.4v5c0,1.1.6,1.8,1.6,1.8s1.7-.8,1.7-2.1v-4.7h1.9v8.3h-1.9v-.7c-.5.6-1.3.9-2.2.9-1.9,0-3.1-1.4-3.1-3.7v-4.8h1.9Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M580.7,272.1h-5.7c0,1.2,1,2,2.2,2s1.6-.4,2.1-1.2l1.3,1.2c-.9,1.1-2,1.7-3.5,1.7s-2.3-.4-3-1.2-1.2-1.8-1.2-3.1.4-2.3,1.1-3.1c.7-.9,1.7-1.3,2.8-1.3,2.7,0,3.8,2.2,3.8,4.3v.7ZM575.1,270.6h3.6c0-.9-.6-1.6-1.7-1.6s-1.6.7-1.8,1.6Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M584.9,272.3c-1.7-.4-2.5-1.3-2.5-2.6s1.3-2.5,3-2.5,2.2.5,3.1,1.4l-1.2,1.3c-.5-.7-1.2-1.1-2-1.1s-1.1.4-1.1.8.3.6.8.8l1.3.3c1.7.4,2.5,1.3,2.5,2.6s-1.2,2.6-3.3,2.6-2.6-.5-3.5-1.6l1.2-1.3c.6.8,1.4,1.2,2.4,1.2s1.3-.4,1.3-.9-.3-.6-1-.8l-1.1-.3Z"
                        fill="#0035a2"
                    />
                </g>
                <line
                    x1="463.2"
                    y1="320.5"
                    x2="592.6"
                    y2="320.5"
                    fill="none"
                    stroke="#0035a2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <g>
                    <path
                        d="M513.7,305.6h4.3v-9.5c-1.1.7-2.5,1.1-4.3,1.1v-3.3c2-.1,3.8-.8,4.6-2.4h3.3v14.1h4.1v3.4h-12.1v-3.4Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M539.7,306.8c-1.2,1.7-2.9,2.5-5.1,2.5s-3.9-.8-5.1-2.5c-1.2-1.7-1.8-3.8-1.8-6.5s.6-4.9,1.8-6.5c1.2-1.7,2.9-2.5,5.1-2.5s3.9.8,5.1,2.5c1.2,1.7,1.8,3.8,1.8,6.5s-.6,4.9-1.8,6.5ZM537.9,300.2c0-1.8-.3-3.1-.8-4.1-.5-1-1.3-1.5-2.4-1.5s-1.8.5-2.4,1.5c-.5,1-.8,2.4-.8,4.1s.3,3.1.8,4.1c.6,1,1.4,1.5,2.4,1.5s1.8-.5,2.4-1.5c.6-1,.8-2.4.8-4.1Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M555.9,306.8c-1.2,1.7-2.9,2.5-5.1,2.5s-3.9-.8-5.1-2.5-1.8-3.8-1.8-6.5.6-4.9,1.8-6.5c1.2-1.7,2.9-2.5,5.1-2.5s3.9.8,5.1,2.5c1.2,1.7,1.8,3.8,1.8,6.5s-.6,4.9-1.8,6.5ZM554.1,300.2c0-1.8-.3-3.1-.8-4.1-.5-1-1.3-1.5-2.4-1.5s-1.8.5-2.4,1.5c-.6,1-.8,2.4-.8,4.1s.3,3.1.8,4.1c.6,1,1.4,1.5,2.4,1.5s1.8-.5,2.4-1.5c.6-1,.8-2.4.8-4.1Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M572.1,306.8c-1.2,1.7-2.9,2.5-5.1,2.5s-3.9-.8-5.1-2.5c-1.2-1.7-1.8-3.8-1.8-6.5s.6-4.9,1.8-6.5c1.2-1.7,2.9-2.5,5.1-2.5s3.9.8,5.1,2.5c1.2,1.7,1.8,3.8,1.8,6.5s-.6,4.9-1.8,6.5ZM570.3,300.2c0-1.8-.3-3.1-.8-4.1-.5-1-1.3-1.5-2.4-1.5s-1.8.5-2.4,1.5c-.5,1-.8,2.4-.8,4.1s.3,3.1.8,4.1c.6,1,1.4,1.5,2.4,1.5s1.8-.5,2.4-1.5c.6-1,.8-2.4.8-4.1Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M584.1,311.9v-2.7c-2.5-.3-4.5-1.6-5.8-3.2l2.5-2.6c.9,1.2,2,1.9,3.3,2.3v-3.9c-3.4-.6-5.4-2.4-5.4-5.2s.5-2.6,1.5-3.5,2.3-1.5,3.9-1.7v-2.6h2.8v2.7c2,.4,3.7,1.2,5,2.6l-2.5,2.6c-.8-.8-1.6-1.3-2.5-1.7v3.5c3.7.7,5.6,2.5,5.6,5.4s-2.1,4.9-5.6,5.3v2.6h-2.8ZM584.1,298v-3.3c-1.1.2-1.7.8-1.7,1.6s.6,1.3,1.7,1.7ZM586.9,302.3v3.5c1.2-.2,1.9-.9,1.9-1.8s-.7-1.4-1.9-1.7Z"
                        fill="#0035a2"
                    />
                </g>
                <g>
                    <rect x="490.2" y="341" width="99.3" height="48.3" fill="none" />
                    <path
                        d="M539,350.9c-1.2,1.5-2.8,2.2-4.7,2.2s-3.3-.6-4.5-1.7-1.8-2.6-1.8-4.4.6-3.3,1.7-4.5c1.2-1.1,2.6-1.7,4.4-1.7s3.5.7,4.6,2.2l-1.3,1.4c-.8-1.2-1.9-1.8-3.3-1.8s-2.2.4-2.9,1.2c-.8.8-1.2,1.9-1.2,3.1s.4,2.2,1.2,3.1c.8.8,1.8,1.2,3,1.2s2.5-.6,3.4-1.8l1.3,1.4Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M547.6,345.7c.8.8,1.3,1.9,1.3,3.1s-.4,2.3-1.3,3.1-1.8,1.2-3,1.2-2.2-.4-3.1-1.2-1.2-1.9-1.2-3.1.4-2.3,1.2-3.1,1.9-1.3,3.1-1.3,2.2.4,3,1.3ZM546.2,350.7c.4-.5.7-1.1.7-1.9s-.2-1.4-.7-1.9c-.4-.5-1-.7-1.6-.7-1.4,0-2.3,1.1-2.3,2.6s1,2.6,2.3,2.6,1.2-.2,1.6-.7Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M556,353v-5c0-1.1-.6-1.8-1.5-1.8s-1.7.8-1.7,2v4.7h-1.9v-8.3h1.9v.7c.5-.6,1.3-.9,2.2-.9s1.8.4,2.3,1.1c.6-.7,1.5-1.1,2.6-1.1,2.2,0,3.3,1.3,3.3,3.7v4.8h-1.9v-5c0-1.1-.6-1.8-1.5-1.8s-1.7.8-1.7,2v4.7h-1.9Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M567.4,352.3v3.9h-1.9v-11.6h1.9v.7c.6-.6,1.4-1,2.4-1s2.2.4,2.9,1.2,1.1,1.9,1.1,3.1-.4,2.3-1.1,3.1c-.8.8-1.7,1.2-2.9,1.2s-1.7-.3-2.3-.8ZM571.2,350.7c.4-.5.6-1.1.6-1.9s-.2-1.4-.6-1.9c-.4-.5-.9-.8-1.6-.8-1.4,0-2.2,1.1-2.2,2.6s.8,2.6,2.2,2.6,1.2-.3,1.6-.8Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M575.9,350v-3.7h-1.2v-1.7h1.2v-2.3h1.9v2.3h2v1.7h-2v3.4c0,1.2.3,1.5,1.4,1.5h.6s0,1.7,0,1.7c-.3,0-.6,0-1,0-2.2,0-2.9-.8-2.9-3Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M588.7,349.4h-5.7c0,1.2,1,2,2.2,2s1.6-.4,2.1-1.2l1.3,1.2c-.9,1.1-2,1.7-3.5,1.7s-2.3-.4-3-1.2-1.2-1.8-1.2-3.1.4-2.3,1.1-3.1c.7-.9,1.7-1.3,2.8-1.3,2.7,0,3.8,2.2,3.8,4.3v.7ZM583.1,347.8h3.6c0-.9-.6-1.6-1.7-1.6s-1.6.7-1.8,1.6Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M519.6,365.6v-4.2h1.9v11.9h-1.9v-.7c-.6.6-1.4.9-2.4.9s-2.2-.4-2.9-1.2c-.8-.8-1.1-1.9-1.1-3.1s.4-2.3,1.1-3.1c.8-.8,1.7-1.2,2.9-1.2s1.7.3,2.3.8ZM515.2,369.2c0,1.5.8,2.7,2.2,2.7s2.2-1.2,2.2-2.7-.8-2.7-2.2-2.7-2.2,1.2-2.2,2.7Z"
                        fill="#0035a2"
                    />
                    <path d="M524.9,365.3h-1.8l1.6-3.9h2l-1.8,3.9Z" fill="#0035a2" />
                    <path
                        d="M530.6,371.8c.9,0,1.6-.4,2.1-1.2l1.3,1.2c-.9,1.1-2,1.7-3.5,1.7s-2.3-.4-3-1.2-1.2-1.8-1.2-3.1.4-2.3,1.1-3.1c.7-.9,1.7-1.3,2.8-1.3,2.7,0,3.8,2.2,3.8,4.3s0,.4,0,.7h-5.7c0,1.2,1,2,2.2,2ZM528.5,368.2h3.6c0-.9-.6-1.6-1.7-1.6s-1.6.7-1.8,1.6ZM530.5,361.4h2.4l-2,2.2h-1.9l1.5-2.2Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M538,372.7v3.9h-1.9v-11.6h1.9v.7c.6-.6,1.4-1,2.4-1s2.2.4,2.9,1.2,1.1,1.9,1.1,3.1-.4,2.3-1.1,3.1c-.8.8-1.7,1.2-2.9,1.2s-1.7-.3-2.3-.8ZM541.9,371.1c.4-.5.6-1.1.6-1.9s-.2-1.4-.6-1.9c-.4-.5-.9-.8-1.6-.8-1.4,0-2.2,1.1-2.2,2.6s.8,2.6,2.2,2.6,1.2-.3,1.6-.8Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M546.9,372.9c-.5-.5-.8-1.1-.8-1.9,0-1.8,1.4-2.8,3.5-2.8s1.1.1,1.7.3v-.6c0-.8-.7-1.4-1.6-1.4s-1.5.4-2,1.2l-1.3-1.2c.6-1.1,1.9-1.7,3.3-1.7,2.2,0,3.6,1.1,3.6,3.1v5.4h-1.9v-.6c-.6.6-1.4.8-2.4.8s-1.5-.2-2-.7ZM549.3,371.8c.8,0,1.5-.2,2-.7v-1c-.6-.2-1.2-.3-1.7-.3-.9,0-1.5.4-1.5,1s.4,1,1.3,1Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M560.4,366.8c0,0-.2,0-.3,0-1.9,0-2.6,1-2.6,3.3v3.3h-1.9v-8.3h1.9v.9c.6-.7,1.4-1,2.5-1s.2,0,.4,0v1.8Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M563,373.7c.5,1,1.3,1.4,2.3,1.4s2.2-.8,2.2-2.3v-.4c-.6.5-1.3.8-2.2.8s-2.1-.4-2.9-1.2c-.7-.8-1.1-1.8-1.1-3s.4-2.2,1.1-3c.7-.8,1.7-1.2,2.9-1.2s1.7.3,2.3.9v-.7h1.9v7.6c0,1.3-.4,2.3-1.1,3s-1.8,1.1-3,1.1-1.7-.2-2.3-.6-1.1-.8-1.4-1.3l1.4-1.3ZM565.4,371.4c1.3,0,2.1-1,2.1-2.4s-.8-2.4-2.1-2.4-2.1,1-2.1,2.4.8,2.4,2.1,2.4Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M573.9,373.4h-1.9v-8.3h1.9v.7c.5-.6,1.2-.9,2.2-.9,2.1,0,3.3,1.4,3.3,3.7v4.8h-1.9v-4.8c0-1.2-.6-1.9-1.6-1.9s-1.8.8-1.8,2.2v4.6Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M588.7,369.8h-5.7c0,1.2,1,2,2.2,2s1.6-.4,2.1-1.2l1.3,1.2c-.9,1.1-2,1.7-3.5,1.7s-2.3-.4-3-1.2-1.2-1.8-1.2-3.1.4-2.3,1.1-3.1c.7-.9,1.7-1.3,2.8-1.3,2.7,0,3.8,2.2,3.8,4.3v.7ZM583.1,368.2h3.6c0-.9-.6-1.6-1.7-1.6s-1.6.7-1.8,1.6Z"
                        fill="#0035a2"
                    />
                </g>
                <line
                    x1="410.2"
                    y1="418.2"
                    x2="592.6"
                    y2="418.2"
                    fill="none"
                    stroke="#0035a2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <g>
                    <path
                        d="M494.5,403.2h4.3v-9.5c-1.1.7-2.5,1.1-4.3,1.1v-3.3c2-.1,3.8-.8,4.6-2.4h3.3v14.1h4.1v3.4h-12.1v-3.4Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M520.5,404.4c-1.2,1.7-2.9,2.5-5.1,2.5s-3.9-.8-5.1-2.5-1.8-3.8-1.8-6.5.6-4.9,1.8-6.5c1.2-1.7,2.9-2.5,5.1-2.5s3.9.8,5.1,2.5c1.2,1.7,1.8,3.8,1.8,6.5s-.6,4.9-1.8,6.5ZM518.7,397.9c0-1.8-.3-3.1-.8-4.1-.5-1-1.3-1.5-2.4-1.5s-1.8.5-2.4,1.5c-.6,1-.8,2.4-.8,4.1s.3,3.1.8,4.1c.6,1,1.4,1.5,2.4,1.5s1.8-.5,2.4-1.5c.6-1,.8-2.4.8-4.1Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M539.7,404.4c-1.2,1.7-2.9,2.5-5.1,2.5s-3.9-.8-5.1-2.5c-1.2-1.7-1.8-3.8-1.8-6.5s.6-4.9,1.8-6.5c1.2-1.7,2.9-2.5,5.1-2.5s3.9.8,5.1,2.5c1.2,1.7,1.8,3.8,1.8,6.5s-.6,4.9-1.8,6.5ZM537.9,397.9c0-1.8-.3-3.1-.8-4.1-.5-1-1.3-1.5-2.4-1.5s-1.8.5-2.4,1.5c-.5,1-.8,2.4-.8,4.1s.3,3.1.8,4.1c.6,1,1.4,1.5,2.4,1.5s1.8-.5,2.4-1.5c.6-1,.8-2.4.8-4.1Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M555.9,404.4c-1.2,1.7-2.9,2.5-5.1,2.5s-3.9-.8-5.1-2.5-1.8-3.8-1.8-6.5.6-4.9,1.8-6.5c1.2-1.7,2.9-2.5,5.1-2.5s3.9.8,5.1,2.5c1.2,1.7,1.8,3.8,1.8,6.5s-.6,4.9-1.8,6.5ZM554.1,397.9c0-1.8-.3-3.1-.8-4.1-.5-1-1.3-1.5-2.4-1.5s-1.8.5-2.4,1.5c-.6,1-.8,2.4-.8,4.1s.3,3.1.8,4.1c.6,1,1.4,1.5,2.4,1.5s1.8-.5,2.4-1.5c.6-1,.8-2.4.8-4.1Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M572.1,404.4c-1.2,1.7-2.9,2.5-5.1,2.5s-3.9-.8-5.1-2.5-1.8-3.8-1.8-6.5.6-4.9,1.8-6.5c1.2-1.7,2.9-2.5,5.1-2.5s3.9.8,5.1,2.5c1.2,1.7,1.8,3.8,1.8,6.5s-.6,4.9-1.8,6.5ZM570.3,397.9c0-1.8-.3-3.1-.8-4.1-.5-1-1.3-1.5-2.4-1.5s-1.8.5-2.4,1.5c-.6,1-.8,2.4-.8,4.1s.3,3.1.8,4.1c.6,1,1.4,1.5,2.4,1.5s1.8-.5,2.4-1.5c.6-1,.8-2.4.8-4.1Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M584.1,409.5v-2.7c-2.5-.3-4.5-1.6-5.8-3.2l2.5-2.6c.9,1.2,2,1.9,3.3,2.3v-3.9c-3.4-.6-5.4-2.4-5.4-5.2s.5-2.6,1.5-3.5,2.3-1.5,3.9-1.7v-2.6h2.8v2.7c2,.4,3.7,1.2,5,2.6l-2.5,2.6c-.8-.8-1.6-1.3-2.5-1.7v3.5c3.7.7,5.6,2.5,5.6,5.4s-2.1,4.9-5.6,5.3v2.6h-2.8ZM584.1,395.7v-3.3c-1.1.2-1.7.8-1.7,1.6s.6,1.3,1.7,1.7ZM586.9,399.9v3.5c1.2-.2,1.9-.9,1.9-1.8s-.7-1.4-1.9-1.7Z"
                        fill="#0035a2"
                    />
                </g>
                <g>
                    <rect x="434.8" y="431.7" width="154.7" height="24.2" fill="none" />
                    <path
                        d="M447.7,441.2l3.1-9.5h2.1l-4.1,11.9h-2.2l-4.1-11.9h2.1l3.1,9.5Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M454.2,443.2c-.5-.5-.8-1.1-.8-1.9,0-1.8,1.4-2.8,3.5-2.8s1.1.1,1.7.3v-.6c0-.8-.7-1.4-1.6-1.4s-1.5.4-2,1.2l-1.3-1.2c.6-1.1,1.9-1.7,3.3-1.7,2.2,0,3.6,1.1,3.6,3.1v5.4h-1.9v-.6c-.6.6-1.4.8-2.4.8s-1.5-.2-2-.7ZM456.6,442.1c.8,0,1.5-.2,2-.7v-1c-.6-.2-1.2-.3-1.7-.3-.9,0-1.5.4-1.5,1s.4,1,1.3,1Z"
                        fill="#0035a2"
                    />
                    <path d="M462.7,443.7v-11.9h1.9v11.9h-1.9Z" fill="#0035a2" />
                    <path
                        d="M474.3,440.1h-5.7c0,1.2,1,2,2.2,2s1.6-.4,2.1-1.2l1.3,1.2c-.9,1.1-2,1.7-3.5,1.7s-2.3-.4-3-1.2-1.2-1.8-1.2-3.1.4-2.3,1.1-3.1c.7-.9,1.7-1.3,2.8-1.3,2.7,0,3.8,2.2,3.8,4.3v.7ZM468.7,438.5h3.6c0-.9-.6-1.6-1.7-1.6s-1.6.7-1.8,1.6Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M478,435.3v5c0,1.1.6,1.8,1.6,1.8s1.7-.8,1.7-2.1v-4.7h1.9v8.3h-1.9v-.7c-.5.6-1.3.9-2.2.9-1.9,0-3.1-1.4-3.1-3.7v-4.8h1.9Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M490.8,437.1c0,0-.2,0-.3,0-1.9,0-2.6,1-2.6,3.3v3.3h-1.9v-8.3h1.9v.9c.6-.7,1.4-1,2.5-1s.2,0,.4,0v1.8Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M502.5,435.9v-4.2h1.9v11.9h-1.9v-.7c-.6.6-1.4.9-2.4.9s-2.2-.4-2.9-1.2c-.8-.8-1.1-1.9-1.1-3.1s.4-2.3,1.1-3.1c.8-.8,1.7-1.2,2.9-1.2s1.7.3,2.3.8ZM498,439.5c0,1.5.8,2.7,2.2,2.7s2.2-1.2,2.2-2.7-.8-2.7-2.2-2.7-2.2,1.2-2.2,2.7Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M514,440.1h-5.7c0,1.2,1,2,2.2,2s1.6-.4,2.1-1.2l1.3,1.2c-.9,1.1-2,1.7-3.5,1.7s-2.3-.4-3-1.2c-.8-.8-1.2-1.8-1.2-3.1s.4-2.3,1.1-3.1c.7-.9,1.7-1.3,2.8-1.3,2.7,0,3.8,2.2,3.8,4.3v.7ZM508.4,438.5h3.6c0-.9-.6-1.6-1.7-1.6s-1.6.7-1.8,1.6Z"
                        fill="#0035a2"
                    />
                    <path d="M520.3,443.7v-11.9h1.9v11.9h-1.9Z" fill="#0035a2" />
                    <path
                        d="M525,443.2c-.5-.5-.8-1.1-.8-1.9,0-1.8,1.4-2.8,3.5-2.8s1.1.1,1.7.3v-.6c0-.8-.7-1.4-1.6-1.4s-1.5.4-2,1.2l-1.3-1.2c.6-1.1,1.9-1.7,3.3-1.7,2.2,0,3.6,1.1,3.6,3.1v5.4h-1.9v-.6c-.6.6-1.4.8-2.4.8s-1.5-.2-2-.7ZM527.4,442.1c.8,0,1.5-.2,2-.7v-1c-.6-.2-1.2-.3-1.7-.3-.9,0-1.5.4-1.5,1s.4,1,1.3,1Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M544.4,435.3l-3,8.3h-2.1l-3-8.3h2.1l1.9,5.8,1.9-5.8h2.1Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M552.2,436.4c.8.8,1.3,1.9,1.3,3.1s-.4,2.3-1.3,3.1-1.8,1.2-3,1.2-2.2-.4-3.1-1.2-1.2-1.9-1.2-3.1.4-2.3,1.2-3.1,1.9-1.3,3.1-1.3,2.2.4,3,1.3ZM550.8,441.4c.4-.5.7-1.1.7-1.9s-.2-1.4-.7-1.9c-.4-.5-1-.7-1.6-.7-1.4,0-2.3,1.1-2.3,2.6s1,2.6,2.3,2.6,1.2-.2,1.6-.7Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M555.5,431.9c.5-.5,1.3-.5,1.7,0,.5.5.5,1.2,0,1.7-.5.5-1.3.5-1.7,0s-.5-1.3,0-1.7ZM555.4,443.7v-8.3h1.9v8.3h-1.9Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M560,440.7v-3.7h-1.2v-1.7h1.2v-2.3h1.9v2.3h2v1.7h-2v3.4c0,1.2.3,1.5,1.4,1.5h.6s0,1.7,0,1.7c-.3,0-.6,0-1,0-2.2,0-2.9-.8-2.9-3Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M567.4,435.3v5c0,1.1.6,1.8,1.6,1.8s1.7-.8,1.7-2.1v-4.7h1.9v8.3h-1.9v-.7c-.5.6-1.3.9-2.2.9-1.9,0-3.1-1.4-3.1-3.7v-4.8h1.9Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M580.2,437.1c0,0-.2,0-.3,0-1.9,0-2.6,1-2.6,3.3v3.3h-1.9v-8.3h1.9v.9c.6-.7,1.4-1,2.5-1s.2,0,.4,0v1.8Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M588.7,440.1h-5.7c0,1.2,1,2,2.2,2s1.6-.4,2.1-1.2l1.3,1.2c-.9,1.1-2,1.7-3.5,1.7s-2.3-.4-3-1.2-1.2-1.8-1.2-3.1.4-2.3,1.1-3.1c.7-.9,1.7-1.3,2.8-1.3,2.7,0,3.8,2.2,3.8,4.3v.7ZM583.1,438.5h3.6c0-.9-.6-1.6-1.7-1.6s-1.6.7-1.8,1.6Z"
                        fill="#0035a2"
                    />
                </g>
                <polyline
                    points="592.6 488.1 355.3 488.1 355.3 455.9"
                    fill="none"
                    stroke="#0035a2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <g>
                    <path
                        d="M522.6,471.6c0,1.7-.6,3-1.9,3.9-1.2.9-2.8,1.4-4.7,1.4s-4.8-1.1-6.2-2.8l2.5-2.5c1,1.3,2.3,1.9,3.9,1.9s2.7-.7,2.7-2-1.1-2-3.3-2h-1.4v-3.4h1.2c2.1,0,3.1-.7,3.1-2s-.9-1.8-2.3-1.8-2.6.6-3.5,1.7l-2.4-2.4c1.6-1.8,3.5-2.7,5.8-2.7s3.1.4,4.3,1.2c1.2.8,1.8,2,1.8,3.6s-.7,2.9-1.9,3.8c1.3.7,2.4,2.1,2.4,4.1Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M539.7,474.4c-1.2,1.7-2.9,2.5-5.1,2.5s-3.9-.8-5.1-2.5c-1.2-1.7-1.8-3.8-1.8-6.5s.6-4.9,1.8-6.5c1.2-1.7,2.9-2.5,5.1-2.5s3.9.8,5.1,2.5c1.2,1.7,1.8,3.8,1.8,6.5s-.6,4.9-1.8,6.5ZM537.9,467.9c0-1.8-.3-3.1-.8-4.1-.5-1-1.3-1.5-2.4-1.5s-1.8.5-2.4,1.5c-.5,1-.8,2.4-.8,4.1s.3,3.1.8,4.1c.6,1,1.4,1.5,2.4,1.5s1.8-.5,2.4-1.5c.6-1,.8-2.4.8-4.1Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M555.9,474.4c-1.2,1.7-2.9,2.5-5.1,2.5s-3.9-.8-5.1-2.5c-1.2-1.7-1.8-3.8-1.8-6.5s.6-4.9,1.8-6.5c1.2-1.7,2.9-2.5,5.1-2.5s3.9.8,5.1,2.5c1.2,1.7,1.8,3.8,1.8,6.5s-.6,4.9-1.8,6.5ZM554.1,467.9c0-1.8-.3-3.1-.8-4.1-.5-1-1.3-1.5-2.4-1.5s-1.8.5-2.4,1.5c-.6,1-.8,2.4-.8,4.1s.3,3.1.8,4.1c.6,1,1.4,1.5,2.4,1.5s1.8-.5,2.4-1.5c.6-1,.8-2.4.8-4.1Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M572.1,474.4c-1.2,1.7-2.9,2.5-5.1,2.5s-3.9-.8-5.1-2.5c-1.2-1.7-1.8-3.8-1.8-6.5s.6-4.9,1.8-6.5c1.2-1.7,2.9-2.5,5.1-2.5s3.9.8,5.1,2.5c1.2,1.7,1.8,3.8,1.8,6.5s-.6,4.9-1.8,6.5ZM570.3,467.9c0-1.8-.3-3.1-.8-4.1-.5-1-1.3-1.5-2.4-1.5s-1.8.5-2.4,1.5c-.6,1-.8,2.4-.8,4.1s.3,3.1.8,4.1c.6,1,1.4,1.5,2.4,1.5s1.8-.5,2.4-1.5c.6-1,.8-2.4.8-4.1Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M584.1,479.5v-2.7c-2.5-.3-4.5-1.6-5.8-3.2l2.5-2.6c.9,1.2,2,1.9,3.3,2.3v-3.9c-3.4-.6-5.4-2.4-5.4-5.2s.5-2.6,1.5-3.5c1-.9,2.3-1.5,3.9-1.7v-2.6h2.8v2.7c2,.4,3.7,1.2,5,2.6l-2.5,2.6c-.8-.8-1.6-1.3-2.5-1.7v3.6c3.7.7,5.6,2.5,5.6,5.4s-2.1,4.9-5.6,5.3v2.6h-2.8ZM584.1,465.6v-3.3c-1.1.2-1.7.8-1.7,1.6s.6,1.3,1.7,1.7ZM586.9,469.9v3.5c1.2-.2,1.9-.9,1.9-1.8s-.7-1.4-1.9-1.7Z"
                        fill="#0035a2"
                    />
                </g>
                <polyline
                    points="23.8 608.1 251.2 608.1 251.2 456.3"
                    fill="none"
                    stroke="#0035a2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <polyline
                    points="590.3 563.2 295.6 563.2 295.6 455.9"
                    fill="none"
                    stroke="#0035a2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    d="M172.7,156.1c-33.1,33.6-53.6,79.7-53.6,130.6,0,75.2,44.7,140,108.9,169.3l77-169.3-132.4-130.6Z"
                    fill="url(#Nouveau_motif)"
                    opacity=".8"
                    stroke="url(#Nouveau_motif)"
                    strokeMiterlimit="10"
                />
                <g>
                    <rect x="24.5" y="423.9" width="205.7" height="62.6" fill="none" />
                    <path
                        d="M25.8,435.8v-11.9h3.7c1.7,0,3.2.4,4.4,1.2,1.3.9,2,2.5,2,4.6s-.8,3.8-2,4.7c-1.2.9-2.7,1.3-4.4,1.3h-3.7ZM27.9,425.7v8.2h1.3c1.4,0,2.4-.2,3.3-.8.9-.6,1.4-1.8,1.4-3.4s-.5-2.7-1.4-3.2c-.9-.6-1.9-.8-3.3-.8h-1.3Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M45.2,432.2h-5.7c0,1.2,1,2,2.2,2s1.6-.4,2.1-1.2l1.3,1.2c-.9,1.1-2,1.7-3.5,1.7s-2.3-.4-3-1.2c-.8-.8-1.2-1.8-1.2-3.1s.4-2.3,1.1-3.1c.7-.8,1.7-1.3,2.8-1.3,2.7,0,3.8,2.2,3.8,4.3v.7ZM39.6,430.7h3.6c0-.9-.6-1.6-1.7-1.6s-1.6.7-1.8,1.6Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M47.3,432.8v-3.7h-1.2v-1.7h1.2v-2.3h1.9v2.3h2v1.7h-2v3.4c0,1.2.3,1.5,1.4,1.5h.6s0,1.7,0,1.7c-.3,0-.6,0-1,0-2.2,0-2.9-.8-2.9-3Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M53.2,432.8v-3.7h-1.2v-1.7h1.2v-2.3h1.9v2.3h2v1.7h-2v3.4c0,1.2.3,1.5,1.4,1.5h.6s0,1.7,0,1.7c-.3,0-.6,0-1,0-2.2,0-2.9-.8-2.9-3Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M66,432.2h-5.7c0,1.2,1,2,2.2,2s1.6-.4,2.1-1.2l1.3,1.2c-.9,1.1-2,1.7-3.5,1.7s-2.3-.4-3-1.2-1.2-1.8-1.2-3.1.4-2.3,1.1-3.1c.7-.8,1.7-1.3,2.8-1.3,2.7,0,3.8,2.2,3.8,4.3v.7ZM60.4,430.7h3.6c0-.9-.6-1.6-1.7-1.6s-1.6.7-1.8,1.6Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M78,423.9l4.6,11.9h-2.1l-1-2.5h-5.1l-1,2.5h-2.1l4.7-11.9h2ZM76.9,426.6l-1.8,4.8h3.6l-1.8-4.8Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M84.1,424.1c.5-.5,1.3-.5,1.7,0,.5.5.5,1.2,0,1.7-.5.5-1.3.5-1.7,0s-.5-1.3,0-1.7ZM84,435.8v-8.3h1.9v8.3h-1.9Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M94.3,428.1v-4.2h1.9v11.9h-1.9v-.7c-.6.6-1.4.9-2.4.9s-2.2-.4-2.9-1.2c-.8-.8-1.1-1.9-1.1-3.1s.4-2.3,1.1-3.1c.8-.8,1.7-1.2,2.9-1.2s1.7.3,2.3.8ZM89.9,431.6c0,1.5.8,2.7,2.2,2.7s2.2-1.2,2.2-2.7-.8-2.7-2.2-2.7-2.2,1.2-2.2,2.7Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M105.8,432.2h-5.7c0,1.2,1,2,2.2,2s1.6-.4,2.1-1.2l1.3,1.2c-.9,1.1-2,1.7-3.5,1.7s-2.3-.4-3-1.2-1.2-1.8-1.2-3.1.4-2.3,1.1-3.1c.7-.8,1.7-1.3,2.8-1.3,2.7,0,3.8,2.2,3.8,4.3v.7ZM100.2,430.7h3.6c0-.9-.6-1.6-1.7-1.6s-1.6.7-1.8,1.6Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M30.2,444.3v1.8c-.2,0-.3,0-.4,0-1.2,0-1.8.5-1.8,1.7h0c0,.1,2,.1,2,.1v1.7h-2v6.6h-1.9v-6.6h-1.2v-1.7h1.2v-.4c0-2.1,1.1-3.2,3.3-3.2h.7Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M31.8,444.5c.5-.5,1.3-.5,1.7,0,.5.5.5,1.2,0,1.7-.5.5-1.3.5-1.7,0-.5-.5-.5-1.3,0-1.7ZM31.7,456.2v-8.3h1.9v8.3h-1.9Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M38,456.2h-1.9v-8.3h1.9v.7c.5-.6,1.2-.9,2.2-.9,2.1,0,3.3,1.4,3.3,3.7v4.8h-1.9v-4.8c0-1.2-.6-1.9-1.6-1.9s-1.8.8-1.8,2.2v4.6Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M46,455.7c-.5-.5-.8-1.1-.8-1.9,0-1.8,1.4-2.8,3.5-2.8s1.1.1,1.7.3v-.6c0-.8-.7-1.4-1.6-1.4s-1.5.4-2,1.2l-1.3-1.2c.6-1.1,1.9-1.7,3.3-1.7,2.2,0,3.6,1.1,3.6,3.1v5.4h-1.9v-.6c-.6.6-1.4.8-2.4.8s-1.5-.2-2-.7ZM48.4,454.7c.8,0,1.5-.2,2-.7v-1c-.6-.2-1.2-.3-1.7-.3-.9,0-1.5.4-1.5,1s.4,1,1.3,1Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M56.4,456.2h-1.9v-8.3h1.9v.7c.5-.6,1.2-.9,2.2-.9,2.1,0,3.3,1.4,3.3,3.7v4.8h-1.9v-4.8c0-1.2-.6-1.9-1.6-1.9s-1.8.8-1.8,2.2v4.6Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M65.7,452c0,1.5,1,2.6,2.3,2.6s1.5-.4,2.1-1.3l1.3,1.2c-.8,1.2-2,1.8-3.5,1.8s-2.1-.4-3-1.2c-.8-.8-1.2-1.9-1.2-3.1s.4-2.3,1.2-3.1c.8-.8,1.8-1.3,3-1.3s2.5.6,3.4,1.7l-1.3,1.3c-.6-.8-1.3-1.2-2-1.2-1.3,0-2.3,1.1-2.3,2.6Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M73.2,444.5c.5-.5,1.3-.5,1.7,0,.5.5.5,1.2,0,1.7-.5.5-1.3.5-1.7,0-.5-.5-.5-1.3,0-1.7ZM73.1,456.2v-8.3h1.9v8.3h-1.9Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M84.9,452.6h-5.7c0,1.2,1,2,2.2,2s1.6-.4,2.1-1.2l1.3,1.2c-.9,1.1-2,1.7-3.5,1.7s-2.3-.4-3-1.2c-.8-.8-1.2-1.8-1.2-3.1s.4-2.3,1.1-3.1c.7-.9,1.7-1.3,2.8-1.3,2.7,0,3.8,2.2,3.8,4.3v.7ZM78.7,444.3h2.4l1.5,2.2h-1.9l-2-2.2ZM79.3,451h3.6c0-.9-.6-1.6-1.7-1.6s-1.6.7-1.8,1.6Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M91.9,449.6c0,0-.2,0-.3,0-1.9,0-2.6,1-2.6,3.3v3.3h-1.9v-8.3h1.9v.9c.6-.7,1.4-1,2.5-1s.2,0,.4,0v1.8Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M100.4,452.6h-5.7c0,1.2,1,2,2.2,2s1.6-.4,2.1-1.2l1.3,1.2c-.9,1.1-2,1.7-3.5,1.7s-2.3-.4-3-1.2-1.2-1.8-1.2-3.1.4-2.3,1.1-3.1c.7-.9,1.7-1.3,2.8-1.3,2.7,0,3.8,2.2,3.8,4.3v.7ZM94.9,451h3.6c0-.9-.6-1.6-1.7-1.6s-1.6.7-1.8,1.6Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M107.1,455.7c-.5-.5-.8-1.1-.8-1.9,0-1.8,1.4-2.8,3.5-2.8s1.1.1,1.7.3v-.6c0-.8-.7-1.4-1.6-1.4s-1.5.4-2,1.2l-1.3-1.2c.6-1.1,1.9-1.7,3.3-1.7,2.2,0,3.6,1.1,3.6,3.1v5.4h-1.9v-.6c-.6.6-1.4.8-2.4.8s-1.5-.2-2-.7ZM109.5,454.7c.8,0,1.5-.2,2-.7v-1c-.6-.2-1.2-.3-1.7-.3-.9,0-1.5.4-1.5,1s.4,1,1.3,1Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M117.3,447.9v5c0,1.1.6,1.8,1.6,1.8s1.7-.8,1.7-2.1v-4.7h1.9v8.3h-1.9v-.7c-.5.6-1.3.9-2.2.9-1.9,0-3.1-1.4-3.1-3.7v-4.8h1.9Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M126.6,447.9l1.5,2.5,1.6-2.5h2.2l-2.7,4,2.9,4.3h-2.2l-1.8-2.7-1.7,2.7h-2.2l2.9-4.3-2.7-4h2.2Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M141.7,454.6c.9,0,1.6-.4,2.1-1.2l1.3,1.2c-.9,1.1-2,1.7-3.5,1.7s-2.3-.4-3-1.2c-.8-.8-1.2-1.8-1.2-3.1s.4-2.3,1.1-3.1c.7-.9,1.7-1.3,2.8-1.3,2.7,0,3.8,2.2,3.8,4.3s0,.4,0,.7h-5.7c0,1.2,1,2,2.2,2ZM139.6,451h3.6c0-.9-.6-1.6-1.7-1.6s-1.6.7-1.8,1.6ZM141.5,444.3h2.4l-2,2.2h-1.9l1.5-2.2Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M147.2,453.2v-3.7h-1.2v-1.7h1.2v-2.3h1.9v2.3h2v1.7h-2v3.4c0,1.2.3,1.5,1.4,1.5h.6s0,1.7,0,1.7c-.3,0-.6,0-1,0-2.2,0-2.9-.8-2.9-3Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M154.6,447.9v5c0,1.1.6,1.8,1.6,1.8s1.7-.8,1.7-2.1v-4.7h1.9v8.3h-1.9v-.7c-.5.6-1.3.9-2.2.9-1.9,0-3.1-1.4-3.1-3.7v-4.8h1.9Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M168.2,448.5v-4.2h1.9v11.9h-1.9v-.7c-.6.6-1.4.9-2.4.9s-2.2-.4-2.9-1.2c-.8-.8-1.1-1.9-1.1-3.1s.4-2.3,1.1-3.1c.8-.8,1.7-1.2,2.9-1.2s1.7.3,2.3.8ZM163.8,452c0,1.5.8,2.7,2.2,2.7s2.2-1.2,2.2-2.7-.8-2.7-2.2-2.7-2.2,1.2-2.2,2.7Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M179.8,452.6h-5.7c0,1.2,1,2,2.2,2s1.6-.4,2.1-1.2l1.3,1.2c-.9,1.1-2,1.7-3.5,1.7s-2.3-.4-3-1.2-1.2-1.8-1.2-3.1.4-2.3,1.1-3.1c.7-.9,1.7-1.3,2.8-1.3,2.7,0,3.8,2.2,3.8,4.3v.7ZM174.2,451h3.6c0-.9-.6-1.6-1.7-1.6s-1.6.7-1.8,1.6Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M184,452.8c-1.7-.4-2.5-1.3-2.5-2.6s1.3-2.5,3-2.5,2.2.5,3.1,1.4l-1.2,1.3c-.5-.7-1.2-1.1-2-1.1s-1.1.4-1.1.8.3.6.8.8l1.3.3c1.7.4,2.5,1.3,2.5,2.6s-1.2,2.6-3.3,2.6-2.6-.5-3.5-1.6l1.2-1.3c.6.8,1.4,1.2,2.4,1.2s1.3-.4,1.3-.9-.3-.6-1-.8l-1.1-.3Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M30.7,478.8l-1.4,1.3c-1-1-1.8-2.1-2.5-3.5-.6-1.3-1-2.8-1-4.4s.3-3.1,1-4.4c.7-1.3,1.5-2.5,2.5-3.5l1.4,1.3c-1.9,1.8-2.8,4-2.8,6.6s.9,4.7,2.8,6.6Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M38.1,464.7l4.6,11.9h-2.1l-1-2.5h-5.1l-1,2.5h-2.1l4.7-11.9h2ZM37.1,467.4l-1.8,4.8h3.6l-1.8-4.8Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M46.4,476.6h-2v-11.9h7.8v1.8h-5.8v3.1h5.4v1.9h-5.4v5.2Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M54.2,476.6v-11.9h8.4v1.8h-6.4v3.1h6.1v1.9h-6.1v3.3h6.4v1.8h-8.4Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M64.3,465.6l1.4-1.3c1,1,1.8,2.1,2.5,3.5.7,1.3,1,2.8,1,4.4s-.3,3.1-1,4.4c-.6,1.3-1.5,2.5-2.5,3.5l-1.4-1.3c1.9-1.8,2.8-4,2.8-6.6s-.9-4.7-2.8-6.6Z"
                        fill="#0035a2"
                    />
                </g>
                <polyline
                    points="23.8 514.9 203.5 514.9 203.5 431.6"
                    fill="none"
                    stroke="#0035a2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <g>
                    <path
                        d="M36.3,500v3.4h-12.5v-.6c0-2.1.4-3.9,1.3-5.3.9-1.4,2.3-2.6,4.4-3.7l1-.5c1.7-.9,2.2-1.6,2.2-2.5s-.9-1.8-2.4-1.8-3.1.9-4,2.8l-2.4-2.4c.5-1.1,1.4-2,2.5-2.7,1.1-.7,2.4-1.1,3.8-1.1s3.2.5,4.4,1.4c1.2.9,1.9,2.2,1.9,3.8,0,2.6-1.4,4.1-4.2,5.5l-1.5.8c-1.6.8-2.4,1.5-2.6,2.8h8.3Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M51.4,498.4c0,1.7-.6,3-1.9,3.9-1.2.9-2.8,1.4-4.7,1.4s-4.8-1.1-6.2-2.8l2.5-2.5c1,1.3,2.3,1.9,3.9,1.9s2.7-.7,2.7-2-1.1-2-3.3-2h-1.4v-3.4h1.2c2.1,0,3.1-.7,3.1-2s-.9-1.8-2.3-1.8-2.6.6-3.5,1.7l-2.4-2.4c1.6-1.8,3.5-2.7,5.8-2.7s3.1.4,4.3,1.2c1.2.8,1.8,2,1.8,3.6s-.6,2.9-1.9,3.8c1.3.7,2.4,2.1,2.4,4.1Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M68.5,501.2c-1.2,1.7-2.9,2.5-5.1,2.5s-3.9-.8-5.1-2.5c-1.2-1.7-1.8-3.8-1.8-6.5s.6-4.9,1.8-6.5c1.2-1.7,2.9-2.5,5.1-2.5s3.9.8,5.1,2.5c1.2,1.7,1.8,3.8,1.8,6.5s-.6,4.9-1.8,6.5ZM66.6,494.6c0-1.8-.3-3.1-.8-4.1-.5-1-1.3-1.5-2.4-1.5s-1.8.5-2.4,1.5c-.6,1-.8,2.4-.8,4.1s.3,3.1.8,4.1c.6,1,1.4,1.5,2.4,1.5s1.8-.5,2.4-1.5c.6-1,.8-2.4.8-4.1Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M84.7,501.2c-1.2,1.7-2.9,2.5-5.1,2.5s-3.9-.8-5.1-2.5c-1.2-1.7-1.8-3.8-1.8-6.5s.6-4.9,1.8-6.5c1.2-1.7,2.9-2.5,5.1-2.5s3.9.8,5.1,2.5c1.2,1.7,1.8,3.8,1.8,6.5s-.6,4.9-1.8,6.5ZM82.8,494.6c0-1.8-.3-3.1-.8-4.1-.5-1-1.3-1.5-2.4-1.5s-1.8.5-2.4,1.5c-.6,1-.8,2.4-.8,4.1s.3,3.1.8,4.1c.6,1,1.4,1.5,2.4,1.5s1.8-.5,2.4-1.5c.6-1,.8-2.4.8-4.1Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M100.9,501.2c-1.2,1.7-2.9,2.5-5.1,2.5s-3.9-.8-5.1-2.5c-1.2-1.7-1.8-3.8-1.8-6.5s.6-4.9,1.8-6.5c1.2-1.7,2.9-2.5,5.1-2.5s3.9.8,5.1,2.5c1.2,1.7,1.8,3.8,1.8,6.5s-.6,4.9-1.8,6.5ZM99,494.6c0-1.8-.3-3.1-.8-4.1-.5-1-1.3-1.5-2.4-1.5s-1.8.5-2.4,1.5c-.6,1-.8,2.4-.8,4.1s.3,3.1.8,4.1c.6,1,1.4,1.5,2.4,1.5s1.8-.5,2.4-1.5c.6-1,.8-2.4.8-4.1Z"
                        fill="#0035a2"
                    />
                    <path
                        d="M113.4,506.3v-2.7c-2.5-.3-4.5-1.6-5.8-3.2l2.5-2.6c.9,1.2,2,1.9,3.3,2.3v-3.9c-3.4-.6-5.4-2.4-5.4-5.2s.5-2.6,1.5-3.5c1-.9,2.3-1.5,3.9-1.7v-2.6h2.8v2.7c2,.4,3.7,1.2,5,2.6l-2.5,2.6c-.8-.8-1.6-1.3-2.5-1.7v3.5c3.7.7,5.6,2.5,5.6,5.4s-2.1,4.9-5.6,5.3v2.6h-2.8ZM113.4,492.4v-3.3c-1.1.2-1.7.8-1.7,1.6s.6,1.3,1.7,1.7ZM116.1,496.7v3.5c1.2-.2,1.9-.9,1.9-1.8s-.6-1.4-1.9-1.7Z"
                        fill="#0035a2"
                    />
                </g>
            </svg>
        </div>
    );
}

BudgetBreakdownRoxanne.propTypes = propTypes;
BudgetBreakdownRoxanne.defaultProps = defaultProps;

export default BudgetBreakdownRoxanne;
