/* eslint-disable react/jsx-props-no-spreading */
import { getComponentFromName } from '@folklore/utils';
import PropTypes from 'prop-types';
import React from 'react';

import * as Components from './components';

const propTypes = {
    kind: PropTypes.string,
};

const defaultProps = {
    kind: null,
};

function RemaxAnimation({ kind = null, ...otherProps }) {
    const Component = getComponentFromName(Components, kind, null);
    return Component !== null ? <Component {...otherProps} /> : null;
}

RemaxAnimation.propTypes = propTypes;
RemaxAnimation.defaultProps = defaultProps;

export default RemaxAnimation;
