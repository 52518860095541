import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from '../../styles/animations/remax/coin-stack.module.css';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function CoinStack({ className }) {
    return (
        <div className={classNames(styles.container, className)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 175.32 220.91">
                <g>
                    <g className={styles.topCoin}>
                        <ellipse className="cls-4" cx="81.08" cy="67.38" rx="67.33" ry="67.36" />
                        <path
                            className="cls-3"
                            d="M81.14,120.98c-5.59,0-11.21-.89-16.67-2.67-28.07-9.17-43.45-39.47-34.29-67.55,4.44-13.6,13.91-24.66,26.66-31.14,12.75-6.48,27.27-7.6,40.87-3.16,28.07,9.17,43.45,39.47,34.29,67.55l-.58-.19.58.19c-4.44,13.6-13.91,24.66-26.66,31.14-7.63,3.87-15.88,5.83-24.19,5.83ZM81.03,15.01c-8.12,0-16.18,1.91-23.64,5.7-12.46,6.33-21.71,17.14-26.05,30.43-8.95,27.44,6.08,57.05,33.5,66,13.29,4.34,27.47,3.24,39.93-3.09,12.46-6.33,21.71-17.14,26.05-30.43,8.95-27.44-6.08-57.05-33.5-66-5.34-1.74-10.83-2.61-16.29-2.61Z"
                        />
                        <path
                            className="cls-3"
                            d="M59.85,100.06l3.79-7.82c-6.77-4.45-11-11.19-12.5-17.78l11.1-3.92c.89,4.67,3.05,8.42,6.39,11.39l5.52-11.4c-9.02-6.53-12.49-14.62-8.42-23.02,2.05-4.24,5.25-6.93,9.55-8.18,4.31-1.25,8.92-1.09,13.7.59l3.75-7.75,8.11,3.93-3.86,7.97c5.31,3.93,8.97,8.86,10.94,14.86l-11.02,4.13c-1.1-3.33-2.77-6.21-5.01-8.47l-5.03,10.38c10.01,7.28,12.9,15.27,8.76,23.82-4,8.26-13.19,11.21-23.91,7.46l-3.75,7.75-8.11-3.93ZM79.45,59.58l4.71-9.72c-3.46-.86-6.21-.03-7.38,2.38-1.13,2.34-.23,4.76,2.67,7.34ZM81.55,75.93l-4.99,10.3c3.83,1.04,6.94.02,8.17-2.54,1.17-2.41.08-5.01-3.19-7.77Z"
                        />
                    </g>
                    <rect
                        className="cls-5"
                        x="24.72"
                        y="191.37"
                        width="150.6"
                        height="29.54"
                        rx="6.34"
                        ry="6.34"
                    />
                    <path
                        className="cls-3"
                        d="M129.03,216.6c-.34,0-.61-.28-.61-.61v-18.71c0-.34.28-.61.61-.61s.61.28.61.61v18.71c0,.34-.28.61-.61.61Z"
                    />
                    <path
                        className="cls-3"
                        d="M98.82,216.6c-.34,0-.61-.28-.61-.61v-18.71c0-.34.28-.61.61-.61s.61.28.61.61v18.71c0,.34-.28.61-.61.61Z"
                    />
                    <path
                        className="cls-3"
                        d="M88.75,216.6c-.34,0-.61-.28-.61-.61v-18.71c0-.34.28-.61.61-.61s.61.28.61.61v18.71c0,.34-.28.61-.61.61Z"
                    />
                    <rect
                        className="cls-1"
                        x="15.23"
                        y="161.58"
                        width="150.6"
                        height="29.54"
                        rx="6.34"
                        ry="6.34"
                        transform="translate(181.06 352.69) rotate(180)"
                    />
                    <path
                        className="cls-3"
                        d="M31.31,185.82c-.34,0-.61-.28-.61-.61v-18.71c0-.34.28-.61.61-.61s.61.28.61.61v18.71c0,.34-.28.61-.61.61Z"
                    />
                    <path
                        className="cls-3"
                        d="M41.38,185.82c-.34,0-.61-.28-.61-.61v-18.71c0-.34.28-.61.61-.61s.61.28.61.61v18.71c0,.34-.28.61-.61.61Z"
                    />
                    <path
                        className="cls-3"
                        d="M61.52,185.82c-.34,0-.61-.28-.61-.61v-18.71c0-.34.28-.61.61-.61s.61.28.61.61v18.71c0,.34-.28.61-.61.61Z"
                    />
                    <path
                        className="cls-3"
                        d="M91.73,185.82c-.34,0-.61-.28-.61-.61v-18.71c0-.34.28-.61.61-.61s.61.28.61.61v18.71c0,.34-.28.61-.61.61Z"
                    />
                    <path
                        className="cls-3"
                        d="M101.8,185.82c-.34,0-.61-.28-.61-.61v-18.71c0-.34.28-.61.61-.61s.61.28.61.61v18.71c0,.34-.28.61-.61.61Z"
                    />
                    <rect
                        className="cls-2"
                        y="131.78"
                        width="150.6"
                        height="29.54"
                        rx="6.34"
                        ry="6.34"
                    />
                    <path
                        className="cls-3"
                        d="M134.52,157.01c-.34,0-.61-.28-.61-.61v-18.71c0-.34.28-.61.61-.61s.61.28.61.61v18.71c0,.34-.28.61-.61.61Z"
                    />
                    <path
                        className="cls-3"
                        d="M124.45,157.01c-.34,0-.61-.28-.61-.61v-18.71c0-.34.28-.61.61-.61s.61.28.61.61v18.71c0,.34-.28.61-.61.61Z"
                    />
                    <path
                        className="cls-3"
                        d="M104.31,157.01c-.34,0-.61-.28-.61-.61v-18.71c0-.34.28-.61.61-.61s.61.28.61.61v18.71c0,.34-.28.61-.61.61Z"
                    />
                    <path
                        className="cls-3"
                        d="M74.09,157.01c-.34,0-.61-.28-.61-.61v-18.71c0-.34.28-.61.61-.61s.61.28.61.61v18.71c0,.34-.28.61-.61.61Z"
                    />
                    <path
                        className="cls-3"
                        d="M64.02,157.01c-.34,0-.61-.28-.61-.61v-18.71c0-.34.28-.61.61-.61s.61.28.61.61v18.71c0,.34-.28.61-.61.61Z"
                    />
                    <path
                        className="cls-3"
                        d="M138.71,162.5h0l-117.65-.48c-.34,0-.61-.28-.61-.62,0-.34.28-.61.61-.61h0l117.65.48c.34,0,.61.28.61.62,0,.34-.28.61-.61.61Z"
                    />
                    <path
                        className="cls-3"
                        d="M152.08,191.99l-120.91-.14c-.34,0-.61-.28-.61-.62,0-.34.28-.61.61-.61l120.91.14c.34,0,.61.28.61.62,0,.34-.28.61-.61.61Z"
                    />
                </g>
            </svg>
        </div>
    );
}

CoinStack.propTypes = propTypes;
CoinStack.defaultProps = defaultProps;

export default CoinStack;
