import { useResizeObserver } from '@folklore/hooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import styles from '../../styles/animations/remax/divider.module.css';

import downArrow from '../../assets/img/animations/remax/divider-arrow.svg';

const propTypes = {
    value: PropTypes.string,
    anchor: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    value: null,
    anchor: null,
    className: null,
};

function Divider({ value, anchor, className }) {
    const {
        entry: { contentRect: itemRect = null },
        ref: itemRef,
    } = useResizeObserver();
    const { width: itemWidth = null } = itemRect || {};

    const {
        entry: { contentRect: containerRect = null },
        ref: containerRef,
    } = useResizeObserver();
    const { width: containerWidth = null } = containerRect || {};

    const repeatCount = useMemo(() => {
        if (containerWidth === null || itemWidth === null) {
            return 1;
        }
        return Math.floor(containerWidth / itemWidth);
    }, [containerWidth, itemWidth]);

    const items = useMemo(
        () =>
            Array.from(Array(repeatCount).keys()).map((_, index) => (
                <div className={styles.value} ref={index === 0 ? itemRef : null}>
                    <span>{value}</span>
                    <img className={styles.separator} src={downArrow} alt="" />
                </div>
            )),
        [repeatCount],
    );

    return (
        <div
            id={anchor}
            ref={containerRef}
            className={classNames(styles.container, 'divider', className)}
        >
            {items}
        </div>
    );
}

Divider.propTypes = propTypes;
Divider.defaultProps = defaultProps;

export default Divider;
