import { useIntersectionObserver } from '@folklore/hooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from '../../styles/animations/remax/budget-breakdown-derek.module.css';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function BudgetBreakdownDerek({ className }) {
    const {
        ref: containerRef,
        entry: { isIntersecting = false },
    } = useIntersectionObserver({ rootMargin: '-20%' });

    return (
        <div
            ref={containerRef}
            className={classNames([
                styles.container,
                { [className]: className !== null, [styles.active]: isIntersecting },
            ])}
        >
            <svg viewBox="0 0 609.09 1317.12">
                <g>
                    <rect
                        fill="#e1e8f4"
                        stroke="#0d349d"
                        strokeMiterlimit={10}
                        strokeWidth={1.5}
                        x=".75"
                        y=".75"
                        width="607.59"
                        height="1315.62"
                        rx="12"
                        ry="12"
                    />
                    <g className={styles.pie}>
                        <path
                            fill="#1858b0"
                            d="M489.87,322.4l-185.01,20.88,134.67,128.57c39.63-41.51,56.28-96.56,50.33-149.45Z"
                        />
                        <path
                            fill="#2f6eb8"
                            d="M489.87,322.4c-1.38-12.33-4.01-24.53-7.84-36.42l-177.17,57.3,185.01-20.88Z"
                        />
                        <path
                            fill="#4d83c4"
                            d="M304.86,343.28l177.17-57.3c-9.19-28.52-25.4-55.22-48.6-77.37-74.38-71.01-192.23-68.27-263.24,6.11-31.24,32.73-48.2,73.87-51.07,115.74l185.74,12.83Z"
                        />
                        <path
                            fill="#1245a6"
                            d="M176.3,477.95c74.38,71.01,192.23,68.27,263.24-6.11l-134.67-128.57-185.74-12.83c-3.65,53.28,15.53,107.74,57.18,147.5Z"
                        />
                    </g>
                    <path
                        fill="#6a96cc"
                        d="M489.33,932.29c-2.23-56.5-30.09-111.29-80.2-145.89l-105.71,153.09,185.91-7.2Z"
                    />
                    <path
                        fill="#1858b0"
                        d="M151.98,1047.59c4.76,6.67,9.99,13.06,15.69,19.13l135.75-127.24-151.44,108.11Z"
                    />
                    <path
                        fill="#2f6eb8"
                        d="M151.98,1047.59l151.44-108.11-186.04-.94c-.16,38.7,11.74,77.02,34.61,109.05Z"
                    />
                    <path
                        className="cls-10"
                        d="M150.33,833.77c-22.12,32.04-32.81,68.57-32.96,104.77l186.04.94,105.71-153.09c-84.55-58.38-200.42-37.17-258.8,47.38Z"
                    />
                    <path
                        className="cls-11"
                        d="M489.33,932.29l-185.91,7.2-135.75,127.24c8.9,9.47,18.92,18.16,30.04,25.84,84.55,58.38,200.42,37.17,258.8-47.38,23.78-34.44,34.35-74.07,32.82-112.91Z"
                    />
                    <g>
                        <path
                            fill="#0d349d"
                            d="M45.22,170.73c-1.19,1.5-2.77,2.24-4.74,2.24-1.8,0-3.3-.56-4.47-1.7s-1.75-2.62-1.75-4.42.58-3.31,1.73-4.45c1.17-1.14,2.63-1.72,4.42-1.72,1.99,0,3.54.75,4.64,2.24l-1.31,1.36c-.82-1.17-1.92-1.75-3.3-1.75-1.17,0-2.16.41-2.94,1.22-.78.82-1.17,1.85-1.17,3.09s.39,2.24,1.19,3.06c.8.8,1.79,1.21,2.99,1.21,1.39,0,2.53-.59,3.43-1.78l1.27,1.39Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M47.08,172.79v-11.92h8.38v1.84h-6.36v3.08h6.07v1.85h-6.07v3.31h6.36v1.84h-8.38Z"
                        />
                        <path fill="#0d349d" d="M57.99,172.79v-11.92h2.02v10.08h5.64v1.84h-7.67Z" />
                        <path fill="#0d349d" d="M67.7,172.79v-11.92h2.02v11.92h-2.02Z" />
                        <path
                            fill="#0d349d"
                            d="M77.97,160.87l4.64,11.92h-2.12l-1-2.53h-5.08l-.99,2.53h-2.14l4.66-11.92h2.04ZM76.93,163.63l-1.8,4.76h3.62l-1.82-4.76Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M86.19,168.73v4.06h-2.02v-11.92h4.54c2.8,0,4.54,1.45,4.54,3.89s-1.75,3.96-4.54,3.96h-2.52ZM86.19,162.71v4.17h2.26c1.85,0,2.77-.7,2.77-2.09s-.92-2.07-2.77-2.07h-2.26Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M97.12,168.73v4.06h-2.02v-11.92h4.54c2.8,0,4.54,1.45,4.54,3.89s-1.75,3.96-4.54,3.96h-2.52ZM97.12,162.71v4.17h2.26c1.85,0,2.77-.7,2.77-2.09s-.92-2.07-2.77-2.07h-2.26Z"
                        />
                    </g>
                    <rect
                        fill="#1858b0"
                        x="13.06"
                        y="555.63"
                        width="580.73"
                        height="61.84"
                        rx="30.92"
                        ry="30.92"
                    />
                    <g className={styles.pie}>
                        <path
                            fill="#fff"
                            d="M41.19,589.4l4.1-12.7h3.8l-6,17.52h-3.85l-6-17.52h3.77l4.17,12.7Z"
                        />
                        <path
                            fill="#fff"
                            d="M59.89,576.7l6.85,17.52h-3.85l-1.4-3.45h-6.77l-1.38,3.45h-3.85l6.85-17.52h3.55ZM58.11,581.75l-2.17,5.72h4.35l-2.17-5.72Z"
                        />
                        <path fill="#fff" d="M68.71,594.23v-17.52h3.72v14.1h8v3.42h-11.72Z" />
                        <path
                            fill="#fff"
                            d="M82.84,594.23v-17.52h12.92v3.42h-9.2v3.47h8.77v3.37h-8.77v3.82h9.2v3.42h-12.92Z"
                        />
                        <path
                            fill="#fff"
                            d="M109.76,576.7h3.7v9.75c0,5.22-2.75,8.05-7.35,8.05s-7.35-2.83-7.35-8.05v-9.75h3.7v9.92c0,2.67,1.25,4.47,3.65,4.47s3.65-1.5,3.65-4.52v-9.87Z"
                        />
                        <path
                            fill="#fff"
                            d="M126.74,594.23l-3.45-5.62h-2.97v5.62h-3.72v-17.52h7.25c1.95,0,3.55.5,4.8,1.52,1.27,1.02,1.9,2.42,1.9,4.2,0,2.47-1.35,4.47-3.45,5.47l3.87,6.32h-4.22ZM120.31,580.13v5.07h2.87c2.42,0,3.65-.85,3.65-2.58s-1.15-2.5-3.65-2.5h-2.87Z"
                        />
                        <path
                            fill="#fff"
                            d="M151.28,594.23l-7.07-11.82v11.82h-3.72v-17.52h4.47l7.07,11.87v-11.87h3.7v17.52h-4.45Z"
                        />
                        <path
                            fill="#fff"
                            d="M159.16,594.23v-17.52h12.92v3.42h-9.2v3.47h8.77v3.37h-8.77v3.82h9.2v3.42h-12.92Z"
                        />
                        <path
                            fill="#fff"
                            d="M174.36,576.7h13.75v3.42h-5.02v14.1h-3.7v-14.1h-5.02v-3.42Z"
                        />
                        <path
                            fill="#fff"
                            d="M189.51,576.7h13.75v3.42h-5.02v14.1h-3.7v-14.1h-5.02v-3.42Z"
                        />
                        <path
                            fill="#fff"
                            d="M205.66,594.23v-17.52h12.92v3.42h-9.2v3.47h8.77v3.37h-8.77v3.82h9.2v3.42h-12.92Z"
                        />
                        <path
                            fill="#fff"
                            d="M228.84,594.23v-17.52h5.77c2.5,0,4.72.62,6.62,1.92,1.92,1.3,3.15,3.7,3.15,6.72s-1.22,5.47-3.12,6.85c-1.9,1.32-4.17,2.02-6.65,2.02h-5.77ZM232.56,580.13v10.67h1.5c4.37,0,6.55-1.83,6.55-5.45s-2.2-5.22-6.57-5.22h-1.47Z"
                        />
                        <path
                            fill="#fff"
                            d="M247.09,594.23v-17.52h12.92v3.42h-9.2v3.47h8.77v3.37h-8.77v3.82h9.2v3.42h-12.92Z"
                        />
                        <path
                            fill="#fff"
                            d="M270.26,594.23v-17.52h5.77c2.5,0,4.72.62,6.62,1.92,1.92,1.3,3.15,3.7,3.15,6.72s-1.22,5.47-3.12,6.85c-1.9,1.32-4.17,2.02-6.65,2.02h-5.77ZM273.98,580.13v10.67h1.5c4.37,0,6.55-1.83,6.55-5.45s-2.2-5.22-6.57-5.22h-1.47Z"
                        />
                        <path
                            fill="#fff"
                            d="M288.51,594.23v-17.52h12.92v3.42h-9.2v3.47h8.77v3.37h-8.77v3.82h9.2v3.42h-12.92Z"
                        />
                        <path
                            fill="#fff"
                            d="M314.86,594.23l-3.45-5.62h-2.97v5.62h-3.72v-17.52h7.25c1.95,0,3.55.5,4.8,1.52,1.27,1.02,1.9,2.42,1.9,4.2,0,2.47-1.35,4.47-3.45,5.47l3.87,6.32h-4.22ZM308.43,580.13v5.07h2.88c2.42,0,3.65-.85,3.65-2.58s-1.15-2.5-3.65-2.5h-2.88Z"
                        />
                        <path
                            fill="#fff"
                            d="M321.63,594.23v-17.52h12.92v3.42h-9.2v3.47h8.77v3.37h-8.77v3.82h9.2v3.42h-12.92Z"
                        />
                        <path
                            fill="#fff"
                            d="M337.83,594.23v-17.52h3.72v7.15l5.65-7.15h4.42l-6,7.5,6.57,10.02h-4.27l-4.67-7.15-1.7,2.17v4.97h-3.72Z"
                        />
                    </g>
                    <g>
                        <path
                            className="cls-12"
                            d="M480.86,589.19c0,1.67-.62,2.97-1.88,3.9s-2.82,1.4-4.72,1.4c-2.47,0-4.85-1.12-6.22-2.8l2.5-2.52c1.02,1.27,2.3,1.92,3.87,1.92s2.75-.72,2.75-2c0-1.37-1.1-2.05-3.32-2.05h-1.45v-3.45h1.17c2.1,0,3.15-.65,3.15-1.97,0-1.03-.9-1.8-2.3-1.8s-2.62.58-3.52,1.7l-2.42-2.42c1.57-1.77,3.52-2.67,5.85-2.67,1.7,0,3.15.4,4.32,1.22,1.2.83,1.8,2.02,1.8,3.62s-.65,2.92-1.95,3.8c1.33.67,2.38,2.15,2.38,4.12Z"
                        />
                        <path
                            className="cls-12"
                            d="M485.44,592.04c-1.25-1.65-1.87-3.9-1.87-6.75,0-2.67.67-4.82,2-6.45,1.35-1.62,3.17-2.42,5.47-2.42,2.12,0,4.2.97,5.42,2.38l-2.4,2.47c-.8-.97-1.85-1.45-3.17-1.45-1.05,0-1.95.4-2.67,1.22-.72.8-1.1,1.85-1.12,3.15.92-1.08,2.2-1.6,3.8-1.6s3.1.55,4.27,1.65c1.2,1.1,1.8,2.52,1.8,4.27s-.62,3.25-1.88,4.35c-1.22,1.07-2.72,1.62-4.47,1.62-2.17,0-3.9-.83-5.17-2.45ZM490.64,585.89c-1.5,0-2.62,1.08-2.62,2.6s1.12,2.6,2.62,2.6,2.62-1.1,2.62-2.6-1.12-2.6-2.62-2.6Z"
                        />
                        <path
                            className="cls-12"
                            d="M514.94,591.99c-1.22,1.67-2.92,2.5-5.1,2.5s-3.87-.83-5.1-2.5-1.85-3.85-1.85-6.55.62-4.87,1.85-6.52c1.22-1.67,2.92-2.5,5.1-2.5s3.87.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM513.09,585.44c0-1.75-.28-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.82,2.37-.82,4.12s.27,3.12.82,4.15c.58,1,1.38,1.5,2.42,1.5s1.85-.5,2.4-1.5c.57-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            className="cls-12"
                            d="M531.14,591.99c-1.22,1.67-2.92,2.5-5.1,2.5s-3.88-.83-5.1-2.5-1.85-3.85-1.85-6.55.62-4.87,1.85-6.52c1.22-1.67,2.92-2.5,5.1-2.5s3.87.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM529.29,585.44c0-1.75-.27-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.83,2.37-.83,4.12s.28,3.12.83,4.15c.58,1,1.37,1.5,2.42,1.5s1.85-.5,2.4-1.5c.58-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            className="cls-12"
                            d="M547.34,591.99c-1.22,1.67-2.92,2.5-5.1,2.5s-3.87-.83-5.1-2.5c-1.22-1.67-1.85-3.85-1.85-6.55s.62-4.87,1.85-6.52c1.23-1.67,2.92-2.5,5.1-2.5s3.87.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM545.49,585.44c0-1.75-.27-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.83,2.37-.83,4.12s.28,3.12.83,4.15c.57,1,1.37,1.5,2.42,1.5s1.85-.5,2.4-1.5c.58-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            className="cls-12"
                            d="M564.29,597.06v-2.67c-2.47-.33-4.55-1.62-5.85-3.25l2.55-2.57c.88,1.17,1.97,1.92,3.3,2.3v-3.9c-3.37-.6-5.42-2.38-5.42-5.25,0-1.45.52-2.62,1.55-3.55,1.02-.92,2.32-1.5,3.87-1.67v-2.65h2.78v2.72c2,.38,3.67,1.25,5.02,2.65l-2.5,2.62c-.75-.77-1.6-1.35-2.52-1.67v3.55c3.75.67,5.62,2.5,5.62,5.42s-2.15,4.87-5.62,5.27v2.65h-2.78ZM564.29,583.22v-3.32c-1.07.22-1.72.82-1.72,1.65s.58,1.35,1.72,1.67ZM567.06,587.47v3.52c1.2-.22,1.92-.92,1.92-1.8,0-.83-.65-1.4-1.92-1.72Z"
                        />
                    </g>
                    <g>
                        <path
                            fill="#0d349d"
                            d="M196.51,22.28l6.85,17.52h-3.85l-1.4-3.45h-6.77l-1.38,3.45h-3.85l6.85-17.52h3.55ZM194.73,27.33l-2.17,5.72h4.35l-2.17-5.72Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M221.06,36.78c-1.8,2.2-4.17,3.3-7.12,3.3-2.67,0-4.9-.83-6.67-2.47-1.75-1.65-2.62-3.82-2.62-6.52s.88-4.92,2.62-6.57c1.75-1.67,3.92-2.5,6.55-2.5,2.87,0,5.2,1.1,6.97,3.3l-2.5,2.55c-1.02-1.47-2.72-2.37-4.65-2.37-1.38,0-2.57.5-3.62,1.5-1.03.98-1.55,2.33-1.55,4.02s.52,3.1,1.55,4.1c1.02,1,2.3,1.5,3.82,1.5,1.97,0,3.55-.83,4.75-2.45l2.47,2.62Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M222.61,22.28h13.75v3.42h-5.02v14.1h-3.7v-14.1h-5.02v-3.42Z"
                        />
                        <path fill="#0d349d" d="M238.91,39.8v-17.52h3.7v17.52h-3.7Z" />
                        <path
                            fill="#0d349d"
                            d="M249.89,39.8h-3.72v-17.52h12.07v3.42h-8.35v3.8h7.77v3.4h-7.77v6.9Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M266.03,32.55c-3.42-.58-5.52-2.35-5.52-5.25,0-1.62.62-2.9,1.9-3.85,1.27-.97,2.8-1.45,4.57-1.45,2.72,0,4.97.92,6.75,2.8l-2.5,2.62c-1.32-1.35-2.8-2.02-4.45-2.02s-2.58.7-2.58,1.72c0,.92.83,1.55,2.5,1.85l1.72.27c3.92.62,5.9,2.45,5.9,5.47,0,1.6-.65,2.9-1.92,3.88-1.27.97-2.95,1.47-5.05,1.47-3.07,0-5.7-1.38-7.27-3.35l2.55-2.57c1.3,1.67,2.95,2.52,4.95,2.52s3.05-.8,3.05-1.9c0-.92-.83-1.55-2.45-1.85l-2.15-.38Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M284.01,39.8v-17.52h12.92v3.42h-9.2v3.47h8.77v3.37h-8.77v3.82h9.2v3.42h-12.92Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M299.21,22.28h13.75v3.42h-5.02v14.1h-3.7v-14.1h-5.02v-3.42Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M326.06,34.25v5.55h-3.72v-17.52h7.1c2.07,0,3.72.52,4.97,1.6,1.25,1.05,1.88,2.47,1.88,4.27s-.62,3.3-1.9,4.42c-1.28,1.12-2.92,1.67-4.95,1.67h-3.37ZM326.06,25.71v5.12h2.72c2.52,0,3.8-.88,3.8-2.6s-1.22-2.52-3.8-2.52h-2.72Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M347.66,22.28l6.85,17.52h-3.85l-1.4-3.45h-6.77l-1.38,3.45h-3.85l6.85-17.52h3.55ZM345.88,27.33l-2.17,5.72h4.35l-2.17-5.72Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M361.58,32.55c-3.42-.58-5.52-2.35-5.52-5.25,0-1.62.62-2.9,1.9-3.85,1.28-.97,2.8-1.45,4.57-1.45,2.72,0,4.97.92,6.75,2.8l-2.5,2.62c-1.32-1.35-2.8-2.02-4.45-2.02s-2.57.7-2.57,1.72c0,.92.83,1.55,2.5,1.85l1.72.27c3.92.62,5.9,2.45,5.9,5.47,0,1.6-.65,2.9-1.92,3.88-1.27.97-2.95,1.47-5.05,1.47-3.07,0-5.7-1.38-7.27-3.35l2.55-2.57c1.3,1.67,2.95,2.52,4.95,2.52s3.05-.8,3.05-1.9c0-.92-.83-1.55-2.45-1.85l-2.15-.38Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M377.68,32.55c-3.42-.58-5.52-2.35-5.52-5.25,0-1.62.62-2.9,1.9-3.85,1.28-.97,2.8-1.45,4.57-1.45,2.72,0,4.97.92,6.75,2.8l-2.5,2.62c-1.32-1.35-2.8-2.02-4.45-2.02s-2.57.7-2.57,1.72c0,.92.83,1.55,2.5,1.85l1.72.27c3.92.62,5.9,2.45,5.9,5.47,0,1.6-.65,2.9-1.92,3.88-1.27.97-2.95,1.47-5.05,1.47-3.07,0-5.7-1.38-7.27-3.35l2.55-2.57c1.3,1.67,2.95,2.52,4.95,2.52s3.05-.8,3.05-1.9c0-.92-.83-1.55-2.45-1.85l-2.15-.38Z"
                        />
                        <path fill="#0d349d" d="M388.83,39.8v-17.52h3.7v17.52h-3.7Z" />
                        <path
                            fill="#0d349d"
                            d="M399.81,39.8h-3.72v-17.52h12.07v3.42h-8.35v3.8h7.77v3.4h-7.77v6.9Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M415.96,32.55c-3.42-.58-5.52-2.35-5.52-5.25,0-1.62.62-2.9,1.9-3.85,1.28-.97,2.8-1.45,4.57-1.45,2.72,0,4.97.92,6.75,2.8l-2.5,2.62c-1.32-1.35-2.8-2.02-4.45-2.02s-2.57.7-2.57,1.72c0,.92.83,1.55,2.5,1.85l1.72.27c3.92.62,5.9,2.45,5.9,5.47,0,1.6-.65,2.9-1.92,3.88-1.27.97-2.95,1.47-5.05,1.47-3.07,0-5.7-1.38-7.27-3.35l2.55-2.57c1.3,1.67,2.95,2.52,4.95,2.52s3.05-.8,3.05-1.9c0-.92-.83-1.55-2.45-1.85l-2.15-.38Z"
                        />
                    </g>
                    <line className="cls-9" x1="13.06" y1="62.91" x2="593.78" y2="62.91" />
                    <line
                        stroke="#0d349d"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        x1="26.96"
                        y1="211.47"
                        x2="201.86"
                        y2="211.47"
                    />
                    <g>
                        <path
                            fill="#0d349d"
                            d="M34.51,196.54h4.27v-9.47c-1.1.72-2.53,1.1-4.27,1.1v-3.32c2-.1,3.85-.83,4.62-2.4h3.35v14.1h4.12v3.42h-12.1v-3.42Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M50.49,197.79c-1.25-1.65-1.87-3.9-1.87-6.75,0-2.67.67-4.82,2-6.45,1.35-1.62,3.17-2.42,5.47-2.42,2.12,0,4.2.97,5.42,2.38l-2.4,2.47c-.8-.97-1.85-1.45-3.17-1.45-1.05,0-1.95.4-2.67,1.22-.72.8-1.1,1.85-1.12,3.15.92-1.08,2.2-1.6,3.8-1.6s3.1.55,4.27,1.65c1.2,1.1,1.8,2.52,1.8,4.27s-.62,3.25-1.88,4.35c-1.22,1.07-2.72,1.62-4.47,1.62-2.17,0-3.9-.83-5.17-2.45ZM55.69,191.64c-1.5,0-2.62,1.08-2.62,2.6s1.12,2.6,2.62,2.6,2.62-1.1,2.62-2.6-1.12-2.6-2.62-2.6Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M78.99,197.74c-1.22,1.67-2.92,2.5-5.1,2.5s-3.87-.83-5.1-2.5c-1.22-1.67-1.85-3.85-1.85-6.55s.62-4.87,1.85-6.52c1.23-1.67,2.92-2.5,5.1-2.5s3.88.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM77.14,191.2c0-1.75-.28-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.83,2.37-.83,4.12s.27,3.12.83,4.15c.57,1,1.38,1.5,2.42,1.5s1.85-.5,2.4-1.5c.57-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M95.19,197.74c-1.22,1.67-2.92,2.5-5.1,2.5s-3.88-.83-5.1-2.5-1.85-3.85-1.85-6.55.62-4.87,1.85-6.52c1.22-1.67,2.92-2.5,5.1-2.5s3.87.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM93.34,191.2c0-1.75-.27-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.83,2.37-.83,4.12s.28,3.12.83,4.15c.58,1,1.37,1.5,2.42,1.5s1.85-.5,2.4-1.5c.58-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M111.39,197.74c-1.22,1.67-2.92,2.5-5.1,2.5s-3.87-.83-5.1-2.5c-1.23-1.67-1.85-3.85-1.85-6.55s.62-4.87,1.85-6.52c1.22-1.67,2.92-2.5,5.1-2.5s3.87.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM109.54,191.2c0-1.75-.27-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.83,2.37-.83,4.12s.28,3.12.83,4.15c.57,1,1.37,1.5,2.42,1.5s1.85-.5,2.4-1.5c.58-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M123.36,202.82v-2.67c-2.47-.33-4.55-1.62-5.85-3.25l2.55-2.57c.88,1.17,1.97,1.92,3.3,2.3v-3.9c-3.37-.6-5.42-2.38-5.42-5.25,0-1.45.52-2.62,1.55-3.55,1.02-.92,2.32-1.5,3.87-1.67v-2.65h2.78v2.72c2,.38,3.67,1.25,5.02,2.65l-2.5,2.62c-.75-.77-1.6-1.35-2.52-1.67v3.55c3.75.67,5.62,2.5,5.62,5.42s-2.15,4.87-5.62,5.27v2.65h-2.78ZM123.36,188.97v-3.32c-1.07.22-1.72.82-1.72,1.65s.58,1.35,1.72,1.67ZM126.14,193.22v3.52c1.2-.22,1.92-.92,1.92-1.8,0-.83-.65-1.4-1.92-1.72Z"
                        />
                    </g>
                    <g>
                        <path
                            fill="#0d349d"
                            d="M45.5,450.97c-1.19,1.5-2.77,2.24-4.74,2.24-1.8,0-3.3-.56-4.47-1.7s-1.75-2.62-1.75-4.42.58-3.31,1.73-4.45c1.17-1.14,2.63-1.72,4.42-1.72,1.99,0,3.54.75,4.64,2.24l-1.31,1.36c-.82-1.17-1.92-1.75-3.3-1.75-1.17,0-2.16.41-2.94,1.22-.78.82-1.17,1.85-1.17,3.09s.39,2.24,1.19,3.06c.8.8,1.79,1.21,2.99,1.21,1.39,0,2.53-.59,3.43-1.78l1.27,1.39Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M47.35,453.02v-11.92h8.38v1.84h-6.36v3.08h6.07v1.85h-6.07v3.31h6.36v1.84h-8.38Z"
                        />
                        <path fill="#0d349d" d="M58.26,453.02v-11.92h2.02v10.08h5.64v1.84h-7.67Z" />
                        <path fill="#0d349d" d="M67.97,453.02v-11.92h2.02v11.92h-2.02Z" />
                    </g>
                    <line
                        stroke="#0d349d"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        x1="26.96"
                        y1="491.7"
                        x2="229.29"
                        y2="491.7"
                    />
                    <g>
                        <path
                            fill="#0d349d"
                            d="M35.88,476.78h4.27v-9.47c-1.1.72-2.52,1.1-4.27,1.1v-3.32c2-.1,3.85-.83,4.62-2.4h3.35v14.1h4.12v3.42h-12.1v-3.42Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M63.4,476.5h-2.42v3.7h-3.7v-3.7h-7.87v-3.05l7.5-10.77h4.07v10.55h2.42v3.27ZM53.25,473.23h4.02v-5.87l-4.02,5.87Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M80.6,477.98c-1.22,1.67-2.92,2.5-5.1,2.5s-3.88-.83-5.1-2.5-1.85-3.85-1.85-6.55.62-4.87,1.85-6.52c1.22-1.67,2.92-2.5,5.1-2.5s3.87.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM78.75,471.43c0-1.75-.27-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.83,2.37-.83,4.12s.28,3.12.83,4.15c.58,1,1.37,1.5,2.42,1.5s1.85-.5,2.4-1.5c.58-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M96.8,477.98c-1.22,1.67-2.92,2.5-5.1,2.5s-3.87-.83-5.1-2.5c-1.22-1.67-1.85-3.85-1.85-6.55s.62-4.87,1.85-6.52c1.23-1.67,2.92-2.5,5.1-2.5s3.87.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM94.95,471.43c0-1.75-.27-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.83,2.37-.83,4.12s.28,3.12.83,4.15c.57,1,1.37,1.5,2.42,1.5s1.85-.5,2.4-1.5c.58-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M113,477.98c-1.22,1.67-2.92,2.5-5.1,2.5s-3.87-.83-5.1-2.5c-1.23-1.67-1.85-3.85-1.85-6.55s.62-4.87,1.85-6.52c1.22-1.67,2.92-2.5,5.1-2.5s3.88.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM111.15,471.43c0-1.75-.28-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.83,2.37-.83,4.12s.27,3.12.83,4.15c.57,1,1.38,1.5,2.42,1.5s1.85-.5,2.4-1.5c.57-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M124.98,483.05v-2.67c-2.47-.33-4.55-1.62-5.85-3.25l2.55-2.57c.87,1.17,1.97,1.92,3.3,2.3v-3.9c-3.38-.6-5.42-2.38-5.42-5.25,0-1.45.53-2.62,1.55-3.55,1.03-.92,2.33-1.5,3.88-1.67v-2.65h2.77v2.72c2,.38,3.67,1.25,5.02,2.65l-2.5,2.62c-.75-.77-1.6-1.35-2.53-1.67v3.55c3.75.67,5.62,2.5,5.62,5.42s-2.15,4.87-5.62,5.27v2.65h-2.77ZM124.98,469.21v-3.32c-1.08.22-1.72.82-1.72,1.65s.57,1.35,1.72,1.67ZM127.75,473.45v3.52c1.2-.22,1.93-.92,1.93-1.8,0-.83-.65-1.4-1.93-1.72Z"
                        />
                    </g>
                    <g>
                        <path
                            fill="#0d349d"
                            d="M538.9,239.5c-1.19,1.5-2.77,2.24-4.74,2.24-1.8,0-3.3-.56-4.47-1.7s-1.75-2.62-1.75-4.42.58-3.31,1.73-4.45c1.17-1.14,2.63-1.72,4.42-1.72,1.99,0,3.54.75,4.64,2.24l-1.31,1.36c-.82-1.17-1.92-1.75-3.3-1.75-1.17,0-2.16.41-2.94,1.22-.78.82-1.17,1.85-1.17,3.09s.39,2.24,1.19,3.06c.8.8,1.78,1.21,2.99,1.21,1.39,0,2.53-.59,3.43-1.78l1.28,1.39Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M547.5,234.28c.83.83,1.26,1.87,1.26,3.11s-.42,2.28-1.26,3.11-1.84,1.24-3.04,1.24-2.23-.41-3.06-1.24-1.24-1.87-1.24-3.11.41-2.28,1.24-3.11,1.85-1.26,3.06-1.26,2.21.42,3.04,1.26ZM546.11,239.26c.44-.49.66-1.1.66-1.87s-.22-1.38-.66-1.87c-.42-.49-.99-.73-1.65-.73-1.36,0-2.33,1.09-2.33,2.6s.97,2.6,2.33,2.6c.66,0,1.22-.24,1.65-.73Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M555.9,241.56v-4.98c0-1.1-.56-1.8-1.53-1.8-1.11,0-1.72.8-1.72,2.04v4.74h-1.94v-8.33h1.9v.73c.53-.63,1.26-.93,2.16-.93,1.04,0,1.8.36,2.33,1.09.65-.73,1.51-1.09,2.6-1.09,2.19,0,3.32,1.29,3.32,3.69v4.84h-1.94v-4.98c0-1.1-.56-1.8-1.53-1.8-1.11,0-1.73.8-1.73,2.04v4.74h-1.92Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M567.1,240.94v3.91h-1.94v-11.63h1.9v.75c.59-.63,1.39-.95,2.36-.95,1.19,0,2.16.41,2.92,1.24s1.14,1.87,1.14,3.13-.37,2.29-1.14,3.13c-.76.82-1.73,1.22-2.92,1.22-.9,0-1.68-.27-2.33-.8ZM570.93,239.28c.39-.51.59-1.14.59-1.87s-.2-1.36-.59-1.87c-.39-.51-.94-.76-1.63-.76-1.43,0-2.23,1.14-2.23,2.63s.8,2.63,2.23,2.63c.7,0,1.24-.25,1.63-.76Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M575.76,238.61v-3.66h-1.22v-1.73h1.22v-2.33h1.92v2.33h1.99v1.73h-1.99v3.37c0,1.21.27,1.51,1.36,1.51l.63-.03v1.73c-.25.02-.59.03-1.04.03-2.16,0-2.87-.8-2.87-2.96Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M588.71,237.97h-5.69c.08,1.24.95,2.02,2.19,2.02.9,0,1.62-.39,2.14-1.17l1.31,1.22c-.88,1.14-2.04,1.7-3.45,1.7-1.24,0-2.26-.39-3.03-1.19s-1.16-1.84-1.16-3.11.36-2.29,1.05-3.14c.71-.85,1.67-1.28,2.84-1.28,2.7,0,3.81,2.16,3.81,4.27l-.02.68ZM583.12,236.42h3.55c-.02-.87-.56-1.63-1.73-1.63-1.02,0-1.63.73-1.82,1.63Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M527.34,257.79c0,1.51.97,2.6,2.28,2.6.97,0,1.51-.41,2.14-1.29l1.33,1.24c-.83,1.21-2.01,1.8-3.54,1.8-1.16,0-2.14-.41-2.96-1.24-.82-.83-1.22-1.87-1.22-3.11s.41-2.28,1.22-3.11c.82-.83,1.8-1.26,2.96-1.26,1.39,0,2.52.56,3.38,1.67l-1.34,1.29c-.59-.8-1.26-1.19-1.99-1.19-1.31,0-2.26,1.09-2.26,2.6Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M536.69,261.95h-1.94v-11.92h1.94v4.17c.56-.53,1.26-.78,2.07-.78,1.99,0,3.35,1.38,3.35,3.69v4.84h-1.94v-4.84c0-1.17-.61-1.94-1.65-1.94-1.17,0-1.84.83-1.84,2.18v4.61Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M551.73,258.37h-5.69c.08,1.24.95,2.02,2.19,2.02.9,0,1.62-.39,2.14-1.17l1.31,1.22c-.88,1.14-2.04,1.7-3.45,1.7-1.24,0-2.26-.39-3.03-1.19-.77-.8-1.16-1.84-1.16-3.11s.36-2.29,1.05-3.14c.71-.85,1.67-1.28,2.84-1.28,2.7,0,3.81,2.16,3.81,4.27l-.02.68ZM545.51,250.04h2.38l1.55,2.23h-1.9l-2.02-2.23ZM546.14,256.82h3.55c-.02-.87-.56-1.63-1.73-1.63-1.02,0-1.63.73-1.82,1.63Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M559.81,254.37v-.75h1.9v11.63h-1.94v-3.91c-.65.53-1.43.8-2.33.8-1.19,0-2.16-.41-2.92-1.22-.77-.83-1.14-1.87-1.14-3.13s.37-2.29,1.14-3.13c.76-.83,1.73-1.24,2.92-1.24.99,0,1.77.32,2.36.95ZM555.93,255.94c-.39.51-.58,1.14-.58,1.87s.19,1.36.58,1.87c.39.51.95.76,1.65.76,1.43,0,2.23-1.14,2.23-2.63s-.8-2.63-2.23-2.63c-.7,0-1.26.25-1.65.76Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M565.79,253.63v4.98c0,1.1.56,1.8,1.56,1.8,1.09,0,1.73-.85,1.73-2.09v-4.69h1.94v8.33h-1.9v-.68c-.53.58-1.26.87-2.16.87-1.89,0-3.11-1.36-3.11-3.67v-4.84h1.94Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M580.67,258.37h-5.69c.08,1.24.95,2.02,2.19,2.02.9,0,1.62-.39,2.14-1.17l1.31,1.22c-.88,1.14-2.04,1.7-3.45,1.7-1.24,0-2.26-.39-3.03-1.19s-1.16-1.84-1.16-3.11.36-2.29,1.05-3.14c.71-.85,1.67-1.28,2.84-1.28,2.7,0,3.81,2.16,3.81,4.27l-.02.68ZM575.07,256.82h3.55c-.02-.87-.56-1.63-1.73-1.63-1.02,0-1.63.73-1.82,1.63Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M584.92,258.54c-1.68-.39-2.53-1.26-2.53-2.63,0-1.51,1.26-2.48,3.04-2.48,1.21,0,2.24.46,3.14,1.39l-1.24,1.33c-.51-.7-1.16-1.05-1.96-1.05-.7,0-1.14.36-1.14.78,0,.39.27.65.82.78l1.31.31c1.7.39,2.55,1.26,2.55,2.6,0,1.51-1.19,2.58-3.3,2.58-1.44,0-2.6-.54-3.45-1.61l1.22-1.26c.61.8,1.41,1.21,2.38,1.21.9,0,1.27-.37,1.27-.85,0-.36-.25-.61-1.02-.82l-1.11-.27Z"
                        />
                    </g>
                    <line
                        stroke="#0d349d"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        x1="463.17"
                        y1="306.76"
                        x2="592.56"
                        y2="306.76"
                    />
                    <g>
                        <path
                            fill="#0d349d"
                            d="M513.68,291.83h4.27v-9.47c-1.1.72-2.52,1.1-4.27,1.1v-3.32c2-.1,3.85-.83,4.62-2.4h3.35v14.1h4.12v3.42h-12.1v-3.42Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M539.71,293.03c-1.22,1.67-2.92,2.5-5.1,2.5s-3.87-.83-5.1-2.5c-1.22-1.67-1.85-3.85-1.85-6.55s.62-4.87,1.85-6.52c1.23-1.67,2.92-2.5,5.1-2.5s3.87.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM537.86,286.49c0-1.75-.28-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.83,2.37-.83,4.12s.28,3.12.83,4.15c.58,1,1.38,1.5,2.42,1.5s1.85-.5,2.4-1.5c.57-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M555.91,293.03c-1.22,1.67-2.92,2.5-5.1,2.5s-3.87-.83-5.1-2.5-1.85-3.85-1.85-6.55.62-4.87,1.85-6.52c1.22-1.67,2.92-2.5,5.1-2.5s3.87.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM554.06,286.49c0-1.75-.27-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.83,2.37-.83,4.12s.27,3.12.83,4.15c.57,1,1.37,1.5,2.42,1.5s1.85-.5,2.4-1.5c.58-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M572.11,293.03c-1.22,1.67-2.92,2.5-5.1,2.5s-3.87-.83-5.1-2.5c-1.22-1.67-1.85-3.85-1.85-6.55s.62-4.87,1.85-6.52c1.23-1.67,2.92-2.5,5.1-2.5s3.87.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM570.26,286.49c0-1.75-.28-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.83,2.37-.83,4.12s.28,3.12.83,4.15c.58,1,1.38,1.5,2.42,1.5s1.85-.5,2.4-1.5c.57-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M584.08,298.11v-2.67c-2.47-.33-4.55-1.62-5.85-3.25l2.55-2.57c.87,1.17,1.97,1.92,3.3,2.3v-3.9c-3.37-.6-5.42-2.38-5.42-5.25,0-1.45.53-2.62,1.55-3.55s2.32-1.5,3.87-1.67v-2.65h2.77v2.72c2,.38,3.67,1.25,5.02,2.65l-2.5,2.62c-.75-.77-1.6-1.35-2.52-1.67v3.55c3.75.67,5.62,2.5,5.62,5.42s-2.15,4.87-5.62,5.27v2.65h-2.77ZM584.08,284.26v-3.32c-1.08.22-1.72.82-1.72,1.65s.58,1.35,1.72,1.67ZM586.86,288.51v3.52c1.2-.22,1.92-.92,1.92-1.8,0-.83-.65-1.4-1.92-1.72Z"
                        />
                    </g>
                    <g>
                        <path
                            fill="#0d349d"
                            d="M538.9,369.24c-1.19,1.5-2.77,2.24-4.74,2.24-1.8,0-3.3-.56-4.47-1.7s-1.75-2.62-1.75-4.42.58-3.31,1.73-4.45c1.17-1.14,2.63-1.72,4.42-1.72,1.99,0,3.54.75,4.64,2.24l-1.31,1.36c-.82-1.17-1.92-1.75-3.3-1.75-1.17,0-2.16.41-2.94,1.22-.78.82-1.17,1.85-1.17,3.09s.39,2.24,1.19,3.06c.8.8,1.78,1.21,2.99,1.21,1.39,0,2.53-.59,3.43-1.78l1.28,1.39Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M547.5,364.02c.83.83,1.26,1.87,1.26,3.11s-.42,2.28-1.26,3.11-1.84,1.24-3.04,1.24-2.23-.41-3.06-1.24-1.24-1.87-1.24-3.11.41-2.28,1.24-3.11,1.85-1.26,3.06-1.26,2.21.42,3.04,1.26ZM546.11,369c.44-.49.66-1.1.66-1.87s-.22-1.38-.66-1.87c-.42-.49-.99-.73-1.65-.73-1.36,0-2.33,1.09-2.33,2.6s.97,2.6,2.33,2.6c.66,0,1.22-.24,1.65-.73Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M555.9,371.29v-4.98c0-1.1-.56-1.8-1.53-1.8-1.11,0-1.72.8-1.72,2.04v4.74h-1.94v-8.33h1.9v.73c.53-.63,1.26-.93,2.16-.93,1.04,0,1.8.36,2.33,1.09.65-.73,1.51-1.09,2.6-1.09,2.19,0,3.32,1.29,3.32,3.69v4.84h-1.94v-4.98c0-1.1-.56-1.8-1.53-1.8-1.11,0-1.73.8-1.73,2.04v4.74h-1.92Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M567.1,370.68v3.91h-1.94v-11.63h1.9v.75c.59-.63,1.39-.95,2.36-.95,1.19,0,2.16.41,2.92,1.24s1.14,1.87,1.14,3.13-.37,2.29-1.14,3.13c-.76.82-1.73,1.22-2.92,1.22-.9,0-1.68-.27-2.33-.8ZM570.93,369.01c.39-.51.59-1.14.59-1.87s-.2-1.36-.59-1.87c-.39-.51-.94-.76-1.63-.76-1.43,0-2.23,1.14-2.23,2.63s.8,2.63,2.23,2.63c.7,0,1.24-.25,1.63-.76Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M575.76,368.35v-3.66h-1.22v-1.73h1.22v-2.33h1.92v2.33h1.99v1.73h-1.99v3.37c0,1.21.27,1.51,1.36,1.51l.63-.03v1.73c-.25.02-.59.03-1.04.03-2.16,0-2.87-.8-2.87-2.96Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M588.71,367.71h-5.69c.08,1.24.95,2.02,2.19,2.02.9,0,1.62-.39,2.14-1.17l1.31,1.22c-.88,1.14-2.04,1.7-3.45,1.7-1.24,0-2.26-.39-3.03-1.19s-1.16-1.84-1.16-3.11.36-2.29,1.05-3.14c.71-.85,1.67-1.28,2.84-1.28,2.7,0,3.81,2.16,3.81,4.27l-.02.68ZM583.12,366.16h3.55c-.02-.87-.56-1.63-1.73-1.63-1.02,0-1.63.73-1.82,1.63Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M517.77,383.97v-4.2h1.94v11.92h-1.9v-.75c-.59.63-1.39.93-2.36.93-1.19,0-2.16-.41-2.92-1.22-.77-.83-1.14-1.87-1.14-3.13s.37-2.29,1.14-3.13c.76-.83,1.73-1.24,2.92-1.24.92,0,1.7.27,2.33.82ZM513.35,387.53c0,1.5.82,2.65,2.23,2.65s2.23-1.16,2.23-2.65-.8-2.65-2.23-2.65-2.23,1.16-2.23,2.65Z"
                        />
                        <path fill="#0d349d" d="M523.06,383.67h-1.79l1.58-3.89h2.04l-1.84,3.89Z" />
                        <path
                            fill="#0d349d"
                            d="M530.82,390.13c.9,0,1.62-.39,2.14-1.17l1.31,1.22c-.88,1.14-2.04,1.7-3.45,1.7-1.24,0-2.26-.39-3.03-1.19s-1.16-1.84-1.16-3.11.36-2.29,1.05-3.14c.71-.85,1.67-1.28,2.84-1.28,2.7,0,3.81,2.16,3.81,4.27,0,.19,0,.42-.02.68h-5.69c.08,1.24.95,2.02,2.19,2.02ZM528.73,386.56h3.55c-.02-.87-.56-1.63-1.73-1.63-1.02,0-1.63.73-1.82,1.63ZM530.67,379.78h2.38l-2.02,2.23h-1.9l1.55-2.23Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M538.24,391.08v3.91h-1.94v-11.63h1.9v.75c.59-.63,1.39-.95,2.36-.95,1.19,0,2.16.41,2.92,1.24s1.14,1.87,1.14,3.13-.37,2.29-1.14,3.13c-.76.82-1.73,1.22-2.92,1.22-.9,0-1.68-.27-2.33-.8ZM542.06,389.41c.39-.51.59-1.14.59-1.87s-.2-1.36-.59-1.87c-.39-.51-.94-.76-1.63-.76-1.43,0-2.23,1.14-2.23,2.63s.8,2.63,2.23,2.63c.7,0,1.24-.25,1.63-.76Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M547.06,391.2c-.53-.46-.8-1.09-.8-1.85,0-1.82,1.43-2.79,3.47-2.79.54,0,1.1.1,1.68.29v-.56c0-.83-.7-1.38-1.63-1.38-.87,0-1.53.41-1.97,1.21l-1.31-1.22c.63-1.14,1.94-1.73,3.3-1.73,2.16,0,3.55,1.09,3.55,3.14v5.39h-1.9v-.65c-.63.56-1.43.83-2.38.83-.8,0-1.46-.22-2.01-.68ZM549.46,390.18c.8,0,1.46-.24,1.96-.71v-.95c-.59-.22-1.17-.34-1.73-.34-.9,0-1.48.37-1.48,1.04,0,.56.44.97,1.26.97Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M560.39,385.1c-.08-.02-.19-.02-.31-.02-1.9,0-2.57,1.02-2.57,3.35v3.26h-1.94v-8.33h1.9v.9c.56-.68,1.41-1.02,2.53-1.02.12,0,.24,0,.37.02v1.83Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M563.21,392c.53.97,1.31,1.45,2.31,1.45,1.34,0,2.21-.82,2.21-2.33v-.42c-.6.54-1.33.82-2.23.82-1.17,0-2.12-.39-2.87-1.17-.73-.8-1.11-1.78-1.11-2.99s.37-2.21,1.12-2.99c.75-.8,1.7-1.19,2.86-1.19.92,0,1.67.29,2.26.88v-.68h1.9v7.65c0,1.26-.37,2.26-1.12,3.03s-1.75,1.14-2.99,1.14c-.94,0-1.72-.2-2.33-.59s-1.09-.8-1.41-1.26l1.39-1.33ZM565.62,389.74c1.29,0,2.14-.99,2.14-2.4s-.85-2.41-2.14-2.41-2.12.99-2.12,2.41.83,2.4,2.12,2.4Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M573.87,391.69h-1.94v-8.33h1.9v.68c.53-.59,1.24-.88,2.16-.88,2.06,0,3.3,1.36,3.3,3.69v4.84h-1.94v-4.84c0-1.17-.61-1.94-1.65-1.94-1.17,0-1.84.83-1.84,2.18v4.61Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M588.71,388.1h-5.69c.08,1.24.95,2.02,2.19,2.02.9,0,1.62-.39,2.14-1.17l1.31,1.22c-.88,1.14-2.04,1.7-3.45,1.7-1.24,0-2.26-.39-3.03-1.19s-1.16-1.84-1.16-3.11.36-2.29,1.05-3.14c.71-.85,1.67-1.28,2.84-1.28,2.7,0,3.81,2.16,3.81,4.27l-.02.68ZM583.12,386.56h3.55c-.02-.87-.56-1.63-1.73-1.63-1.02,0-1.63.73-1.82,1.63Z"
                        />
                    </g>
                    <line
                        stroke="#0d349d"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        x1="447.02"
                        y1="436.5"
                        x2="592.56"
                        y2="436.5"
                    />
                    <g>
                        <path
                            fill="#0d349d"
                            d="M515.66,416.57c-1.3,0-2.17.55-2.65,1.67h-3.42l.42-10.77h10.82v3.42h-7.35l-.15,3.22c.8-.53,1.77-.8,2.92-.8,1.62,0,3.02.52,4.2,1.6,1.17,1.05,1.77,2.45,1.77,4.17,0,1.85-.65,3.35-1.95,4.47-1.27,1.12-2.95,1.7-4.97,1.7-2.52,0-4.82-1.1-6.05-2.77l2.65-2.62c1,1.33,2.22,2,3.67,2,1.67,0,2.95-1.05,2.95-2.57s-1.2-2.72-2.87-2.72Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M539.71,422.77c-1.22,1.67-2.92,2.5-5.1,2.5s-3.87-.83-5.1-2.5c-1.22-1.67-1.85-3.85-1.85-6.55s.62-4.87,1.85-6.52c1.23-1.67,2.92-2.5,5.1-2.5s3.87.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM537.86,416.22c0-1.75-.28-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.83,2.37-.83,4.12s.28,3.12.83,4.15c.58,1,1.38,1.5,2.42,1.5s1.85-.5,2.4-1.5c.57-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M555.91,422.77c-1.22,1.67-2.92,2.5-5.1,2.5s-3.87-.83-5.1-2.5-1.85-3.85-1.85-6.55.62-4.87,1.85-6.52c1.22-1.67,2.92-2.5,5.1-2.5s3.87.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM554.06,416.22c0-1.75-.27-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.83,2.37-.83,4.12s.27,3.12.83,4.15c.57,1,1.37,1.5,2.42,1.5s1.85-.5,2.4-1.5c.58-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M572.11,422.77c-1.22,1.67-2.92,2.5-5.1,2.5s-3.87-.83-5.1-2.5c-1.22-1.67-1.85-3.85-1.85-6.55s.62-4.87,1.85-6.52c1.23-1.67,2.92-2.5,5.1-2.5s3.87.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM570.26,416.22c0-1.75-.28-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.83,2.37-.83,4.12s.28,3.12.83,4.15c.58,1,1.38,1.5,2.42,1.5s1.85-.5,2.4-1.5c.57-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M584.08,427.84v-2.67c-2.47-.33-4.55-1.62-5.85-3.25l2.55-2.57c.87,1.17,1.97,1.92,3.3,2.3v-3.9c-3.37-.6-5.42-2.38-5.42-5.25,0-1.45.53-2.62,1.55-3.55s2.32-1.5,3.87-1.67v-2.65h2.77v2.72c2,.38,3.67,1.25,5.02,2.65l-2.5,2.62c-.75-.77-1.6-1.35-2.52-1.67v3.55c3.75.67,5.62,2.5,5.62,5.42s-2.15,4.87-5.62,5.27v2.65h-2.77ZM584.08,414v-3.32c-1.08.22-1.72.82-1.72,1.65s.58,1.35,1.72,1.67ZM586.86,418.25v3.52c1.2-.22,1.92-.92,1.92-1.8,0-.83-.65-1.4-1.92-1.72Z"
                        />
                    </g>
                    <g>
                        <path
                            className="cls-11"
                            d="M35.09,102.72v-17.52h5.47c2.5,0,4.65.55,6.47,1.82,1.87,1.27,3,3.65,3,6.82s-1.12,5.62-3,6.95c-1.83,1.33-3.97,1.92-6.47,1.92h-5.47ZM38.06,87.9v12.12h1.95c2,0,3.52-.33,4.85-1.2,1.38-.9,2.12-2.57,2.12-4.97s-.75-3.95-2.12-4.77c-1.32-.83-2.85-1.17-4.87-1.17h-1.92Z"
                        />
                        <path
                            className="cls-11"
                            d="M53.19,102.72v-17.52h12.32v2.7h-9.35v4.52h8.92v2.72h-8.92v4.87h9.35v2.7h-12.32Z"
                        />
                        <path
                            className="cls-11"
                            d="M79.51,102.72l-3.67-5.97h-3.62v5.97h-2.97v-17.52h6.67c4.12,0,6.67,2.12,6.67,5.72,0,2.57-1.3,4.45-3.57,5.32l4.02,6.47h-3.52ZM72.21,87.9v6.12h3.32c2.72,0,4.07-1.02,4.07-3.07s-1.35-3.05-4.07-3.05h-3.32Z"
                        />
                        <path
                            className="cls-11"
                            d="M85.89,102.72v-17.52h12.32v2.7h-9.35v4.52h8.92v2.72h-8.92v4.87h9.35v2.7h-12.32Z"
                        />
                        <path
                            className="cls-11"
                            d="M101.94,102.72v-17.52h2.97v8.15l6.55-8.15h3.7l-5.92,7.4,6.5,10.12h-3.57l-4.97-7.6-2.28,2.77v4.82h-2.97Z"
                        />
                    </g>
                    <line strokeWidth={1.5} x1="13.06" y1="122.82" x2="593.78" y2="122.82" />
                    <g>
                        <path
                            fill="#0d349d"
                            d="M45.22,924.05c-1.19,1.5-2.77,2.24-4.74,2.24-1.8,0-3.3-.56-4.47-1.7s-1.75-2.62-1.75-4.42.58-3.31,1.73-4.45c1.17-1.14,2.63-1.72,4.42-1.72,1.99,0,3.54.75,4.64,2.24l-1.31,1.36c-.82-1.17-1.92-1.75-3.3-1.75-1.17,0-2.16.41-2.94,1.22-.78.82-1.17,1.85-1.17,3.09s.39,2.24,1.19,3.06c.8.8,1.79,1.21,2.99,1.21,1.39,0,2.53-.59,3.43-1.78l1.27,1.39Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M47.08,926.1v-11.92h8.38v1.84h-6.36v3.08h6.07v1.85h-6.07v3.31h6.36v1.84h-8.38Z"
                        />
                        <path fill="#0d349d" d="M57.99,926.1v-11.92h2.02v10.08h5.64v1.84h-7.67Z" />
                        <path fill="#0d349d" d="M67.7,926.1v-11.92h2.02v11.92h-2.02Z" />
                    </g>
                    <rect
                        fill="#1858b0"
                        x="13.06"
                        y="1151.84"
                        width="580.73"
                        height="61.84"
                        rx="30.92"
                        ry="30.92"
                    />
                    <g>
                        <path
                            fill="#fff"
                            d="M41.19,1185.6l4.1-12.7h3.8l-6,17.52h-3.85l-6-17.52h3.77l4.17,12.7Z"
                        />
                        <path
                            fill="#fff"
                            d="M59.89,1172.91l6.85,17.52h-3.85l-1.4-3.45h-6.77l-1.38,3.45h-3.85l6.85-17.52h3.55ZM58.11,1177.96l-2.17,5.72h4.35l-2.17-5.72Z"
                        />
                        <path fill="#fff" d="M68.71,1190.43v-17.52h3.72v14.1h8v3.42h-11.72Z" />
                        <path
                            fill="#fff"
                            d="M82.84,1190.43v-17.52h12.92v3.42h-9.2v3.47h8.77v3.37h-8.77v3.82h9.2v3.42h-12.92Z"
                        />
                        <path
                            fill="#fff"
                            d="M109.76,1172.91h3.7v9.75c0,5.22-2.75,8.05-7.35,8.05s-7.35-2.83-7.35-8.05v-9.75h3.7v9.92c0,2.67,1.25,4.47,3.65,4.47s3.65-1.5,3.65-4.52v-9.87Z"
                        />
                        <path
                            fill="#fff"
                            d="M126.74,1190.43l-3.45-5.62h-2.97v5.62h-3.72v-17.52h7.25c1.95,0,3.55.5,4.8,1.52,1.27,1.02,1.9,2.42,1.9,4.2,0,2.47-1.35,4.47-3.45,5.47l3.87,6.32h-4.22ZM120.31,1176.33v5.07h2.87c2.42,0,3.65-.85,3.65-2.58s-1.15-2.5-3.65-2.5h-2.87Z"
                        />
                        <path
                            fill="#fff"
                            d="M151.28,1190.43l-7.07-11.82v11.82h-3.72v-17.52h4.47l7.07,11.87v-11.87h3.7v17.52h-4.45Z"
                        />
                        <path
                            fill="#fff"
                            d="M159.16,1190.43v-17.52h12.92v3.42h-9.2v3.47h8.77v3.37h-8.77v3.82h9.2v3.42h-12.92Z"
                        />
                        <path
                            fill="#fff"
                            d="M174.36,1172.91h13.75v3.42h-5.02v14.1h-3.7v-14.1h-5.02v-3.42Z"
                        />
                        <path
                            fill="#fff"
                            d="M189.51,1172.91h13.75v3.42h-5.02v14.1h-3.7v-14.1h-5.02v-3.42Z"
                        />
                        <path
                            fill="#fff"
                            d="M205.66,1190.43v-17.52h12.92v3.42h-9.2v3.47h8.77v3.37h-8.77v3.82h9.2v3.42h-12.92Z"
                        />
                        <path
                            fill="#fff"
                            d="M228.84,1190.43v-17.52h5.77c2.5,0,4.72.62,6.62,1.92,1.92,1.3,3.15,3.7,3.15,6.72s-1.22,5.47-3.12,6.85c-1.9,1.32-4.17,2.02-6.65,2.02h-5.77ZM232.56,1176.33v10.67h1.5c4.37,0,6.55-1.83,6.55-5.45s-2.2-5.22-6.57-5.22h-1.47Z"
                        />
                        <path fill="#fff" d="M248.19,1172.91h3.8l-2.72,5.85h-3.45l2.38-5.85Z" />
                        <path
                            fill="#fff"
                            d="M256.56,1175.21c1.72-1.72,3.87-2.57,6.4-2.57s4.7.85,6.45,2.57c1.75,1.7,2.62,3.85,2.62,6.45s-.88,4.77-2.62,6.5c-1.75,1.7-3.9,2.55-6.45,2.55s-4.67-.85-6.4-2.55c-1.72-1.72-2.6-3.87-2.6-6.5s.87-4.75,2.6-6.45ZM259.21,1185.6c.95,1.08,2.22,1.6,3.85,1.6s2.85-.52,3.77-1.6c.95-1.07,1.42-2.37,1.42-3.95s-.5-2.83-1.47-3.9c-.97-1.08-2.2-1.62-3.72-1.62-1.6,0-2.88.53-3.85,1.6-.95,1.08-1.42,2.38-1.42,3.92s.48,2.87,1.42,3.95Z"
                        />
                        <path fill="#fff" d="M274.74,1190.43v-17.52h3.72v14.1h8v3.42h-11.72Z" />
                        <path fill="#fff" d="M289.01,1190.43v-17.52h3.7v17.52h-3.7Z" />
                        <path
                            fill="#fff"
                            d="M302.66,1185.6l4.1-12.7h3.8l-6,17.52h-3.85l-6-17.52h3.77l4.17,12.7Z"
                        />
                        <path fill="#fff" d="M312.53,1190.43v-17.52h3.7v17.52h-3.7Z" />
                        <path
                            fill="#fff"
                            d="M328.76,1172.91l6.85,17.52h-3.85l-1.4-3.45h-6.77l-1.38,3.45h-3.85l6.85-17.52h3.55ZM326.98,1177.96l-2.17,5.72h4.35l-2.17-5.72Z"
                        />
                    </g>
                    <g>
                        <path
                            className="cls-12"
                            d="M481.39,1186.99v3.42h-12.52v-.58c0-2.12.43-3.9,1.28-5.27.85-1.4,2.32-2.62,4.45-3.7l.97-.47c1.72-.88,2.17-1.62,2.17-2.52,0-1.08-.92-1.85-2.42-1.85-1.78,0-3.12.92-4.05,2.8l-2.4-2.4c.53-1.12,1.38-2.03,2.5-2.72,1.15-.72,2.4-1.08,3.77-1.08,1.72,0,3.22.45,4.45,1.38,1.25.92,1.88,2.2,1.88,3.82,0,2.57-1.45,4.15-4.17,5.55l-1.53.77c-1.62.83-2.42,1.55-2.65,2.85h8.27Z"
                        />
                        <path
                            className="cls-12"
                            d="M496.24,1188.19c-1.22,1.67-2.92,2.5-5.1,2.5s-3.87-.83-5.1-2.5c-1.22-1.67-1.85-3.85-1.85-6.55s.62-4.87,1.85-6.52c1.23-1.67,2.92-2.5,5.1-2.5s3.87.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM494.39,1181.64c0-1.75-.27-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.83,2.37-.83,4.12s.28,3.12.83,4.15c.57,1,1.37,1.5,2.42,1.5s1.85-.5,2.4-1.5c.58-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            className="cls-12"
                            d="M516.93,1186.72h-2.42v3.7h-3.7v-3.7h-7.87v-3.05l7.5-10.77h4.07v10.55h2.42v3.27ZM506.79,1183.44h4.02v-5.87l-4.02,5.87Z"
                        />
                        <path
                            className="cls-12"
                            d="M531.14,1188.19c-1.22,1.67-2.92,2.5-5.1,2.5s-3.87-.83-5.1-2.5-1.85-3.85-1.85-6.55.62-4.87,1.85-6.52c1.22-1.67,2.92-2.5,5.1-2.5s3.87.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM529.29,1181.64c0-1.75-.28-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.82,2.37-.82,4.12s.27,3.12.82,4.15c.58,1,1.38,1.5,2.42,1.5s1.85-.5,2.4-1.5c.57-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            className="cls-12"
                            d="M547.33,1188.19c-1.22,1.67-2.92,2.5-5.1,2.5s-3.88-.83-5.1-2.5-1.85-3.85-1.85-6.55.62-4.87,1.85-6.52c1.22-1.67,2.92-2.5,5.1-2.5s3.87.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM545.48,1181.64c0-1.75-.27-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.83,2.37-.83,4.12s.28,3.12.83,4.15c.58,1,1.37,1.5,2.42,1.5s1.85-.5,2.4-1.5c.58-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            className="cls-12"
                            d="M564.29,1193.27v-2.67c-2.47-.33-4.55-1.62-5.85-3.25l2.55-2.57c.88,1.17,1.97,1.92,3.3,2.3v-3.9c-3.37-.6-5.42-2.38-5.42-5.25,0-1.45.53-2.62,1.55-3.55s2.33-1.5,3.87-1.67v-2.65h2.77v2.72c2,.38,3.67,1.25,5.02,2.65l-2.5,2.62c-.75-.77-1.6-1.35-2.52-1.67v3.55c3.75.67,5.62,2.5,5.62,5.42s-2.15,4.87-5.62,5.27v2.65h-2.77ZM564.29,1179.42v-3.32c-1.07.22-1.72.82-1.72,1.65s.58,1.35,1.72,1.67ZM567.06,1183.67v3.52c1.2-.22,1.92-.92,1.92-1.8,0-.83-.65-1.4-1.92-1.72Z"
                        />
                    </g>
                    <rect
                        fill="#0d349d"
                        x="13.06"
                        y="1225.42"
                        width="580.73"
                        height="61.84"
                        rx="30.92"
                        ry="30.92"
                    />
                    <g>
                        <path
                            fill="#fff"
                            d="M41.19,1259.19l4.1-12.7h3.8l-6,17.52h-3.85l-6-17.52h3.77l4.17,12.7Z"
                        />
                        <path
                            fill="#fff"
                            d="M59.89,1246.49l6.85,17.52h-3.85l-1.4-3.45h-6.77l-1.38,3.45h-3.85l6.85-17.52h3.55ZM58.11,1251.54l-2.17,5.72h4.35l-2.17-5.72Z"
                        />
                        <path fill="#fff" d="M68.71,1264.01v-17.52h3.72v14.1h8v3.42h-11.72Z" />
                        <path
                            fill="#fff"
                            d="M82.84,1264.01v-17.52h12.92v3.42h-9.2v3.47h8.77v3.37h-8.77v3.82h9.2v3.42h-12.92Z"
                        />
                        <path
                            fill="#fff"
                            d="M109.76,1246.49h3.7v9.75c0,5.22-2.75,8.05-7.35,8.05s-7.35-2.83-7.35-8.05v-9.75h3.7v9.92c0,2.67,1.25,4.47,3.65,4.47s3.65-1.5,3.65-4.52v-9.87Z"
                        />
                        <path
                            fill="#fff"
                            d="M126.74,1264.01l-3.45-5.62h-2.97v5.62h-3.72v-17.52h7.25c1.95,0,3.55.5,4.8,1.52,1.27,1.02,1.9,2.42,1.9,4.2,0,2.47-1.35,4.47-3.45,5.47l3.87,6.32h-4.22ZM120.31,1249.92v5.07h2.87c2.42,0,3.65-.85,3.65-2.58s-1.15-2.5-3.65-2.5h-2.87Z"
                        />
                        <path
                            fill="#fff"
                            d="M151.28,1264.01l-7.07-11.82v11.82h-3.72v-17.52h4.47l7.07,11.87v-11.87h3.7v17.52h-4.45Z"
                        />
                        <path
                            fill="#fff"
                            d="M159.16,1264.01v-17.52h12.92v3.42h-9.2v3.47h8.77v3.37h-8.77v3.82h9.2v3.42h-12.92Z"
                        />
                        <path
                            fill="#fff"
                            d="M174.36,1246.49h13.75v3.42h-5.02v14.1h-3.7v-14.1h-5.02v-3.42Z"
                        />
                        <path
                            fill="#fff"
                            d="M189.51,1246.49h13.75v3.42h-5.02v14.1h-3.7v-14.1h-5.02v-3.42Z"
                        />
                        <path
                            fill="#fff"
                            d="M205.66,1264.01v-17.52h12.92v3.42h-9.2v3.47h8.77v3.37h-8.77v3.82h9.2v3.42h-12.92Z"
                        />
                        <path
                            fill="#fff"
                            d="M227.84,1246.49h13.75v3.42h-5.02v14.1h-3.7v-14.1h-5.02v-3.42Z"
                        />
                        <path
                            fill="#fff"
                            d="M245.89,1248.79c1.72-1.72,3.87-2.57,6.4-2.57s4.7.85,6.45,2.57c1.75,1.7,2.62,3.85,2.62,6.45s-.88,4.77-2.62,6.5c-1.75,1.7-3.9,2.55-6.45,2.55s-4.67-.85-6.4-2.55c-1.72-1.72-2.6-3.87-2.6-6.5s.87-4.75,2.6-6.45ZM248.53,1259.19c.95,1.08,2.22,1.6,3.85,1.6s2.85-.52,3.77-1.6c.95-1.07,1.42-2.37,1.42-3.95s-.5-2.83-1.47-3.9c-.97-1.08-2.2-1.62-3.72-1.62-1.6,0-2.88.53-3.85,1.6-.95,1.08-1.42,2.38-1.42,3.92s.48,2.87,1.42,3.95Z"
                        />
                        <path
                            fill="#fff"
                            d="M263.06,1246.49h13.75v3.42h-5.02v14.1h-3.7v-14.1h-5.02v-3.42Z"
                        />
                        <path
                            fill="#fff"
                            d="M288.18,1246.49l6.85,17.52h-3.85l-1.4-3.45h-6.77l-1.37,3.45h-3.85l6.85-17.52h3.55ZM286.41,1251.54l-2.17,5.72h4.35l-2.17-5.72Z"
                        />
                        <path fill="#fff" d="M297.01,1264.01v-17.52h3.72v14.1h8v3.42h-11.72Z" />
                    </g>
                    <g>
                        <path
                            className="cls-12"
                            d="M473.94,1255.58c-1.3,0-2.17.55-2.65,1.67h-3.42l.43-10.77h10.82v3.42h-7.35l-.15,3.22c.8-.53,1.77-.8,2.92-.8,1.62,0,3.02.52,4.2,1.6,1.17,1.05,1.77,2.45,1.77,4.17,0,1.85-.65,3.35-1.95,4.47-1.27,1.12-2.95,1.7-4.97,1.7-2.53,0-4.82-1.1-6.05-2.77l2.65-2.62c1,1.33,2.22,2,3.67,2,1.67,0,2.95-1.05,2.95-2.57s-1.2-2.72-2.87-2.72Z"
                        />
                        <path
                            className="cls-12"
                            d="M484.94,1261.83c-1.25-1.65-1.87-3.9-1.87-6.75,0-2.67.67-4.82,2-6.45,1.35-1.62,3.17-2.42,5.47-2.42,2.12,0,4.2.97,5.42,2.38l-2.4,2.47c-.8-.97-1.85-1.45-3.17-1.45-1.05,0-1.95.4-2.67,1.22-.72.8-1.1,1.85-1.12,3.15.92-1.08,2.2-1.6,3.8-1.6s3.1.55,4.27,1.65c1.2,1.1,1.8,2.52,1.8,4.27s-.62,3.25-1.88,4.35c-1.22,1.07-2.72,1.62-4.47,1.62-2.17,0-3.9-.83-5.17-2.45ZM490.14,1255.68c-1.5,0-2.62,1.08-2.62,2.6s1.12,2.6,2.62,2.6,2.62-1.1,2.62-2.6-1.12-2.6-2.62-2.6Z"
                        />
                        <path
                            className="cls-12"
                            d="M516.94,1260.3h-2.42v3.7h-3.7v-3.7h-7.87v-3.05l7.5-10.77h4.07v10.55h2.42v3.27ZM506.79,1257.03h4.02v-5.87l-4.02,5.87Z"
                        />
                        <path
                            className="cls-12"
                            d="M531.14,1261.78c-1.22,1.67-2.92,2.5-5.1,2.5s-3.88-.83-5.1-2.5-1.85-3.85-1.85-6.55.62-4.87,1.85-6.52c1.22-1.67,2.92-2.5,5.1-2.5s3.87.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM529.29,1255.23c0-1.75-.27-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.83,2.37-.83,4.12s.28,3.12.83,4.15c.58,1,1.37,1.5,2.42,1.5s1.85-.5,2.4-1.5c.58-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            className="cls-12"
                            d="M547.34,1261.78c-1.22,1.67-2.92,2.5-5.1,2.5s-3.87-.83-5.1-2.5c-1.22-1.67-1.85-3.85-1.85-6.55s.62-4.87,1.85-6.52c1.23-1.67,2.92-2.5,5.1-2.5s3.87.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM545.49,1255.23c0-1.75-.27-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.83,2.37-.83,4.12s.28,3.12.83,4.15c.57,1,1.37,1.5,2.42,1.5s1.85-.5,2.4-1.5c.58-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            className="cls-12"
                            d="M564.29,1266.85v-2.67c-2.47-.33-4.55-1.62-5.85-3.25l2.55-2.57c.88,1.17,1.97,1.92,3.3,2.3v-3.9c-3.37-.6-5.42-2.38-5.42-5.25,0-1.45.52-2.62,1.55-3.55,1.02-.92,2.32-1.5,3.87-1.67v-2.65h2.78v2.72c2,.38,3.67,1.25,5.02,2.65l-2.5,2.62c-.75-.77-1.6-1.35-2.52-1.67v3.55c3.75.67,5.62,2.5,5.62,5.42s-2.15,4.87-5.62,5.27v2.65h-2.78ZM564.29,1253.01v-3.32c-1.07.22-1.72.82-1.72,1.65s.58,1.35,1.72,1.67ZM567.06,1257.25v3.52c1.2-.22,1.92-.92,1.92-1.8,0-.83-.65-1.4-1.92-1.72Z"
                        />
                    </g>
                    <line
                        stroke="#0d349d"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        x1="26.96"
                        y1="964.78"
                        x2="138.99"
                        y2="964.78"
                    />
                    <g>
                        <path
                            fill="#0d349d"
                            d="M46.49,948.26c0,1.67-.62,2.97-1.88,3.9-1.25.92-2.82,1.4-4.72,1.4-2.47,0-4.85-1.12-6.22-2.8l2.5-2.52c1.03,1.27,2.3,1.92,3.87,1.92s2.75-.72,2.75-2c0-1.37-1.1-2.05-3.32-2.05h-1.45v-3.45h1.17c2.1,0,3.15-.65,3.15-1.97,0-1.03-.9-1.8-2.3-1.8s-2.62.58-3.52,1.7l-2.42-2.42c1.57-1.77,3.52-2.67,5.85-2.67,1.7,0,3.15.4,4.32,1.22,1.2.83,1.8,2.02,1.8,3.62s-.65,2.92-1.95,3.8c1.33.67,2.37,2.15,2.37,4.12Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M64.11,951.06c-1.22,1.67-2.92,2.5-5.1,2.5s-3.87-.83-5.1-2.5-1.85-3.85-1.85-6.55.62-4.87,1.85-6.52c1.22-1.67,2.92-2.5,5.1-2.5s3.87.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM62.26,944.51c0-1.75-.28-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.82,2.37-.82,4.12s.27,3.12.82,4.15c.58,1,1.38,1.5,2.42,1.5s1.85-.5,2.4-1.5c.57-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M80.31,951.06c-1.22,1.67-2.92,2.5-5.1,2.5s-3.88-.83-5.1-2.5-1.85-3.85-1.85-6.55.62-4.87,1.85-6.52c1.22-1.67,2.92-2.5,5.1-2.5s3.87.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM78.46,944.51c0-1.75-.27-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.83,2.37-.83,4.12s.28,3.12.83,4.15c.58,1,1.37,1.5,2.42,1.5s1.85-.5,2.4-1.5c.58-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M96.51,951.06c-1.22,1.67-2.92,2.5-5.1,2.5s-3.87-.83-5.1-2.5c-1.22-1.67-1.85-3.85-1.85-6.55s.62-4.87,1.85-6.52c1.23-1.67,2.92-2.5,5.1-2.5s3.88.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM94.66,944.51c0-1.75-.28-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.83,2.37-.83,4.12s.27,3.12.83,4.15c.57,1,1.38,1.5,2.42,1.5s1.85-.5,2.4-1.5c.57-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M108.49,956.13v-2.67c-2.47-.33-4.55-1.62-5.85-3.25l2.55-2.57c.87,1.17,1.97,1.92,3.3,2.3v-3.9c-3.37-.6-5.42-2.38-5.42-5.25,0-1.45.52-2.62,1.55-3.55,1.03-.92,2.32-1.5,3.87-1.67v-2.65h2.77v2.72c2,.38,3.67,1.25,5.02,2.65l-2.5,2.62c-.75-.77-1.6-1.35-2.52-1.67v3.55c3.75.67,5.62,2.5,5.62,5.42s-2.15,4.87-5.62,5.27v2.65h-2.77ZM108.49,942.28v-3.32c-1.08.22-1.72.82-1.72,1.65s.58,1.35,1.72,1.67ZM111.26,946.53v3.52c1.2-.22,1.92-.92,1.92-1.8,0-.83-.65-1.4-1.92-1.72Z"
                        />
                    </g>
                    <g>
                        <path
                            fill="#0d349d"
                            d="M39.49,779.51l3.08-9.47h2.09l-4.06,11.92h-2.23l-4.1-11.92h2.09l3.13,9.47Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M46.74,781.46c-.53-.46-.8-1.09-.8-1.85,0-1.82,1.43-2.79,3.47-2.79.54,0,1.11.1,1.68.29v-.56c0-.83-.7-1.38-1.63-1.38-.87,0-1.53.41-1.97,1.21l-1.31-1.22c.63-1.14,1.94-1.73,3.3-1.73,2.16,0,3.55,1.09,3.55,3.14v5.39h-1.9v-.65c-.63.56-1.43.83-2.38.83-.8,0-1.46-.22-2-.68ZM49.13,780.44c.8,0,1.46-.24,1.96-.71v-.95c-.6-.22-1.17-.34-1.73-.34-.9,0-1.48.37-1.48,1.04,0,.56.44.97,1.26.97Z"
                        />
                        <path fill="#0d349d" d="M55.08,781.95v-11.92h1.94v11.92h-1.94Z" />
                        <path
                            fill="#0d349d"
                            d="M66.66,778.37h-5.69c.08,1.24.95,2.02,2.19,2.02.9,0,1.62-.39,2.14-1.17l1.31,1.22c-.88,1.14-2.04,1.7-3.45,1.7-1.24,0-2.26-.39-3.03-1.19s-1.16-1.84-1.16-3.11.36-2.29,1.05-3.14c.71-.85,1.67-1.28,2.84-1.28,2.7,0,3.81,2.16,3.81,4.27l-.02.68ZM61.07,776.82h3.55c-.02-.87-.56-1.63-1.73-1.63-1.02,0-1.63.73-1.82,1.63Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M70.37,773.62v4.98c0,1.1.56,1.8,1.56,1.8,1.09,0,1.73-.85,1.73-2.09v-4.69h1.94v8.33h-1.9v-.68c-.53.58-1.26.87-2.16.87-1.89,0-3.11-1.36-3.11-3.67v-4.84h1.94Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M82.96,775.36c-.08-.02-.19-.02-.31-.02-1.9,0-2.57,1.02-2.57,3.35v3.26h-1.94v-8.33h1.9v.9c.56-.68,1.41-1.02,2.53-1.02.12,0,.24,0,.37.02v1.84Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M94.66,774.24v-4.2h1.94v11.92h-1.9v-.75c-.59.63-1.39.93-2.36.93-1.19,0-2.16-.41-2.92-1.22-.76-.83-1.14-1.87-1.14-3.13s.37-2.29,1.14-3.13c.77-.83,1.73-1.24,2.92-1.24.92,0,1.7.27,2.33.82ZM90.24,777.79c0,1.5.82,2.65,2.23,2.65s2.23-1.16,2.23-2.65-.8-2.65-2.23-2.65-2.23,1.16-2.23,2.65Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M106.22,778.37h-5.69c.08,1.24.95,2.02,2.19,2.02.9,0,1.62-.39,2.14-1.17l1.31,1.22c-.88,1.14-2.04,1.7-3.45,1.7-1.24,0-2.26-.39-3.03-1.19s-1.16-1.84-1.16-3.11.36-2.29,1.05-3.14c.71-.85,1.67-1.28,2.84-1.28,2.7,0,3.81,2.16,3.81,4.27l-.02.68ZM100.63,776.82h3.55c-.02-.87-.56-1.63-1.73-1.63-1.02,0-1.63.73-1.82,1.63Z"
                        />
                        <path fill="#0d349d" d="M35.01,802.35v-11.92h1.94v11.92h-1.94Z" />
                        <path
                            fill="#0d349d"
                            d="M39.72,801.86c-.53-.46-.8-1.09-.8-1.85,0-1.82,1.43-2.79,3.47-2.79.54,0,1.1.1,1.68.29v-.56c0-.83-.7-1.38-1.63-1.38-.87,0-1.53.41-1.97,1.21l-1.31-1.22c.63-1.14,1.94-1.73,3.3-1.73,2.16,0,3.55,1.09,3.55,3.14v5.39h-1.9v-.65c-.63.56-1.43.83-2.38.83-.8,0-1.46-.22-2.01-.68ZM42.11,800.84c.8,0,1.46-.24,1.96-.71v-.95c-.59-.22-1.17-.34-1.73-.34-.9,0-1.48.37-1.48,1.04,0,.56.44.97,1.26.97Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M59.15,794.02l-2.96,8.33h-2.11l-2.96-8.33h2.09l1.92,5.83,1.92-5.83h2.09Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M67.26,795.08c.83.83,1.26,1.87,1.26,3.11s-.42,2.28-1.26,3.11-1.84,1.24-3.04,1.24-2.23-.41-3.06-1.24-1.24-1.87-1.24-3.11.41-2.28,1.24-3.11,1.85-1.26,3.06-1.26,2.21.42,3.04,1.26ZM65.86,800.06c.44-.49.66-1.1.66-1.87s-.22-1.38-.66-1.87c-.42-.49-.99-.73-1.65-.73-1.36,0-2.33,1.09-2.33,2.6s.97,2.6,2.33,2.6c.66,0,1.22-.24,1.65-.73Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M70.57,790.64c.48-.48,1.26-.48,1.73,0,.48.46.48,1.24,0,1.72-.48.48-1.26.48-1.73,0-.48-.48-.48-1.26,0-1.72ZM70.45,802.35v-8.33h1.94v8.33h-1.94Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M75.01,799.41v-3.66h-1.22v-1.73h1.22v-2.33h1.92v2.33h1.99v1.73h-1.99v3.37c0,1.21.27,1.51,1.36,1.51l.63-.03v1.73c-.25.02-.59.03-1.04.03-2.16,0-2.87-.8-2.87-2.96Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M82.42,794.02v4.98c0,1.1.56,1.8,1.56,1.8,1.09,0,1.73-.85,1.73-2.09v-4.69h1.94v8.33h-1.9v-.68c-.53.58-1.26.87-2.16.87-1.89,0-3.11-1.36-3.11-3.67v-4.84h1.94Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M95.02,795.76c-.08-.02-.19-.02-.31-.02-1.9,0-2.57,1.02-2.57,3.35v3.26h-1.94v-8.33h1.9v.9c.56-.68,1.41-1.02,2.53-1.02.12,0,.24,0,.37.02v1.83Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M103.75,798.77h-5.69c.08,1.24.95,2.02,2.19,2.02.9,0,1.62-.39,2.14-1.17l1.31,1.22c-.88,1.14-2.04,1.7-3.45,1.7-1.24,0-2.26-.39-3.03-1.19s-1.16-1.84-1.16-3.11.36-2.29,1.05-3.14c.71-.85,1.67-1.28,2.84-1.28,2.7,0,3.81,2.16,3.81,4.27l-.02.68ZM98.16,797.22h3.55c-.02-.87-.56-1.63-1.73-1.63-1.02,0-1.63.73-1.82,1.63Z"
                        />
                    </g>
                    <line
                        stroke="#0d349d"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        x1="26.96"
                        y1="847.07"
                        x2="161.73"
                        y2="847.07"
                    />
                    <g>
                        <path
                            fill="#0d349d"
                            d="M33.94,824.12v-6.07h12.8v3.22c-3.47,3.62-5.45,8.05-5.9,13.3l-.07,1h-3.78l.1-.97c.42-5.1,2.15-9.47,5.15-13.12h-4.7v2.65h-3.6Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M63.66,833.34c-1.22,1.67-2.92,2.5-5.1,2.5s-3.87-.83-5.1-2.5c-1.22-1.67-1.85-3.85-1.85-6.55s.62-4.87,1.85-6.52c1.23-1.67,2.92-2.5,5.1-2.5s3.88.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM61.81,826.79c0-1.75-.28-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.83,2.37-.83,4.12s.27,3.12.83,4.15c.57,1,1.38,1.5,2.42,1.5s1.85-.5,2.4-1.5c.57-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M79.86,833.34c-1.22,1.67-2.92,2.5-5.1,2.5s-3.87-.83-5.1-2.5-1.85-3.85-1.85-6.55.62-4.87,1.85-6.52c1.22-1.67,2.92-2.5,5.1-2.5s3.87.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM78.01,826.79c0-1.75-.28-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.82,2.37-.82,4.12s.27,3.12.82,4.15c.58,1,1.38,1.5,2.42,1.5s1.85-.5,2.4-1.5c.57-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M96.06,833.34c-1.22,1.67-2.92,2.5-5.1,2.5s-3.87-.83-5.1-2.5c-1.23-1.67-1.85-3.85-1.85-6.55s.62-4.87,1.85-6.52c1.22-1.67,2.92-2.5,5.1-2.5s3.87.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM94.21,826.79c0-1.75-.27-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.83,2.37-.83,4.12s.28,3.12.83,4.15c.57,1,1.37,1.5,2.42,1.5s1.85-.5,2.4-1.5c.58-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M108.04,838.42v-2.67c-2.47-.33-4.55-1.62-5.85-3.25l2.55-2.57c.88,1.17,1.97,1.92,3.3,2.3v-3.9c-3.37-.6-5.42-2.38-5.42-5.25,0-1.45.52-2.62,1.55-3.55,1.02-.92,2.32-1.5,3.87-1.67v-2.65h2.78v2.72c2,.38,3.67,1.25,5.02,2.65l-2.5,2.62c-.75-.77-1.6-1.35-2.52-1.67v3.55c3.75.67,5.62,2.5,5.62,5.42s-2.15,4.87-5.62,5.27v2.65h-2.78ZM108.04,824.57v-3.32c-1.07.22-1.72.82-1.72,1.65s.58,1.35,1.72,1.67ZM110.81,828.82v3.52c1.2-.22,1.92-.92,1.92-1.8,0-.83-.65-1.4-1.92-1.72Z"
                        />
                    </g>
                    <g>
                        <path
                            fill="#0d349d"
                            d="M45.5,989.68c-1.19,1.5-2.77,2.24-4.74,2.24-1.8,0-3.3-.56-4.47-1.7s-1.75-2.62-1.75-4.42.58-3.31,1.73-4.45c1.17-1.14,2.63-1.72,4.42-1.72,1.99,0,3.54.75,4.64,2.24l-1.31,1.36c-.82-1.17-1.92-1.75-3.3-1.75-1.17,0-2.16.41-2.94,1.22-.78.82-1.17,1.85-1.17,3.09s.39,2.24,1.19,3.06c.8.8,1.79,1.21,2.99,1.21,1.39,0,2.53-.59,3.43-1.78l1.27,1.39Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M54.1,984.46c.83.83,1.26,1.87,1.26,3.11s-.42,2.28-1.26,3.11-1.84,1.24-3.04,1.24-2.23-.41-3.06-1.24-1.24-1.87-1.24-3.11.41-2.28,1.24-3.11,1.85-1.26,3.06-1.26,2.21.42,3.04,1.26ZM52.7,989.44c.44-.49.66-1.1.66-1.87s-.22-1.38-.66-1.87c-.42-.49-.99-.73-1.65-.73-1.36,0-2.33,1.09-2.33,2.6s.97,2.6,2.33,2.6c.66,0,1.22-.24,1.65-.73Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M62.5,991.74v-4.98c0-1.1-.56-1.8-1.53-1.8-1.11,0-1.72.8-1.72,2.04v4.74h-1.94v-8.33h1.9v.73c.53-.63,1.26-.93,2.16-.93,1.04,0,1.8.36,2.33,1.09.65-.73,1.51-1.09,2.6-1.09,2.19,0,3.32,1.29,3.32,3.69v4.84h-1.94v-4.98c0-1.1-.56-1.8-1.53-1.8-1.11,0-1.73.8-1.73,2.04v4.74h-1.92Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M73.7,991.13v3.91h-1.94v-11.63h1.9v.75c.59-.63,1.39-.95,2.36-.95,1.19,0,2.16.41,2.92,1.24s1.14,1.87,1.14,3.13-.37,2.29-1.14,3.13c-.76.82-1.73,1.22-2.92,1.22-.9,0-1.68-.27-2.33-.8ZM77.52,989.46c.39-.51.59-1.14.59-1.87s-.2-1.36-.59-1.87c-.39-.51-.94-.76-1.63-.76-1.43,0-2.23,1.14-2.23,2.63s.8,2.63,2.23,2.63c.7,0,1.24-.25,1.63-.76Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M82.35,988.8v-3.66h-1.22v-1.73h1.22v-2.33h1.92v2.33h1.99v1.73h-1.99v3.37c0,1.21.27,1.51,1.36,1.51l.63-.03v1.73c-.25.02-.59.03-1.04.03-2.16,0-2.87-.8-2.87-2.96Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M95.31,988.15h-5.69c.08,1.24.95,2.02,2.19,2.02.9,0,1.62-.39,2.14-1.17l1.31,1.22c-.88,1.14-2.04,1.7-3.45,1.7-1.24,0-2.26-.39-3.03-1.19s-1.16-1.84-1.16-3.11.36-2.29,1.05-3.14c.71-.85,1.67-1.28,2.84-1.28,2.7,0,3.81,2.16,3.81,4.27l-.02.68ZM89.71,986.6h3.55c-.02-.87-.56-1.63-1.73-1.63-1.02,0-1.63.73-1.82,1.63Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M37.03,1007.97c0,1.51.97,2.6,2.28,2.6.97,0,1.51-.41,2.14-1.29l1.33,1.24c-.83,1.21-2.01,1.8-3.54,1.8-1.16,0-2.14-.41-2.96-1.24-.82-.83-1.22-1.87-1.22-3.11s.41-2.28,1.22-3.11c.82-.83,1.8-1.26,2.96-1.26,1.39,0,2.52.56,3.38,1.67l-1.34,1.29c-.6-.8-1.26-1.19-1.99-1.19-1.31,0-2.26,1.09-2.26,2.6Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M46.38,1012.14h-1.94v-11.92h1.94v4.17c.56-.53,1.26-.78,2.07-.78,1.99,0,3.35,1.38,3.35,3.69v4.84h-1.94v-4.84c0-1.17-.61-1.94-1.65-1.94-1.17,0-1.84.83-1.84,2.18v4.61Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M61.43,1008.55h-5.69c.08,1.24.95,2.02,2.19,2.02.9,0,1.62-.39,2.14-1.17l1.31,1.22c-.88,1.14-2.04,1.7-3.45,1.7-1.24,0-2.26-.39-3.03-1.19s-1.16-1.84-1.16-3.11.36-2.29,1.05-3.14c.71-.85,1.67-1.28,2.84-1.28,2.7,0,3.81,2.16,3.81,4.27l-.02.68ZM55.2,1000.22h2.38l1.55,2.23h-1.9l-2.02-2.23ZM55.83,1007h3.55c-.02-.87-.56-1.63-1.73-1.63-1.02,0-1.63.73-1.82,1.63Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M69.5,1004.56v-.75h1.9v11.63h-1.94v-3.91c-.65.53-1.43.8-2.33.8-1.19,0-2.16-.41-2.92-1.22-.77-.83-1.14-1.87-1.14-3.13s.37-2.29,1.14-3.13c.76-.83,1.73-1.24,2.92-1.24.99,0,1.77.32,2.36.95ZM65.62,1006.12c-.39.51-.58,1.14-.58,1.87s.19,1.36.58,1.87c.39.51.95.76,1.65.76,1.43,0,2.23-1.14,2.23-2.63s-.8-2.63-2.23-2.63c-.7,0-1.26.25-1.65.76Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M75.48,1003.81v4.98c0,1.1.56,1.8,1.56,1.8,1.09,0,1.73-.85,1.73-2.09v-4.69h1.94v8.33h-1.9v-.68c-.53.58-1.26.87-2.16.87-1.89,0-3.11-1.36-3.11-3.67v-4.84h1.94Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M90.36,1008.55h-5.69c.08,1.24.95,2.02,2.19,2.02.9,0,1.62-.39,2.14-1.17l1.31,1.22c-.88,1.14-2.04,1.7-3.45,1.7-1.24,0-2.26-.39-3.03-1.19s-1.16-1.84-1.16-3.11.36-2.29,1.05-3.14c.71-.85,1.67-1.28,2.84-1.28,2.7,0,3.81,2.16,3.81,4.27l-.02.68ZM84.77,1007h3.55c-.02-.87-.56-1.63-1.73-1.63-1.02,0-1.63.73-1.82,1.63Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M94.61,1008.72c-1.68-.39-2.53-1.26-2.53-2.63,0-1.51,1.26-2.48,3.04-2.48,1.21,0,2.24.46,3.14,1.39l-1.24,1.33c-.51-.7-1.16-1.05-1.96-1.05-.7,0-1.14.36-1.14.78,0,.39.27.65.82.78l1.31.31c1.7.39,2.55,1.26,2.55,2.6,0,1.51-1.19,2.58-3.3,2.58-1.44,0-2.6-.54-3.45-1.61l1.22-1.26c.61.8,1.41,1.21,2.38,1.21.9,0,1.27-.37,1.27-.85,0-.36-.25-.61-1.02-.82l-1.11-.27Z"
                        />
                    </g>
                    <line
                        stroke="#0d349d"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        x1="26.96"
                        y1="1053.51"
                        x2="172.15"
                        y2="1053.51"
                    />
                    <g>
                        <path
                            fill="#0d349d"
                            d="M49.15,1038.31h-2.42v3.7h-3.7v-3.7h-7.87v-3.05l7.5-10.77h4.07v10.55h2.42v3.27ZM39,1035.03h4.02v-5.87l-4.02,5.87Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M63.35,1039.78c-1.22,1.67-2.92,2.5-5.1,2.5s-3.88-.83-5.1-2.5-1.85-3.85-1.85-6.55.62-4.87,1.85-6.52c1.22-1.67,2.92-2.5,5.1-2.5s3.87.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM61.5,1033.23c0-1.75-.27-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.83,2.37-.83,4.12s.28,3.12.83,4.15c.58,1,1.37,1.5,2.42,1.5s1.85-.5,2.4-1.5c.58-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M79.55,1039.78c-1.22,1.67-2.92,2.5-5.1,2.5s-3.87-.83-5.1-2.5c-1.22-1.67-1.85-3.85-1.85-6.55s.62-4.87,1.85-6.52c1.23-1.67,2.92-2.5,5.1-2.5s3.87.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM77.7,1033.23c0-1.75-.27-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.83,2.37-.83,4.12s.28,3.12.83,4.15c.57,1,1.37,1.5,2.42,1.5s1.85-.5,2.4-1.5c.58-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M91.53,1044.85v-2.67c-2.47-.33-4.55-1.62-5.85-3.25l2.55-2.57c.87,1.17,1.97,1.92,3.3,2.3v-3.9c-3.37-.6-5.42-2.38-5.42-5.25,0-1.45.52-2.62,1.55-3.55,1.03-.92,2.32-1.5,3.87-1.67v-2.65h2.77v2.72c2,.38,3.67,1.25,5.02,2.65l-2.5,2.62c-.75-.77-1.6-1.35-2.52-1.67v3.55c3.75.67,5.62,2.5,5.62,5.42s-2.15,4.87-5.62,5.27v2.65h-2.77ZM91.53,1031.01v-3.32c-1.08.22-1.72.82-1.72,1.65s.58,1.35,1.72,1.67ZM94.3,1035.26v3.52c1.2-.22,1.92-.92,1.92-1.8,0-.83-.65-1.4-1.92-1.72Z"
                        />
                    </g>
                    <g>
                        <path
                            fill="#0d349d"
                            d="M538.9,835.7c-1.19,1.5-2.77,2.24-4.74,2.24-1.8,0-3.3-.56-4.47-1.7s-1.75-2.62-1.75-4.42.58-3.31,1.73-4.45c1.17-1.14,2.63-1.72,4.42-1.72,1.99,0,3.54.75,4.64,2.24l-1.31,1.36c-.82-1.17-1.92-1.75-3.3-1.75-1.17,0-2.16.41-2.94,1.22-.78.82-1.17,1.85-1.17,3.09s.39,2.24,1.19,3.06c.8.8,1.78,1.21,2.99,1.21,1.39,0,2.53-.59,3.43-1.78l1.28,1.39Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M547.5,830.48c.83.83,1.26,1.87,1.26,3.11s-.42,2.28-1.26,3.11-1.84,1.24-3.04,1.24-2.23-.41-3.06-1.24-1.24-1.87-1.24-3.11.41-2.28,1.24-3.11,1.85-1.26,3.06-1.26,2.21.42,3.04,1.26ZM546.11,835.46c.44-.49.66-1.1.66-1.87s-.22-1.38-.66-1.87c-.42-.49-.99-.73-1.65-.73-1.36,0-2.33,1.09-2.33,2.6s.97,2.6,2.33,2.6c.66,0,1.22-.24,1.65-.73Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M555.9,837.76v-4.98c0-1.1-.56-1.8-1.53-1.8-1.11,0-1.72.8-1.72,2.04v4.74h-1.94v-8.33h1.9v.73c.53-.63,1.26-.93,2.16-.93,1.04,0,1.8.36,2.33,1.09.65-.73,1.51-1.09,2.6-1.09,2.19,0,3.32,1.29,3.32,3.69v4.84h-1.94v-4.98c0-1.1-.56-1.8-1.53-1.8-1.11,0-1.73.8-1.73,2.04v4.74h-1.92Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M567.1,837.15v3.91h-1.94v-11.63h1.9v.75c.59-.63,1.39-.95,2.36-.95,1.19,0,2.16.41,2.92,1.24s1.14,1.87,1.14,3.13-.37,2.29-1.14,3.13c-.76.82-1.73,1.22-2.92,1.22-.9,0-1.68-.27-2.33-.8ZM570.93,835.48c.39-.51.59-1.14.59-1.87s-.2-1.36-.59-1.87c-.39-.51-.94-.76-1.63-.76-1.43,0-2.23,1.14-2.23,2.63s.8,2.63,2.23,2.63c.7,0,1.24-.25,1.63-.76Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M575.76,834.82v-3.66h-1.22v-1.73h1.22v-2.33h1.92v2.33h1.99v1.73h-1.99v3.37c0,1.21.27,1.51,1.36,1.51l.63-.03v1.73c-.25.02-.59.03-1.04.03-2.16,0-2.87-.8-2.87-2.96Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M588.71,834.17h-5.69c.08,1.24.95,2.02,2.19,2.02.9,0,1.62-.39,2.14-1.17l1.31,1.22c-.88,1.14-2.04,1.7-3.45,1.7-1.24,0-2.26-.39-3.03-1.19s-1.16-1.84-1.16-3.11.36-2.29,1.05-3.14c.71-.85,1.67-1.28,2.84-1.28,2.7,0,3.81,2.16,3.81,4.27l-.02.68ZM583.12,832.62h3.55c-.02-.87-.56-1.63-1.73-1.63-1.02,0-1.63.73-1.82,1.63Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M530.82,856.59c.9,0,1.62-.39,2.14-1.17l1.31,1.22c-.88,1.14-2.04,1.7-3.45,1.7-1.24,0-2.26-.39-3.03-1.19s-1.16-1.84-1.16-3.11.36-2.29,1.05-3.14c.71-.85,1.67-1.28,2.84-1.28,2.7,0,3.81,2.16,3.81,4.27,0,.19,0,.42-.02.68h-5.69c.08,1.24.95,2.02,2.19,2.02ZM528.73,853.02h3.55c-.02-.87-.56-1.63-1.73-1.63-1.02,0-1.63.73-1.82,1.63ZM530.67,846.24h2.38l-2.02,2.23h-1.9l1.55-2.23Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M538.24,857.54v3.91h-1.94v-11.63h1.9v.75c.59-.63,1.39-.95,2.36-.95,1.19,0,2.16.41,2.92,1.24s1.14,1.87,1.14,3.13-.37,2.29-1.14,3.13c-.76.82-1.73,1.22-2.92,1.22-.9,0-1.68-.27-2.33-.8ZM542.06,855.88c.39-.51.59-1.14.59-1.87s-.2-1.36-.59-1.87c-.39-.51-.94-.76-1.63-.76-1.43,0-2.23,1.14-2.23,2.63s.8,2.63,2.23,2.63c.7,0,1.24-.25,1.63-.76Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M547.06,857.66c-.53-.46-.8-1.09-.8-1.85,0-1.82,1.43-2.79,3.47-2.79.54,0,1.1.1,1.68.29v-.56c0-.83-.7-1.38-1.63-1.38-.87,0-1.53.41-1.97,1.21l-1.31-1.22c.63-1.14,1.94-1.73,3.3-1.73,2.16,0,3.55,1.09,3.55,3.14v5.39h-1.9v-.65c-.63.56-1.43.83-2.38.83-.8,0-1.46-.22-2.01-.68ZM549.46,856.64c.8,0,1.46-.24,1.96-.71v-.95c-.59-.22-1.17-.34-1.73-.34-.9,0-1.48.37-1.48,1.04,0,.56.44.97,1.26.97Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M560.39,851.56c-.08-.02-.19-.02-.31-.02-1.9,0-2.57,1.02-2.57,3.35v3.26h-1.94v-8.33h1.9v.9c.56-.68,1.41-1.02,2.53-1.02.12,0,.24,0,.37.02v1.83Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M563.21,858.46c.53.97,1.31,1.45,2.31,1.45,1.34,0,2.21-.82,2.21-2.33v-.42c-.6.54-1.33.82-2.23.82-1.17,0-2.12-.39-2.87-1.17-.73-.8-1.11-1.78-1.11-2.99s.37-2.21,1.12-2.99c.75-.8,1.7-1.19,2.86-1.19.92,0,1.67.29,2.26.88v-.68h1.9v7.65c0,1.26-.37,2.26-1.12,3.03s-1.75,1.14-2.99,1.14c-.94,0-1.72-.2-2.33-.59s-1.09-.8-1.41-1.26l1.39-1.33ZM565.62,856.2c1.29,0,2.14-.99,2.14-2.4s-.85-2.41-2.14-2.41-2.12.99-2.12,2.41.83,2.4,2.12,2.4Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M573.87,858.16h-1.94v-8.33h1.9v.68c.53-.59,1.24-.88,2.16-.88,2.06,0,3.3,1.36,3.3,3.69v4.84h-1.94v-4.84c0-1.17-.61-1.94-1.65-1.94-1.17,0-1.84.83-1.84,2.18v4.61Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M588.71,854.57h-5.69c.08,1.24.95,2.02,2.19,2.02.9,0,1.62-.39,2.14-1.17l1.31,1.22c-.88,1.14-2.04,1.7-3.45,1.7-1.24,0-2.26-.39-3.03-1.19s-1.16-1.84-1.16-3.11.36-2.29,1.05-3.14c.71-.85,1.67-1.28,2.84-1.28,2.7,0,3.81,2.16,3.81,4.27l-.02.68ZM583.12,853.02h3.55c-.02-.87-.56-1.63-1.73-1.63-1.02,0-1.63.73-1.82,1.63Z"
                        />
                    </g>
                    <line
                        stroke="#0d349d"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        x1="463.17"
                        y1="902.96"
                        x2="592.56"
                        y2="902.96"
                    />
                    <g>
                        <path
                            fill="#0d349d"
                            d="M522.36,888.04v3.42h-12.52v-.58c0-2.12.42-3.9,1.27-5.27.85-1.4,2.33-2.62,4.45-3.7l.97-.47c1.72-.88,2.17-1.62,2.17-2.52,0-1.08-.92-1.85-2.42-1.85-1.77,0-3.12.92-4.05,2.8l-2.4-2.4c.52-1.12,1.38-2.02,2.5-2.72,1.15-.72,2.4-1.08,3.77-1.08,1.72,0,3.22.45,4.45,1.38,1.25.92,1.87,2.2,1.87,3.82,0,2.57-1.45,4.15-4.17,5.55l-1.52.77c-1.62.83-2.42,1.55-2.65,2.85h8.27Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M539.71,889.24c-1.22,1.67-2.92,2.5-5.1,2.5s-3.87-.83-5.1-2.5c-1.22-1.67-1.85-3.85-1.85-6.55s.62-4.87,1.85-6.52c1.23-1.67,2.92-2.5,5.1-2.5s3.87.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM537.86,882.69c0-1.75-.28-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.83,2.37-.83,4.12s.28,3.12.83,4.15c.58,1,1.38,1.5,2.42,1.5s1.85-.5,2.4-1.5c.57-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M555.91,889.24c-1.22,1.67-2.92,2.5-5.1,2.5s-3.87-.83-5.1-2.5c-1.22-1.67-1.85-3.85-1.85-6.55s.62-4.87,1.85-6.52c1.23-1.67,2.92-2.5,5.1-2.5s3.87.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM554.06,882.69c0-1.75-.28-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.83,2.37-.83,4.12s.28,3.12.83,4.15c.58,1,1.38,1.5,2.42,1.5s1.85-.5,2.4-1.5c.57-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M572.11,889.24c-1.22,1.67-2.92,2.5-5.1,2.5s-3.87-.83-5.1-2.5-1.85-3.85-1.85-6.55.62-4.87,1.85-6.52c1.22-1.67,2.92-2.5,5.1-2.5s3.87.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM570.26,882.69c0-1.75-.27-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.83,2.37-.83,4.12s.27,3.12.83,4.15c.57,1,1.37,1.5,2.42,1.5s1.85-.5,2.4-1.5c.58-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M584.08,894.31v-2.67c-2.47-.33-4.55-1.62-5.85-3.25l2.55-2.57c.87,1.17,1.97,1.92,3.3,2.3v-3.9c-3.37-.6-5.42-2.38-5.42-5.25,0-1.45.53-2.62,1.55-3.55s2.32-1.5,3.87-1.67v-2.65h2.77v2.72c2,.38,3.67,1.25,5.02,2.65l-2.5,2.62c-.75-.77-1.6-1.35-2.52-1.67v3.55c3.75.67,5.62,2.5,5.62,5.42s-2.15,4.87-5.62,5.27v2.65h-2.77ZM584.08,880.46v-3.32c-1.08.22-1.72.82-1.72,1.65s.58,1.35,1.72,1.67ZM586.86,884.71v3.52c1.2-.22,1.92-.92,1.92-1.8,0-.83-.65-1.4-1.92-1.72Z"
                        />
                    </g>
                    <g>
                        <path
                            fill="#0d349d"
                            d="M530.06,986.59c-1.19,1.5-2.77,2.24-4.74,2.24-1.8,0-3.3-.56-4.47-1.7s-1.75-2.62-1.75-4.42.58-3.31,1.73-4.45c1.17-1.14,2.63-1.72,4.42-1.72,1.99,0,3.54.75,4.64,2.24l-1.31,1.36c-.82-1.17-1.92-1.75-3.3-1.75-1.17,0-2.16.41-2.94,1.22-.78.82-1.17,1.85-1.17,3.09s.39,2.24,1.19,3.06c.8.8,1.78,1.21,2.99,1.21,1.39,0,2.53-.59,3.43-1.78l1.28,1.39Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M531.91,988.64v-11.92h8.38v1.84h-6.36v3.08h6.07v1.85h-6.07v3.31h6.36v1.84h-8.38Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M542.83,988.64v-11.92h2.02v10.08h5.64v1.84h-7.67Z"
                        />
                        <path fill="#0d349d" d="M552.53,988.64v-11.92h2.02v11.92h-2.02Z" />
                        <path
                            fill="#0d349d"
                            d="M562.8,976.73l4.64,11.92h-2.12l-1-2.53h-5.08l-.99,2.53h-2.14l4.66-11.92h2.04ZM561.76,979.48l-1.8,4.76h3.62l-1.82-4.76Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M571.03,984.58v4.06h-2.02v-11.92h4.54c2.8,0,4.54,1.45,4.54,3.89s-1.75,3.96-4.54,3.96h-2.52ZM571.03,978.56v4.17h2.26c1.85,0,2.77-.7,2.77-2.09s-.92-2.07-2.77-2.07h-2.26Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M581.96,984.58v4.06h-2.02v-11.92h4.54c2.8,0,4.54,1.45,4.54,3.89s-1.75,3.96-4.54,3.96h-2.52ZM581.96,978.56v4.17h2.26c1.85,0,2.77-.7,2.77-2.09s-.92-2.07-2.77-2.07h-2.26Z"
                        />
                    </g>
                    <line
                        stroke="#0d349d"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        x1="447.02"
                        y1="1032.7"
                        x2="592.56"
                        y2="1032.7"
                    />
                    <g>
                        <path
                            fill="#0d349d"
                            d="M512.08,1012.18c-1.17-.97-1.77-2.12-1.77-3.47,0-1.57.55-2.85,1.67-3.82s2.55-1.47,4.25-1.47,3.1.5,4.22,1.47c1.12.97,1.7,2.25,1.7,3.82,0,1.35-.58,2.5-1.75,3.47,1.22.72,2.17,2.17,2.17,4,0,1.62-.6,2.9-1.8,3.87-1.17.95-2.7,1.42-4.55,1.42s-3.37-.48-4.57-1.42c-1.17-.97-1.77-2.25-1.77-3.87,0-1.83.92-3.27,2.2-4ZM518.88,1015.97c0-1.3-1.05-2.12-2.65-2.12s-2.65.83-2.65,2.12,1.07,2.1,2.65,2.1,2.65-.8,2.65-2.1ZM514.01,1008.7c0,1.17,1.02,1.9,2.22,1.9s2.22-.72,2.22-1.9-1-1.9-2.22-1.9-2.22.72-2.22,1.9Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M539.71,1018.97c-1.22,1.67-2.92,2.5-5.1,2.5s-3.87-.83-5.1-2.5c-1.22-1.67-1.85-3.85-1.85-6.55s.62-4.87,1.85-6.52c1.23-1.67,2.92-2.5,5.1-2.5s3.87.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM537.86,1012.43c0-1.75-.28-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.83,2.37-.83,4.12s.28,3.12.83,4.15c.58,1,1.38,1.5,2.42,1.5s1.85-.5,2.4-1.5c.57-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M555.91,1018.97c-1.22,1.67-2.92,2.5-5.1,2.5s-3.87-.83-5.1-2.5-1.85-3.85-1.85-6.55.62-4.87,1.85-6.52c1.22-1.67,2.92-2.5,5.1-2.5s3.87.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM554.06,1012.43c0-1.75-.27-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.83,2.37-.83,4.12s.27,3.12.83,4.15c.57,1,1.37,1.5,2.42,1.5s1.85-.5,2.4-1.5c.58-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M572.11,1018.97c-1.22,1.67-2.92,2.5-5.1,2.5s-3.87-.83-5.1-2.5-1.85-3.85-1.85-6.55.62-4.87,1.85-6.52c1.22-1.67,2.92-2.5,5.1-2.5s3.87.83,5.1,2.5c1.22,1.67,1.85,3.85,1.85,6.52s-.62,4.87-1.85,6.55ZM570.26,1012.43c0-1.75-.27-3.12-.85-4.12-.55-1-1.35-1.5-2.4-1.5s-1.85.5-2.42,1.5c-.55,1-.83,2.37-.83,4.12s.27,3.12.83,4.15c.57,1,1.37,1.5,2.42,1.5s1.85-.5,2.4-1.5c.58-1.02.85-2.4.85-4.15Z"
                        />
                        <path
                            fill="#0d349d"
                            d="M584.08,1024.05v-2.67c-2.47-.33-4.55-1.62-5.85-3.25l2.55-2.57c.87,1.17,1.97,1.92,3.3,2.3v-3.9c-3.37-.6-5.42-2.38-5.42-5.25,0-1.45.53-2.62,1.55-3.55s2.32-1.5,3.87-1.67v-2.65h2.77v2.72c2,.38,3.67,1.25,5.02,2.65l-2.5,2.62c-.75-.77-1.6-1.35-2.52-1.67v3.55c3.75.67,5.62,2.5,5.62,5.42s-2.15,4.87-5.62,5.27v2.65h-2.77ZM584.08,1010.2v-3.32c-1.08.22-1.72.82-1.72,1.65s.58,1.35,1.72,1.67ZM586.86,1014.45v3.52c1.2-.22,1.92-.92,1.92-1.8,0-.83-.65-1.4-1.92-1.72Z"
                        />
                    </g>
                    <g>
                        <path
                            className="cls-11"
                            d="M36.76,683.73c1.7-1.72,3.8-2.6,6.32-2.6s4.62.88,6.35,2.6,2.57,3.87,2.57,6.42-.85,4.72-2.57,6.45-3.82,2.6-6.35,2.6-4.62-.88-6.32-2.6c-1.67-1.72-2.52-3.87-2.52-6.45s.85-4.7,2.52-6.42ZM38.94,694.68c1.12,1.2,2.52,1.8,4.25,1.8s3.07-.6,4.17-1.8c1.1-1.22,1.65-2.72,1.65-4.52s-.55-3.27-1.65-4.47c-1.1-1.2-2.5-1.8-4.17-1.8s-3.12.6-4.25,1.8c-1.1,1.2-1.65,2.7-1.65,4.47s.55,3.32,1.65,4.52Z"
                        />
                        <path
                            className="cls-11"
                            d="M55.16,698.93v-17.52h2.97v14.82h8.3v2.7h-11.27Z"
                        />
                        <path className="cls-11" d="M69.44,698.93v-17.52h2.97v17.52h-2.97Z" />
                        <path
                            className="cls-11"
                            d="M82.76,695.33l4.52-13.92h3.07l-5.97,17.52h-3.27l-6.02-17.52h3.07l4.6,13.92Z"
                        />
                        <path className="cls-11" d="M93.09,698.93v-17.52h2.97v17.52h-2.97Z" />
                        <path
                            className="cls-11"
                            d="M108.19,681.41l6.82,17.52h-3.12l-1.47-3.72h-7.47l-1.45,3.72h-3.15l6.85-17.52h3ZM106.66,685.45l-2.65,7h5.32l-2.67-7Z"
                        />
                    </g>
                    <line strokeWidth={1.5} x1="13.06" y1="719.02" x2="593.78" y2="719.02" />
                </g>
            </svg>
        </div>
    );
}

BudgetBreakdownDerek.propTypes = propTypes;
BudgetBreakdownDerek.defaultProps = defaultProps;

export default BudgetBreakdownDerek;
