import { useIntersectionObserver } from '@folklore/hooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import RemaxCard from './RemaxCard';

import styles from '../../styles/animations/remax/budget-summary.module.css';

const propTypes = {
    config: PropTypes.shape({}),
    title: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    config: null,
    title: null,
    className: null,
};

function BudgetSummary({ config: configStr, title, className }) {
    const config = useMemo(() => {
        try {
            const clean = (configStr || '').replace(/&quot;/g, '"');
            return JSON.parse(clean);
        } catch (e) {
            return null;
        }
    }, [configStr]);

    const {
        ref: containerRef,
        entry: { isIntersecting = false },
    } = useIntersectionObserver({ rootMargin: '-10%' });

    const {
        leftValue = null,
        leftLabel = null,
        rightValue = null,
        rightLabel = null,
        resultValue = null,
        resultLabel = null,
        format = null,
    } = config || {};

    const formattedValues = useMemo(() => {
        const finalFormat = {
            minimumFractionDigits: 0,
            ...(format || {}),
        };
        const formatter = new Intl.NumberFormat('fr-CA', finalFormat);
        return {
            formattedLeftValue: leftValue !== null ? formatter.format(leftValue) : null,
            formattedRightValue: rightValue !== null ? formatter.format(rightValue) : null,
            formattedResultValue: resultValue !== null ? formatter.format(resultValue) : null,
        };
    }, [format, leftValue, rightValue, resultValue]);

    const total = rightValue;
    const leftWidth = leftValue / total;
    const rightWidth = 1 - leftWidth;

    const { formattedLeftValue, formattedRightValue, formattedResultValue } = formattedValues;

    return (
        <RemaxCard title={title} className={styles.card}>
            <div
                className={classNames([
                    styles.container,
                    className,
                    { [styles.visible]: isIntersecting },
                ])}
                ref={containerRef}
            >
                <div className={styles.chart}>
                    <div
                        className={classNames(styles.section, styles.left)}
                        style={{ width: `${leftWidth * 100}%` }}
                    >
                        <div className={styles.label}>
                            <div>{leftLabel}</div>
                            <div className={styles.value}>{formattedLeftValue}</div>
                        </div>
                        <div className={classNames(styles.bar, styles.top)} />
                        <div className={styles.separator} />
                        <div className={classNames(styles.bar, styles.bottom)} />
                    </div>
                    <div
                        className={classNames(styles.section, styles.right)}
                        style={{ width: `${rightWidth * 100}%` }}
                    >
                        <div className={styles.label}>
                            <div>{rightLabel}</div>
                            <div className={styles.value}>{formattedRightValue}</div>
                        </div>
                        <div className={classNames(styles.bar, styles.top)} />
                        <div className={styles.separator} />
                        <div className={classNames(styles.bar, styles.bottom)} />
                        <div className={styles.downLine} />
                    </div>
                </div>
                <div className={styles.result}>
                    <div className={styles.label}>{resultLabel}</div>
                    <div className={styles.value}>{formattedResultValue}</div>
                </div>
            </div>
        </RemaxCard>
    );
}

BudgetSummary.propTypes = propTypes;
BudgetSummary.defaultProps = defaultProps;

export default BudgetSummary;
