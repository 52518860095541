import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from '../../styles/animations/remax/card-machine.module.css';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function CardMachine({ className }) {
    return (
        <div className={classNames(styles.container, className)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159.62 359.53">
                <g>
                    <g>
                        <g>
                            <g id="Pos_terminal" data-name="Pos terminal">
                                <path
                                    className="cls-4"
                                    d="M131.12,359.53H28.5c-15.74,0-28.5-12.76-28.5-28.5V82.25c0-8.56,6.94-15.51,15.51-15.51h128.61c8.56,0,15.51,6.94,15.51,15.51v248.78c0,15.74-12.76,28.5-28.5,28.5Z"
                                />
                                <rect
                                    className="cls-3"
                                    x="19.76"
                                    y="91.21"
                                    width="124.05"
                                    height="7.21"
                                    rx="2.74"
                                    ry="2.74"
                                />
                            </g>
                            <g id="Buttons">
                                <g>
                                    <rect
                                        className="cls-5"
                                        x="15.96"
                                        y="223.92"
                                        width="32.23"
                                        height="19.61"
                                        rx="3.65"
                                        ry="3.65"
                                    />
                                    <rect
                                        className="cls-5"
                                        x="63.7"
                                        y="223.92"
                                        width="32.23"
                                        height="19.61"
                                        rx="3.65"
                                        ry="3.65"
                                    />
                                    <rect
                                        className="cls-5"
                                        x="111.43"
                                        y="223.92"
                                        width="32.23"
                                        height="19.61"
                                        rx="3.65"
                                        ry="3.65"
                                    />
                                    <rect
                                        className="cls-5"
                                        x="15.96"
                                        y="255.39"
                                        width="32.23"
                                        height="19.61"
                                        rx="3.65"
                                        ry="3.65"
                                    />
                                    <rect
                                        className="cls-5"
                                        x="63.7"
                                        y="255.39"
                                        width="32.23"
                                        height="19.61"
                                        rx="3.65"
                                        ry="3.65"
                                    />
                                    <rect
                                        className="cls-5"
                                        x="111.43"
                                        y="255.39"
                                        width="32.23"
                                        height="19.61"
                                        rx="3.65"
                                        ry="3.65"
                                    />
                                    <rect
                                        className="cls-5"
                                        x="15.96"
                                        y="286.86"
                                        width="32.23"
                                        height="19.61"
                                        rx="3.65"
                                        ry="3.65"
                                    />
                                    <rect
                                        className="cls-5"
                                        x="63.7"
                                        y="286.86"
                                        width="32.23"
                                        height="19.61"
                                        rx="3.65"
                                        ry="3.65"
                                    />
                                    <rect
                                        className="cls-5"
                                        x="111.43"
                                        y="286.86"
                                        width="32.23"
                                        height="19.61"
                                        rx="3.65"
                                        ry="3.65"
                                    />
                                </g>
                                <rect
                                    className="cls-8"
                                    x="15.96"
                                    y="318.34"
                                    width="32.23"
                                    height="19.61"
                                    rx="3.65"
                                    ry="3.65"
                                />
                                <rect
                                    className="cls-2"
                                    x="63.7"
                                    y="318.34"
                                    width="32.23"
                                    height="19.61"
                                    rx="3.65"
                                    ry="3.65"
                                />
                                <rect
                                    className="cls-1"
                                    x="111.43"
                                    y="318.34"
                                    width="32.23"
                                    height="19.61"
                                    rx="3.65"
                                    ry="3.65"
                                />
                            </g>
                            <g id="Screen_Checkmark" data-name="Screen+Checkmark">
                                <g id="Screen">
                                    <rect
                                        className="cls-main"
                                        x="15.96"
                                        y="115.88"
                                        width="127.7"
                                        height="89.77"
                                        rx="10.95"
                                        ry="10.95"
                                    />
                                </g>
                                <g id="Checkmark">
                                    <polygon
                                        className="cls-check"
                                        points="96.16 139.67 72.66 163.18 60.91 151.42 51.57 160.76 72.66 181.85 105.5 149.01 96.16 139.67"
                                    />
                                </g>
                            </g>
                        </g>
                        <path
                            className="cls-3"
                            d="M134.86,1.38v93.05l-106.55.69V1.38c0-.66.53-1.19,1.19-1.19.5,0,.95.32,1.14.82,1.1,2.83,3.79,4.65,6.85,4.65s5.76-1.87,6.84-4.65c.2-.5.64-.82,1.14-.82h6.07c.5,0,.95.32,1.14.82,1.1,2.83,3.79,4.65,6.85,4.65s5.76-1.87,6.84-4.65c.2-.5.64-.82,1.14-.82h6.07c.5,0,.95.32,1.14.82,1.1,2.83,3.79,4.65,6.85,4.65s5.76-1.87,6.84-4.65c.2-.5.64-.82,1.14-.82h6.07c.5,0,.95.32,1.14.82,1.1,2.83,3.79,4.65,6.85,4.65s5.76-1.87,6.84-4.65c.2-.5.64-.82,1.14-.82h6.07c.5,0,.95.32,1.14.82,1.1,2.83,3.79,4.65,6.85,4.65s5.76-1.87,6.84-4.65c.2-.5.64-.82,1.14-.82.66,0,1.2.54,1.2,1.19Z"
                        />
                        <g>
                            <rect className="cls-7" x="39.72" y="22.72" width="83" height="11.4" />
                            <rect
                                className="cls-7"
                                x="39.72"
                                y="41.8"
                                width="42.56"
                                height="11.4"
                            />
                            <rect
                                className="cls-7"
                                x="39.72"
                                y="60.84"
                                width="61.13"
                                height="11.4"
                            />
                        </g>
                        <path
                            className="cls-6"
                            d="M135.74,95.1V2.92c0-.6-.23-1.16-.66-1.58-.43-.43-.99-.66-1.58-.66-.94,0-1.75.57-2.11,1.5-.94,2.37-3.28,3.97-5.82,3.97s-4.89-1.56-5.82-3.98c-.36-.92-1.17-1.49-2.11-1.49h-6.03c-.94,0-1.75.57-2.11,1.5-.94,2.37-3.28,3.97-5.82,3.97s-4.89-1.57-5.82-3.98c-.36-.92-1.17-1.49-2.11-1.49h-6.03c-.94,0-1.75.57-2.11,1.5-.94,2.37-3.28,3.97-5.82,3.97s-4.89-1.57-5.82-3.98c-.36-.92-1.17-1.49-2.11-1.49h-6.03c-.94,0-1.75.57-2.11,1.5-.94,2.37-3.28,3.97-5.82,3.97s-4.89-1.56-5.82-3.98c-.36-.92-1.17-1.49-2.11-1.49h-6.03c-.94,0-1.75.57-2.11,1.5-.94,2.37-3.28,3.97-5.82,3.97s-4.89-1.56-5.82-3.98c-.36-.92-1.17-1.49-2.11-1.49-1.23,0-2.23,1.01-2.23,2.24v92.19h49.45"
                        />
                    </g>
                </g>
            </svg>
        </div>
    );
}

CardMachine.propTypes = propTypes;
CardMachine.defaultProps = defaultProps;

export default CardMachine;
