/* eslint-disable jsx-a11y/control-has-associated-label */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from '../../styles/animations/remax/character-picker.module.css';

const propTypes = {
    roxanneAnchor: PropTypes.string,
    derekAnchor: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    roxanneAnchor: null,
    derekAnchor: null,
    className: null,
};

function CharacterPicker({ roxanneAnchor, derekAnchor, className }) {
    return (
        <div className={classNames(styles.container, className)}>
            <svg viewBox="0 0 492.26 391.73" overflow="visible">
                <defs>
                    <pattern
                        id="Nouveau_motif"
                        data-name="Nouveau motif"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternTransform="translate(-47.93 2590.3)"
                        patternUnits="userSpaceOnUse"
                        viewBox="0 0 20 20"
                    >
                        <g>
                            <rect className="cls-4" width="20" height="20" />
                            <line className="cls-8" x1="10" x2="10" y2="100" />
                            <line className="cls-8" x1="10" y1="-20" x2="10" y2="80" />
                            <line className="cls-8" y1="10" x2="100" y2="10" />
                            <line className="cls-8" x1="-20" y1="10" x2="80" y2="10" />
                            <g>
                                <line className="cls-8" x1="-40" y1="10" x2="60" y2="10" />
                                <line className="cls-8" x1="10" y1="-40" x2="10" y2="60" />
                            </g>
                            <line className="cls-8" x1="-60" y1="10" x2="40" y2="10" />
                            <line className="cls-8" x1="-80" y1="10" x2="20" y2="10" />
                            <line className="cls-8" x1="10" y1="-60" x2="10" y2="40" />
                            <line className="cls-8" x1="10" y1="-80" x2="10" y2="20" />
                        </g>
                    </pattern>
                    <clipPath id="clippath">
                        <path
                            className="cls-15"
                            d="M301.23,327.67l-232.69,64.05c-33.54-101.46-52.19-191.89,79.01-220.7,109.78-24.09,134.52,72.77,153.68,156.64Z"
                        />
                    </clipPath>
                    <clipPath id="clippath-1">
                        <path
                            className="cls-1"
                            d="M198.23,126.54l-144.13,39.67c-2.66-45.2-13.79-110.83,42.88-125.87,56.33-16.11,80.47,46.08,101.25,86.2Z"
                        />
                    </clipPath>
                </defs>
                <g>
                    <a href={`#${derekAnchor}`}>
                        <g className={styles.derek}>
                            <g className={styles.character}>
                                <g className={styles.animRoot}>
                                    <rect
                                        className="cls-12"
                                        x="232.5"
                                        y="123.2"
                                        width="186.35"
                                        height="196.18"
                                        transform="translate(47.47 499.92) rotate(-79.24)"
                                    />
                                    <polygon
                                        className="cls-10"
                                        points="439.43 148.07 404.64 331.14 211.91 294.52 246.7 111.45 439.43 148.07"
                                    />
                                    <path
                                        className="cls-15"
                                        d="M451.41,340.03l-46.77-8.89,34.79-183.07c2.97,47.63,9.03,144.72,11.98,191.96Z"
                                    />
                                    <polygon
                                        className="cls-14"
                                        points="445.97 252.84 420.44 247.99 439.43 148.07 445.97 252.84"
                                    />
                                    <path
                                        className="cls-15"
                                        d="M165.14,285.63l46.77,8.89,34.79-183.07c-20.24,43.22-61.49,131.32-81.56,174.18Z"
                                    />
                                    <polygon
                                        className="cls-14"
                                        points="202.18 206.52 227.71 211.37 246.7 111.44 202.18 206.52"
                                    />
                                    <line
                                        className="cls-6"
                                        x1="419.14"
                                        y1="254.84"
                                        x2="446.55"
                                        y2="260.05"
                                    />
                                    <line
                                        className="cls-6"
                                        x1="199.94"
                                        y1="213.18"
                                        x2="227.35"
                                        y2="218.39"
                                    />
                                    <path
                                        className="cls-20"
                                        d="M398.92,78.17l-15.18-2.88,5.77-30.36,15.18,2.88c19.84,3.86,14.09,34.05-5.77,30.36Z"
                                    />
                                    <path
                                        className="cls-20"
                                        d="M310.02,61.28l15.18,2.88,5.77-30.36-15.18-2.88c-19.87-3.68-25.6,26.5-5.77,30.36Z"
                                    />
                                    <path
                                        className="cls-15"
                                        d="M365.65,104.73c-9.54,39.09,2.83,40.46-34.87,32.55-3.22-.61-5.31-3.86-4.66-7.25l6.02-31.66,33.52,6.37Z"
                                    />
                                    <path
                                        className="cls-6"
                                        d="M372.33,135.32c-1.67,7.94-1,18.59-13.48,16.28,0,0-37.58-7.14-37.58-7.14-12.54-2.56-7.89-11.99-6.56-20.09"
                                    />
                                    <path
                                        className="cls-15"
                                        d="M407.39,15.45c-23.59,98.98,5.6,99.54-84.47,81.15-8.11-1.54-13.44-9.36-11.89-17.47,0,0,15.04-79.13,15.04-79.13l81.32,15.45Z"
                                    />
                                    <path
                                        className="cls-18"
                                        d="M326.06,0l40.65,7.72c-10.63,13.09-33.8,15.22-44.62,13.18l3.97-20.91Z"
                                    />
                                    <path
                                        className="cls-18"
                                        d="M407.39,15.45l-3.97,20.91c-10.81-2.07-31.59-12.56-36.67-28.63l40.65,7.72Z"
                                    />
                                    <polygon
                                        className="cls-18"
                                        points="365.65 104.73 364.32 111.74 328.88 115.5 332.14 98.36 365.65 104.73"
                                    />
                                    <rect
                                        className="cls-14"
                                        x="351.89"
                                        y="210.47"
                                        width="45.66"
                                        height="40.28"
                                        transform="translate(78.2 555.69) rotate(-79.24)"
                                    />
                                    <line
                                        className="cls-7"
                                        x1="363.72"
                                        y1="213.78"
                                        x2="391.13"
                                        y2="218.99"
                                    />
                                </g>
                            </g>
                            <g className={styles.bubble}>
                                <path
                                    className="cls-14"
                                    d="M475.32,196.5h-77.42c-22.25-.37-22.29-33.51,0-33.89,0,0,77.42,0,77.42,0,9.36,0,16.94,7.59,16.94,16.94,0,9.36-7.59,16.94-16.94,16.94Z"
                                />
                                <g>
                                    <path
                                        className="cls-11"
                                        d="M395.59,186.68v-14.02h4.38c2,0,3.72.44,5.18,1.46,1.5,1.02,2.4,2.92,2.4,5.46s-.9,4.5-2.4,5.56c-1.46,1.06-3.18,1.54-5.18,1.54h-4.38ZM397.97,174.81v9.7h1.56c1.6,0,2.82-.26,3.88-.96,1.1-.72,1.7-2.06,1.7-3.98s-.6-3.16-1.7-3.82c-1.06-.66-2.28-.94-3.9-.94h-1.54Z"
                                    />
                                    <path
                                        className="cls-11"
                                        d="M409.95,186.68v-14.02h9.86v2.16h-7.48v3.62h7.14v2.18h-7.14v3.9h7.48v2.16h-9.86Z"
                                    />
                                    <path
                                        className="cls-11"
                                        d="M430.79,186.68l-2.94-4.78h-2.9v4.78h-2.38v-14.02h5.34c3.3,0,5.34,1.7,5.34,4.58,0,2.06-1.04,3.56-2.86,4.26l3.22,5.18h-2.82ZM424.95,174.81v4.9h2.66c2.18,0,3.26-.82,3.26-2.46s-1.08-2.44-3.26-2.44h-2.66Z"
                                    />
                                    <path
                                        className="cls-11"
                                        d="M435.72,186.68v-14.02h9.86v2.16h-7.48v3.62h7.14v2.18h-7.14v3.9h7.48v2.16h-9.86Z"
                                    />
                                    <path
                                        className="cls-11"
                                        d="M448.34,186.68v-14.02h2.38v6.52l5.24-6.52h2.96l-4.74,5.92,5.2,8.1h-2.86l-3.98-6.08-1.82,2.22v3.86h-2.38Z"
                                    />
                                </g>
                                <polyline
                                    className="cls-2"
                                    points="466.9 188.42 475.52 179.69 466.9 170.96"
                                />
                            </g>
                        </g>
                    </a>
                    <a href={`#${roxanneAnchor}`}>
                        <g className={styles.roxanne}>
                            <g className={styles.character}>
                                <g className={styles.animRoot}>
                                    <g>
                                        <path
                                            className="cls-15"
                                            d="M301.23,327.67l-232.69,64.05c-33.54-101.46-52.19-191.89,79.01-220.7,109.78-24.09,134.52,72.77,153.68,156.64Z"
                                        />
                                        <g className="cls-17">
                                            <path
                                                className="cls-16"
                                                d="M44.64,210.86c-7.24,1.14-16.08,6.39-14.66,14.76.75,3.69,4.52,6.75,8.2,5.96-11.13,18.74,2.94,33.02,21.76,21.81,7.16,10.54,23.96,1.01,18.59-10.54,32.53,3.59,31.61-38.71-1.12-22.49,4.5-9.35-1.45-19.17-12.17-15.24-4.16,1.17-6.52,5.36-10.04,7.34-3.84.24-5.49-3.38-10.57-1.61Z"
                                            />
                                            <path
                                                className="cls-12"
                                                d="M70.57,229.61c3.45,11.85-15.24,16.99-18.33,5.05-3.45-11.85,15.24-16.99,18.33-5.05Z"
                                            />
                                            <path
                                                className="cls-16"
                                                d="M91.71,311.14h0c4.7-5.56,7.96-15.15,1.6-20.63-2.96-2.42-7.95-2.33-10.25.72-3.81-21.46-23.8-23.08-30.72-2.3-12.49-3.09-18.8,14.61-7.19,20.13-26.97,18.53,1.36,49.95,15.54,16.3,2.7,10.02,13.62,13.57,19.17,3.58,2.38-3.61,1.43-8.32,2.8-12.12,2.75-2.7,6.36-1.02,9.05-5.68Z"
                                            />
                                            <path
                                                className="cls-12"
                                                d="M59.83,313.88c-10.35-6.72.44-22.82,10.58-15.8,10.35,6.72-.44,22.82-10.58,15.8Z"
                                            />
                                            <path
                                                className="cls-16"
                                                d="M149,273.84h0c6.69-2.88,13.89-10.01,10.65-17.74-1.57-3.49-6.07-5.63-9.5-3.93,6.17-20.91-10.99-31.28-26.46-15.78-9.54-8.45-23.47,4.93-15.42,14.8-32.41,4.54-21.09,45.3,6.62,21.52-2.06,10.17,6.13,18.22,15.55,11.77,3.74-2.16,5-6.8,7.92-9.59,3.66-1.18,6.15,1.93,10.64-1.04Z"
                                            />
                                            <path
                                                className="cls-12"
                                                d="M119.25,262.06c-6.26-10.63,10.58-20.22,16.53-9.41,6.26,10.63-10.58,20.22-16.53,9.41Z"
                                            />
                                            <path
                                                className="cls-16"
                                                d="M230.26,190.67h0c6.69-2.88,13.89-10.01,10.65-17.74-1.57-3.49-6.07-5.63-9.5-3.93,6.17-20.91-10.99-31.28-26.46-15.78-9.54-8.45-23.47,4.93-15.42,14.8-32.41,4.54-21.09,45.3,6.62,21.52-2.06,10.17,6.13,18.22,15.55,11.77,3.74-2.16,5-6.8,7.92-9.59,3.66-1.18,6.15,1.93,10.64-1.04Z"
                                            />
                                            <path
                                                className="cls-12"
                                                d="M200.51,178.89c-6.26-10.63,10.58-20.22,16.53-9.41,6.26,10.63-10.58,20.22-16.53,9.41Z"
                                            />
                                            <path
                                                className="cls-16"
                                                d="M254.65,217.27c-6.82-2.71-17.11-2.68-20.13,5.26-1.22,3.56.46,8.1,4.03,9.3-19.12,10.47-14.27,29.93,7.63,29.85,1.06,12.82,19.85,13.15,21.37.38,26.18,19.64,46.9-17.24,10.48-19.93,8.63-5.76,8.5-17.24-2.73-19.31-4.18-1.11-8.34,1.3-12.38,1.22-3.43-1.74-3-5.7-8.27-6.76Z"
                                            />
                                            <path
                                                className="cls-12"
                                                d="M267.43,246.6c-3.06,11.95-21.76,6.87-18.35-4.98,3.06-11.95,21.76-6.87,18.35,4.98Z"
                                            />
                                            <path
                                                className="cls-16"
                                                d="M160.78,322.17c-7.28-.93-17.23,1.64-18.2,10.08-.3,3.75,2.46,7.74,6.21,8.01-15.93,14.89-6.4,32.53,14.8,27.02,4.21,12.16,22.5,7.82,20.8-4.93,30.23,12.54,41.16-28.33,5.21-21.9,6.94-7.72,3.96-18.81-7.43-18.03-4.33-.04-7.76,3.33-11.69,4.25-3.76-.84-4.32-4.78-9.69-4.5Z"
                                            />
                                            <path
                                                className="cls-12"
                                                d="M180.43,347.42c0,12.34-19.38,12.06-19.01-.28,0-12.34,19.38-12.05,19.01.28Z"
                                            />
                                        </g>
                                    </g>
                                    <path
                                        className="cls-6"
                                        d="M161.42,168.48c8.06,34.78-44.25,48.59-54.4,14.33"
                                    />
                                    <path
                                        className="cls-12"
                                        d="M141.79,142.08l8.45,30.69c2,7.27-3.38,15.08-12.01,17.46-8.63,2.38-17.25-1.58-19.25-8.85,0,0-8.45-30.69-8.45-30.69l31.26-8.6Z"
                                    />
                                    <line
                                        className="cls-6"
                                        x1="105.84"
                                        y1="307.2"
                                        x2="124.84"
                                        y2="376.23"
                                    />
                                    <line
                                        className="cls-6"
                                        x1="225.93"
                                        y1="274.14"
                                        x2="244.93"
                                        y2="343.17"
                                    />
                                    <path
                                        className="cls-19"
                                        d="M141.79,142.08l4.65,16.91c-9.26,6.85-20.49,15.13-27.97,20.57l-7.95-28.87,31.26-8.6Z"
                                    />
                                    <g>
                                        <path
                                            className="cls-1"
                                            d="M198.23,126.54l-144.13,39.67c-2.66-45.2-13.79-110.83,42.88-125.87,56.33-16.11,80.47,46.08,101.25,86.2Z"
                                        />
                                        <g className="cls-13">
                                            <path
                                                className="cls-9"
                                                d="M68.7,183.88c-10.99-37.11-8.46-121.49,8.7-163.6"
                                            />
                                            <path
                                                className="cls-9"
                                                d="M81.92,180.87c-10.99-37.11-8.46-121.49,8.7-163.6"
                                            />
                                            <path
                                                className="cls-9"
                                                d="M194.29,147.6c-9.55-37.51-54.91-108.7-91.2-136.1"
                                            />
                                            <path
                                                className="cls-9"
                                                d="M181.39,151.78c-9.55-37.51-54.91-108.7-91.2-136.1"
                                            />
                                            <path
                                                className="cls-9"
                                                d="M89.02,28.35s.25,94.12,10.19,132.44"
                                            />
                                            <path
                                                className="cls-9"
                                                d="M97.8,26.23s59.6,85.86,64.95,128.95"
                                            />
                                            <line
                                                className="cls-9"
                                                x1="93.71"
                                                y1="22.73"
                                                x2="136.59"
                                                y2="178.48"
                                            />
                                        </g>
                                    </g>
                                    <path
                                        className="cls-12"
                                        d="M73.74,98.7c-4.89,32.73,26.5,72.44,56.58,62.77,30.78-7.08,37.45-57.28,16.49-82.88l-73.07,20.11Z"
                                    />
                                    <path
                                        className="cls-5"
                                        d="M109.34,114.1c5.92,23.02-28.16,32.39-34.86,9.59-5.91-23.02,28.16-32.39,34.86-9.59Z"
                                    />
                                    <path
                                        className="cls-5"
                                        d="M158.95,100.44c5.92,23.02-28.16,32.39-34.86,9.59-5.91-23.02,28.16-32.39,34.86-9.59Z"
                                    />
                                    <line
                                        className="cls-5"
                                        x1="108.75"
                                        y1="111.94"
                                        x2="122.2"
                                        y2="108.23"
                                    />
                                </g>
                            </g>
                            <g className={styles.bubble}>
                                <path
                                    className="cls-18"
                                    d="M128.04,274.63H16.7c-22.24-.37-22.3-33.51,0-33.89,0,0,111.34,0,111.34,0,9.36,0,16.94,7.59,16.94,16.94,0,9.36-7.59,16.94-16.94,16.94Z"
                                />
                                <g>
                                    <path
                                        className="cls-11"
                                        d="M22.61,264.81l-2.94-4.78h-2.9v4.78h-2.38v-14.02h5.34c3.3,0,5.34,1.7,5.34,4.58,0,2.06-1.04,3.56-2.86,4.26l3.22,5.18h-2.82ZM16.77,252.95v4.9h2.66c2.18,0,3.26-.82,3.26-2.46s-1.08-2.44-3.26-2.44h-2.66Z"
                                    />
                                    <path
                                        className="cls-11"
                                        d="M28.65,252.65c1.36-1.38,3.04-2.08,5.06-2.08s3.7.7,5.08,2.08c1.38,1.38,2.06,3.1,2.06,5.14s-.68,3.78-2.06,5.16c-1.38,1.38-3.06,2.08-5.08,2.08s-3.7-.7-5.06-2.08c-1.34-1.38-2.02-3.1-2.02-5.16s.68-3.76,2.02-5.14ZM30.39,261.41c.9.96,2.02,1.44,3.4,1.44s2.46-.48,3.34-1.44c.88-.98,1.32-2.18,1.32-3.62s-.44-2.62-1.32-3.58c-.88-.96-2-1.44-3.34-1.44s-2.5.48-3.4,1.44c-.88.96-1.32,2.16-1.32,3.58s.44,2.66,1.32,3.62Z"
                                    />
                                    <path
                                        className="cls-11"
                                        d="M44.37,250.78l3.12,4.88,3.16-4.88h2.76l-4.52,6.84,4.78,7.18h-2.76l-3.42-5.16-3.4,5.16h-2.76l4.8-7.18-4.52-6.84h2.76Z"
                                    />
                                    <path
                                        className="cls-11"
                                        d="M62.61,250.78l5.46,14.02h-2.5l-1.18-2.98h-5.98l-1.16,2.98h-2.52l5.48-14.02h2.4ZM61.39,254.03l-2.12,5.6h4.26l-2.14-5.6Z"
                                    />
                                    <path
                                        className="cls-11"
                                        d="M78.99,264.81l-6.96-9.88v9.88h-2.38v-14.02h2.38l6.96,9.92v-9.92h2.4v14.02h-2.4Z"
                                    />
                                    <path
                                        className="cls-11"
                                        d="M93.83,264.81l-6.96-9.88v9.88h-2.38v-14.02h2.38l6.96,9.92v-9.92h2.4v14.02h-2.4Z"
                                    />
                                    <path
                                        className="cls-11"
                                        d="M99.33,264.81v-14.02h9.86v2.16h-7.48v3.62h7.14v2.18h-7.14v3.9h7.48v2.16h-9.86Z"
                                    />
                                </g>
                                <polyline
                                    className="cls-3"
                                    points="120.99 266.55 129.61 257.82 120.99 249.09"
                                />
                            </g>
                        </g>
                    </a>
                </g>
            </svg>
        </div>
    );
}

CharacterPicker.propTypes = propTypes;
CharacterPicker.defaultProps = defaultProps;

export default CharacterPicker;
