import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from '../../styles/animations/remax/remax-card.module.css';

const propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    cardClassName: PropTypes.string,
    children: PropTypes.node,
};

const defaultProps = {
    title: null,
    className: null,
    cardClassName: null,
    children: null,
};

function RemaxCard({ title, className, cardClassName, children }) {
    return (
        <div className={classNames(styles.container, className)}>
            <div className={classNames(styles.card, cardClassName)}>
                <h3>{title}</h3>
                {children}
            </div>
        </div>
    );
}

RemaxCard.propTypes = propTypes;
RemaxCard.defaultProps = defaultProps;

export default RemaxCard;
